import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Collapse, Typography, Card, CardContent, Button, CardActions, IconButton } from '@material-ui/core';

import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
      paddingTop: 80
    },
    cardRoot: {
      margin: theme.spacing(2),
      minWidth: 275,
    },
    title: {
      fontSize: 14,
      textAlign: "center"
    },
    description: {
      textAlign: "center",
      padding: theme.spacing(2),
      minHeight: 100
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      color: "purple",
      height: 50,
      width: 50,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }),
);

export default function ChordDetectorCard() {
  const classes = useStyles()

  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.cardRoot} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Chord Detector
        </Typography>
        <div className={classes.controls}>
          <IconButton aria-label="play/pause">
            <PhoneIphoneIcon className={classes.playIcon} />
          </IconButton>
        </div>
        <Typography variant="body2" component="p" className={classes.description}>
          Predictively detects the chord being played in the context of a song.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button aria-label="launch trainning">
          Train <PlayForWorkIcon />
        </Button>
        <IconButton aria-label="launch">
          <LaunchIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            Status: <b style={{color: "GoldenRod"}}>Processing..</b>
          </Typography>
          <Typography paragraph>
            Last Run: <b>02/02/2021</b>
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  )
}