import React, { useEffect } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { 
  Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText,
  FormGroup, Typography, Button, FormControl, ButtonGroup,TextField,
  InputAdornment, FormControlLabel, Checkbox
} from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import { getChordByNumber } from '../../Songs/EditSong/EditSongUtil'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    dialogStyle: {
      padding: theme.spacing(1)
    },
    formControl: {
      margin: theme.spacing(3),
      marginLeft: theme.spacing(8),
      display: "flex",
      flexDirection: "row",
    },
    formControl2: {
      margin: theme.spacing(3),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(0),
      display: "flex",
      flexDirection: "row",
    }
  }),
)

export interface KeyAndCapoProps {
  openDialog: boolean,
  setOpenDialog: (b: boolean) => void
  originalKey: string

  transpose: number
  changeTranspose: (newTranspose: number) => void

  capo: number
  changeCapo: (newCapo: number) => void

  disabled: boolean,
  useSpaceBarless: boolean,
  setUseSpaceBarless: (b: boolean) => void
}

export default function KeyAndCapo ({
  openDialog, setOpenDialog, originalKey,
  transpose, changeTranspose, capo, changeCapo,
  disabled, useSpaceBarless, setUseSpaceBarless
}: KeyAndCapoProps) {
  const classes = useStyles()
  const [key, setKey] = React.useState( getChordByNumber( transpose, false, originalKey) )

  useEffect(() => {
    setKey( getChordByNumber( transpose, false, originalKey) )
  }, [originalKey, transpose])
  
  return (
    <Dialog
      open={openDialog}
      onClose={() => { setOpenDialog(false) }}
      aria-labelledby="instructions"
      aria-describedby="instructions"
    >
      <DialogTitle id="instructions">Setup your Key &amp; Capo!</DialogTitle>
      <DialogContent>
        <DialogContentText id="instructions-1">
          Change the <b>Key</b> to modify the chords you view, <br />
          Change the <b>Capo</b> to translate the chords upon submitting<br /> the recording.
        </DialogContentText>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup style={{justifyContent: "center", marginRight: 20, flexDirection: "row"}}>
            {/*<Typography style={{display:"flex", flexDirection:"column", justifyContent:"center", marginRight: 20}}
            > Key: </Typography>*/}
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
              <TextField style={{width: 200}}
                id="key-outlined-basic" value={key} variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span style={{color: "grey", minWidth: 60}}>Key: </span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </FormGroup>
          <ButtonGroup color="primary" orientation="vertical" size="small"
              aria-label="outlined primary button group">
            <Button onClick={ () => { changeTranspose( transpose < 12 ? transpose + 1 : 0 ) } }
            ><AddIcon /></Button>
            <Button onClick={ () => { changeTranspose( transpose > 0 ? transpose - 1 : 11 ) } }
            ><RemoveIcon /></Button>
          </ButtonGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup style={{justifyContent: "center", marginRight: 20, flexDirection: "row"}}>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
              <TextField style={{width: 200}}
                id="capo-outlined-basic" value={capo} variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span style={{color: "grey", minWidth: 60}}>Capo: </span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </FormGroup>
          <ButtonGroup color="primary" orientation="vertical" size="small"
              aria-label="outlined primary button group">
            <Button onClick={() => { changeCapo( capo + 1) }}><AddIcon /></Button>
            <Button><RemoveIcon onClick={() => { changeCapo( capo > 0 ? capo - 1 : 0) }} /></Button>
          </ButtonGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl2}>
          <FormGroup>
            <FormControlLabel disabled={disabled}
              control={
                <Checkbox
                  checked={!disabled ? useSpaceBarless : false}
                  onChange={(e) => { setUseSpaceBarless(e.target.checked) }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Use Auto-Tap!"
            />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions style={{paddingLeft: 20, paddingRight: 20}}>
        <Button onClick={() => { changeTranspose(0); changeCapo(0) }} color="secondary"
          >Reset</Button>
        <Button onClick={() => { setOpenDialog(false) }} color="primary"
          >Done</Button>
      </DialogActions>
    </Dialog>
  )
}