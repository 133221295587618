import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, CircularProgress, Dialog, DialogTitle,
    DialogActions, DialogContentText, DialogContent, Button
 } from '@material-ui/core'

import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"

export interface AssignColleagueProps {
  recordId: string|null
  handleClose: () => void
  assignLabellerToRecording: (userId: string, username: string, email: string, recordingId: string) => Promise<boolean>
}

export default function AssignColleague({ recordId, handleClose, assignLabellerToRecording }: AssignColleagueProps) {
  const [open, setOpen] = React.useState(false)
  const [users, setUsers] = React.useState<Labeller[]>([])
  const [chosenUser, setChosenUser] = React.useState<Labeller|null>(null)
  const loading = open && users.length === 0

  interface ListLabellers {
    listLabellers: {items: Labeller[]}
  }

  const getUsers = async (): Promise<Labeller[]> => {
    return new Promise (async resolve => {
      let query = `query listUser2 {
        listLabellers(
          limit: 20000
        ) { items { id username email roles oslynTeacherEmail } }
      }`
  
      let data = ( await API.graphql(graphqlOperation(query)) ) as GraphQLResult<ListLabellers>
      let items = data ? data.data ? data.data.listLabellers.items: [] : []
      resolve(items)
    }) 
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) { return undefined }
    (async () => { 
      if (active) { setUsers( await getUsers() ) }
    })()
    return () => { active = false }
  }, [loading]);

  React.useEffect(() => {
    if (!open) { setUsers([]) }
  }, [open])

  return (
    <Dialog
      open={recordId ? true : false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-1"
      aria-describedby="alert-dialog-description-1"
    >
      <DialogTitle id="alert-dialog-title">
        Assign an Audio Engineer
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Assign the auditing of this recording to another Audio Engineer.<br />
          Be sure to notify them!
        </DialogContentText>
        <Autocomplete
          id="asynchronous-demo"
          value={chosenUser}
          open={open}
          onOpen={() => { setOpen(true) }}
          onClose={() => { setOpen(false) }}
          getOptionSelected={(option, value) => `${option.username} (${option.email})` === `${value.username} (${value.email})`}
          getOptionLabel={(option) => `${option.username} (${option.email})`}
          options={users}
          loading={loading}
          renderInput={(params) => (
            <TextField {...params} label="Audio Engineer" variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={ (e: React.ChangeEvent<any>, value) => {
            setChosenUser(value)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={async () => {
          if (chosenUser && recordId) {
            if (await assignLabellerToRecording(chosenUser.id, chosenUser.username, chosenUser.email, recordId)) {
              handleClose()
            } else { alert(`There was a problem sending your request!`) }
          } else console.log(`Missing either chosenUser or recordId:${chosenUser}, ${recordId}`)
        }} color="primary" autoFocus>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  )
}