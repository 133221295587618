import React, { useEffect } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import { IconButton, Typography } from '@material-ui/core'

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MicIcon from '@material-ui/icons/Mic';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      display: "flex",
      flexDirection: "column",
    },
    root: {
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      padding: "5px 20px"
    },
    text: {
      maxWidth: 120,
      textAlign: "center"
    },
    bottomText: {
      maxWidth: 300,
      textAlign: "center",
      alignSelf: "center"
    },
    button: {
      marginRight: 'auto',
      marginLeft: 'auto',
      padding: theme.spacing(2),
      transition: "background-color 0.1s ease-in, color 0.1s ease-in"
    },
    icon: {
      fontSize: 50
    }
  })
)

export interface Step2Props {
  selected: number,
  setSelected: (id: number) => void
}

export default function Step2 ({ selected, setSelected }:Step2Props ) {
  const classes = useStyles()

  return (
    <div className={classes.rootContainer}>
      <div className={classes.root}>
        <div className={classes.container}>
          <IconButton className={classes.button}
            style={{
              color: selected === 1 ? "rgba(255,138,101,1)" : "inherit",
              backgroundColor: selected === 1 ? "rgba(255,138,101,0.2)" : "inherit",
            }}
            onClick={() => {
              if (selected === 1) { setSelected(0) }
              else { setSelected(1) }
            }}
          >
            <AssignmentTurnedInIcon className={classes.icon}/>
          </IconButton>
          <Typography className={classes.text} variant="caption" display="block" gutterBottom>
            Complete and go back to Songs!
          </Typography>
        </div>
        <div className={classes.container}>
          <IconButton className={classes.button}
            style={{
              color: selected === 2 ? "rgba(255,138,101,1)" : "inherit",
              backgroundColor: selected === 2 ? "rgba(255,138,101,0.2)" : "inherit",
            }}
            onClick={() => { 
              if (selected === 2) { setSelected(0) }
              else { setSelected(2) }
            }}
          >
            <MicIcon className={classes.icon}/>
          </IconButton>
          <Typography className={classes.text} variant="caption" display="block" gutterBottom>
            Complete and start recording!
          </Typography>
        </div>
      </div>
      <Typography className={classes.bottomText} variant="body1" gutterBottom>
        Once a recording is submitted for this song, this song cannot be changed! Are you sure?
      </Typography>
    </div>
  )
}