import React from 'react';
import { 
  Button, TextField, Dialog, DialogActions, 
  DialogContent, DialogContentText, DialogTitle,
  FormGroup, FormControlLabel, Checkbox
} from '@material-ui/core'
import { API } from 'aws-amplify'

export interface DeleteSongPopupProps {
  user: Labeller
  isOpen: boolean
  setClose: () => void
  selected: string[]
}

export default function DeleteSongPopup({user, isOpen, setClose, selected} : DeleteSongPopupProps) {

  const [hardDelete, setHardDelete] = React.useState(false)
  // const [comment, setComment] = React.useState("")

  const handleDelete = async () => {
    if (hardDelete && selected.length > 0) {
      console.log(selected)
      for (let id of selected) {
        let params = { body: { 
          id, userId: user.id
        } }
        let data = await API.del('oslynstudiov1', '/studio/song/hard-delete', params)
        if (data.error) { console.log(data.error) }
        else { setClose() }
      }
    } else {
      alert("TODO: Soft delete not implemented.")
    }
    setHardDelete(false)
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={setClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reason for Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Theres no going back on this delete. Be careful.
            All recordings that are currently linked to this song will nolonger have a home.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{paddingLeft: 20, paddingRight: 20}}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox style={{transform: "scale(0.8)", color: "red"}}
                  checked={hardDelete}
                  onChange={() => { setHardDelete(!hardDelete) }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={<span style={{left: -20, fontSize: 10, color: "red"}}>Hard Delete (caution)</span>}
            />
          </FormGroup>
          <div style={{flexGrow: 1}}/>
          <Button onClick={setClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}