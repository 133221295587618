
import { Typography } from "@material-ui/core"

export interface LyricLineProps {
  line: string
}

export default function LyricLine({line}: LyricLineProps) {
  return (
    <div style={{position: "relative", top: -8}}>
      <Typography variant="h6" style={{color: "black", fontSize: 20}}>
        {line}
      </Typography>
    </div>
  )
}