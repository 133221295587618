import React, { useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, MenuItem,
  Typography, Container } from '@material-ui/core'
// import { Grid, Typography, Card, CardContent, Button, CardActions, IconButton } from '@material-ui/core';

// import { makeStyles } from '@material-ui/core/styles'

import MainCard from './MainCard'
import { DropDownMenu } from 'material-ui';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
      paddingTop: 80
    },
    cardRoot: {
      margin: theme.spacing(2),
      minWidth: 275,
    },
    title: {
      fontSize: 14,
      textAlign: "center"
    },
    description: {
      textAlign: "center",
      padding: theme.spacing(2),
      minHeight: 100
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      color: "purple",
      height: 50,
      width: 50,
    },

    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }),
);

// CHECK OUT USE EFFECT

// const s3 = new AWS.S3({
//   accessKeyId: env.s3.accessKeyId,
//   secretAccessKey: env.s3.secretAccessKey
// })

// s3.getObject({
//   Bucket: env.s3PiplineBucket,
//   Key: `public/${id}/raw/recording.${fileExtension}`
// }, async (err, data: any) => {
//   if (err) {
//     setMsg({status: "error", msg: "Could not get the audio (recording.wav) from S3"})
//     console.error(err)
//     resolve(null)
//   } else {
//     console.log(data)

//     var byteArray = new Uint8Array(data.Body);
//     var blob = new Blob([byteArray], { type: data.ContentType });
//     let soundString = URL.createObjectURL(blob)

//     let duration = await getBlobDuration(soundString)
//     console.log(`duration: ${duration}`)

//     resolve(soundString)
//   }
// })

export default function ChordDetector() {
  const classes = useStyles()

  // const getGreeting = ():string => {
  //   let dt = new Date()
  //   var offset = 0; //new Date should return Hour in user's timezone ...
  //   var estHour = new Date(dt.getTime() + offset*60*1000).getHours()
  //   console.log(estHour)

  //   if (estHour < 12) return "Good Morning"
  //   else if (estHour > 17) return "Good Evening"
  //   else return "Good Afternoon"
  // }

  return (
    <main style={{paddingTop: "70px"}}>

      <Grid container spacing={4} style={{padding: "20px"}}>

        <Grid item key={1} xs={12} sm={6} md={4} lg={3}>
        
          <Card className={classes.card}>

          <CardContent className={classes.cardContent}>
            <Typography style={{color:"grey"}}>
              Chord Detector
            </Typography>
            {/* <Typography gutterBottom variant="h2" component="h2"
              style={{color: "green"}}
            // >{totalRecordings}</Typography>
            // <Typography variant="caption" display="block">
              // * Number of recordings you've done on this platform.
            // </Typography> */}
          </CardContent>

          <CardActions>
            <Button size="small" color="secondary" style={{color: "blue"}}>Pause</Button>
            <Button size="small" color="primary"
              // onClick={() => { goToSongs() }}
            >START</Button>
            {/* >Reset</Button> */}
            <Button size="small" color="primary"
              // onClick={() => { goToSongs() }}
            >Reset</Button>
          </CardActions>

          </Card>
        
          <Card className={classes.card}>

          <CardContent className={classes.cardContent}>
            <Typography style={{color:"grey"}}>
              CONFIGURE
            </Typography>
          </CardContent>

          <CardActions>
            <Button size="small" color="secondary" style={{color: "blue"}}>Download records</Button>
            <Button size="small" color="primary"
              // onClick={() => { goToSongs() }}
            >choose model</Button>
            {/* >Reset</Button> */}
            {/* <Button size="small" color="primary"
              // onClick={() => { goToSongs() }}
            >Reset</Button> */}
          </CardActions>

          </Card>
        </Grid>
      </Grid>
    </main>

  )
}
