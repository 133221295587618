import React, { createRef, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import { 
  Drawer, List, CssBaseline, Divider, IconButton,
  ListItem, ListItemIcon, ListItemText, Typography,
  Badge
} from '@material-ui/core';


import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


import DashboardIcon from '@material-ui/icons/Dashboard';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import WorkIcon from '@material-ui/icons/Work';
import PeopleIcon from '@material-ui/icons/People';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import FiberNewIcon from '@material-ui/icons/FiberNew';

import TopBar from './TopBar'
import ProfileDialog from './ProfileDialog'

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  }
})

export interface MiniDrawer extends React.HTMLAttributes<HTMLDivElement & React.Component> { 
  openDrawer: () => void
  closeDrawer: () => void

  goToDashboard: () => void
  goToRecordings: () => void
  goToSongs: () => void
  goToWorkspace: () => void
  goToAdmin: () => void

  //goToAIDemos:() => void

  goToChordDetector:() => void

  user: Labeller | null
  page: string
 }

export default function MiniDrawer({ openDrawer, closeDrawer, 
    goToDashboard, goToRecordings, goToSongs, goToChordDetector,
    goToAdmin, goToWorkspace, user, page 
  }: MiniDrawer) {
  const classes = useStyles()
  const theme = useTheme()
  
  const [open, setOpen] = React.useState(false)
  const [highlightIcon, setHighlight] = React.useState("Dashboard")
  
  const [openProfile, setOpenProfile] = React.useState(false)

  useEffect(() => {
    if (page === "EditSong") setHighlight("Songs")
    else if (page === "AudioRecorder") setHighlight("Songs")
    else if (page === "Recording") setHighlight("Recordings")
    else if (page === "RecordingList") setHighlight("Recordings")
    setHighlight(page)
  }, [page])

  useEffect(() => {
    if (window.location.pathname === "/") setHighlight("Dashboard")
    else if (window.location.pathname === "/recordings") setHighlight("Recordings")
    else if (window.location.pathname === "/my-workspace") setHighlight("Workspace")
    else if (window.location.pathname === "/songs") setHighlight("Songs")
    else if (window.location.pathname === "/admin") setHighlight("Admin")
    else if (window.location.pathname === "/demos") setHighlight("Demos")
    else if (
      /\/songs\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}\/edit/g
      .test(window.location.pathname)
    ) setHighlight("Songs")
    else if (
      /\/songs\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}/g
      .test(window.location.pathname)
    ) setHighlight("Songs")
    else if (
      /\/recordings\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}/g
        .test(window.location.pathname)) setHighlight("Recordings")
    else  setHighlight("Error")
  }, [])

  const handleDrawerOpen = () => {
    openDrawer()
    setOpen(true)
  };

  const handleDrawerClose = () => {
    closeDrawer()
    setOpen(false)
  };

  const handleDashboardClick = () => {
    setHighlight("Dashboard")
    goToDashboard()
  }

  const handleRecordingsClick = () => {
    setHighlight("Recordings")
    goToRecordings()
  }
  const handleChordDetectorClick = () => {
    setHighlight("ChordDetector")
    goToChordDetector()
  }
  const handleWorkspaceClick = () => {
    setHighlight("Workspace")
    goToWorkspace()
  }
  
  const handleAdminClick = () => {
    setHighlight("Admin")
    goToAdmin()
  }

  const handleSongsClick = () => {
    setHighlight("Songs")
    goToSongs()
  }

  // const handleDemosClick = () => {
  //   setHighlight("Demos")
  //   goToAIDemos()
  // }

  const openUserProfileModal = () => {
    setOpenProfile(true)
  }

  const closeUserProvileModal = () => {
    setOpenProfile(false)
  }

  return ( user ?
    <div>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button key={"Dashboard"} onClick={handleDashboardClick} selected={highlightIcon === "Dashboard"}>
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
          {/*<ListItem button key={"Demos"} onClick={handleDemosClick} selected={highlightIcon === "Demos"}>
            <ListItemIcon><BlurOnIcon /></ListItemIcon>
            <ListItemText primary={"AI Demos"} />
          </ListItem>*/}
          <ListItem button key={"AudioRecorder"} onClick={handleSongsClick} selected={highlightIcon === "Songs"}>
            <ListItemIcon><RecordVoiceOverIcon /></ListItemIcon>
            <ListItemText primary={"Song Sheets"} />
          </ListItem>
          <ListItem button key={"Recordings"} onClick={handleRecordingsClick} selected={highlightIcon === "Recordings"}>
            <ListItemIcon><LibraryMusicIcon /></ListItemIcon>
            <ListItemText primary={"Recordings"} />
          </ListItem>
          <ListItem button key={"JamRoom"} onClick={() => {}} selected={highlightIcon === "JamRoom"}>
            <ListItemIcon>
              <MeetingRoomIcon />
              <NewReleasesIcon style={{
                position: "relative", fontSize: 15, left: -10, top: -3
              }} color={"primary"}/>
            </ListItemIcon>
              <ListItemText primary={"Jam Room"} />
              <FiberNewIcon style={{fontSize: 17}} color={"primary"}/>
              <div style={{flexGrow: 10}} />
          </ListItem>
        </List>
        <Divider />
        
        <List>
          { open ?
            <Typography variant="caption" display="block"
              style={{color: "grey", paddingLeft: 20}}
            >AI Demos</Typography>:
            <Typography variant="caption" display="block"
              style={{color: "grey", paddingLeft: 23}}
            >AI</Typography>
          }
          <ListItem button key={"ChordDetector"} onClick={handleChordDetectorClick} selected={highlightIcon === "ChordDetector"}>
            <ListItemIcon><BlurOnIcon /></ListItemIcon>
            <ListItemText primary={"Chord Detector"} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key={"Workspace"} onClick={handleWorkspaceClick} selected={highlightIcon === "Workspace"}>
            <ListItemIcon><WorkIcon /></ListItemIcon>
            <ListItemText primary={"My Workspace"} />
          </ListItem>
          {
            user.roles.includes("ADMIN") ? 
            <ListItem button key={"Admin"} onClick={handleAdminClick} selected={highlightIcon === "Admin"}>
              <ListItemIcon><PeopleIcon /></ListItemIcon>
              <ListItemText primary={"Admin"} />
            </ListItem>:null
          }
        </List>
      </Drawer>
      <TopBar clickOpen={handleDrawerOpen} clickClose={handleDrawerClose} open={open} 
        user={user} openUserProfileModal={openUserProfileModal}
      />
      <ProfileDialog open={openProfile} user={user} handleClose={closeUserProvileModal} />
    </div>:
    <TopBar clickOpen={handleDrawerOpen} clickClose={handleDrawerClose} open={open} 
      user={user} openUserProfileModal={openUserProfileModal}
    />
  );
}