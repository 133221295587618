import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Backdrop, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button } from '@material-ui/core';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';

import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import PowerInputIcon from '@material-ui/icons/PowerInput';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import InfoIcon from '@material-ui/icons/Info';

import Autolabel from './ActionPopup/AutoLabel'
import RecordingData from './ActionPopup/RecordingData'
import { SaveAlt } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 10,
      position: "fixed",
      //transform: 'translateZ(0px)',
      flexGrow: 1,
    },
    speedDial: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

const _LABELLER_EMAIL_DEFAULT = "not assigned"

export interface RecordingDataFABProps {
  data: FlattenedData,
  soundBlob: string|null,
  markComplete: () => void,
  saveLabelStudioConfig: (lstudioIput: LStudioInputProps) => Promise<boolean>
}

export default function RecordingDataFAB({ 
  data, markComplete, soundBlob, saveLabelStudioConfig
}: RecordingDataFABProps ) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [showPopup, setShowPopup] = React.useState(false)

  const [metaInfoOpen, setMetaInfoOpen] = React.useState(false)
  const [actions, setActions] = React.useState(
    [ // NOTE: We use this just for TYPE complience 
      { id: "assignSomeone", icon: <PeopleAltIcon />, name: 'Assign Someone Else', onClick: () => {}, minWidth: "160px" },
      { id: "autolabel", icon: <PowerInputIcon />, name: 'Add to Workspace', onClick: () => {}, minWidth: "140px" },
      { id: "unassign", icon: <BlockIcon />, name: 'Unassign', onClick: () => {}, minWidth: "70px" },
      { id: "markComplete_AssignMe",
        icon: data.labellerEmail?<CheckIcon />:<PersonIcon />, 
        name: data.labellerEmail?'Mark Complete':'Assign Me!', 
        onClick: (data: FlattenedData, markComplete: () => void) => {},
        minWidth: data.labellerEmail?"115px":"85px" },
      { id: "info", icon: <InfoIcon />, name: 'Meta Info', onClick: () => { setMetaInfoOpen(true) }, minWidth: "72px" },
    ]
  )
  useEffect(() => {
    let newActions = [ // Default values
      { id: "assignSomeone", icon: <PeopleAltIcon />, name: 'Assign Someone Else', onClick: () => {}, minWidth: "160px" },
      { id: "autolabel", icon: <PowerInputIcon />, name: 'Auto Label', onClick: () => { setShowPopup(true) }, minWidth: "80px" },
      { id: "unassign", icon: <BlockIcon />, name: 'Unassign', onClick: () => {}, minWidth: "70px" },
      { id: "markComplete_AssignMe",
        icon: data.labellerEmail!==_LABELLER_EMAIL_DEFAULT?<CheckIcon />:<PersonIcon />, 
        name: data.labellerEmail!==_LABELLER_EMAIL_DEFAULT?'Mark Complete':'Assign Me!', 
        onClick: (data: FlattenedData, markComplete: () => void) => {
          if (data.labellerEmail!==_LABELLER_EMAIL_DEFAULT) {
            //console.log(`MarkComplete called ${data.labellerEmail}`)
            markComplete()
          } else {  } //TODO
        },
        minWidth: data.labellerEmail!==_LABELLER_EMAIL_DEFAULT?"115px":"85px" 
      },
      { id: "info", icon: <InfoIcon />, name: 'Meta Info', onClick: () => { setMetaInfoOpen(true) }, minWidth: "72px" },
    ]

    //console.log(`chekc labeller email: ${data.labellerEmail}`)
    //console.log(newActions)

    if (data.labellerEmail === _LABELLER_EMAIL_DEFAULT) {
      newActions.splice(2, 1)
    }

    setActions(newActions)
  }, [data, markComplete])

  const closeMetaInfo = () => { setMetaInfoOpen(false) }

  const handleOpen = () => { setOpen(true) }
  const handleClose = () => { setOpen(false) }

  return (
    <div className={classes.root}>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className={classes.speedDial}
        hidden={false}
        icon={<SettingsIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={
              <div style={{minWidth: action.minWidth}}>{action.name}</div>
            }
            tooltipOpen
            onClick={() => action.onClick(data, markComplete)}
          />
        ))}
      </SpeedDial>
      <RecordingData data={data} open={metaInfoOpen} handleClose={closeMetaInfo}/>
      <Autolabel data={data} soundBlob={soundBlob} 
        saveLabelStudioConfig={saveLabelStudioConfig} open={showPopup} handleClose={() => { setShowPopup(false) }} 
      />
    </div>
  );
}