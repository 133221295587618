import React, { useEffect } from 'react'
import { 
  Paper, SvgIcon
} from '@material-ui/core'
import { getChordByNumber } from '../../Songs/EditSong/EditSongUtil'

export interface AutoTapHUDProps {
  oslynJson: OslynSongJson,
  position: number,
  chordPosition: number,
  rawKey: string
}


export default function AutoTapHUD({
  oslynJson,
  position,
  chordPosition,
  rawKey
}: AutoTapHUDProps) {

  const [curChord, setCurChord] = React.useState<OslynSongJsonChord|null>(null)
  const [nextChord, setNextChord] = React.useState<OslynSongJsonChord|null>(null)

  useEffect(() => {
    // current chord
    if ( 
      position >= 0 && chordPosition-1 >= 0 &&
      position < oslynJson.song.length && 
      chordPosition-1 < oslynJson.song[position].chords.length
    ){
      setCurChord(oslynJson.song[position].chords[chordPosition-1])
    } else setCurChord(null)

    // next chord
    if (
      position >= 0 && chordPosition >= 0 &&
      position < oslynJson.song.length && 
      chordPosition < oslynJson.song[position].chords.length
    ){ setNextChord(oslynJson.song[position].chords[chordPosition]) } 
    else if (
      position >= 0 && position < oslynJson.song.length
    ){ setNextChord(oslynJson.song[position].chords[0]) }
    else setNextChord(null)
    
  }, [position, chordPosition, rawKey])

  return (
    <Paper style={{
      border: "solid #651fff", borderRadius: 10, width: 200, height: 100, 
      display: "flex", padding: 10, flexDirection: "column",
      marginLeft: 50, position: "relative", top: -100
    }}>
      <div>Chord &amp; Count</div>
      <div style={{
        fontSize: 30, display: "flex", flexDirection: "row", 
        alignItems: "center", justifyContent: "center"
      }}>
        <div>
          { curChord ? 
            getChordByNumber(curChord.chord, curChord.isMinor, rawKey) : "Start"
          }
        </div>
        { curChord ?
          <div style={{fontSize: 20}}>{ curChord.beats }</div> : <div style={{width: 0}} />
        }
        <div style={{paddingLeft: 10, paddingRight: 10}}>
          <SvgIcon>
            <defs>
              <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{
                  stopColor: "black",
                  stopOpacity: 1
                }} />
                <stop offset="100%" style={{
                  stopColor: "grey",
                  stopOpacity: 1
                }} />
              </linearGradient>
            </defs>
            <path fill={"url(#grad1)"} d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path>
          </SvgIcon>
        </div>
        <div style={{color: "grey"}}>
          { nextChord ? 
            getChordByNumber(nextChord.chord, nextChord.isMinor, rawKey) : "Start"
          }
        </div>
        { nextChord ?
          <div style={{fontSize: 20, color: "grey"}}>{ nextChord.beats }</div> : <div style={{width: 0}} />
        }
        
      </div>
    </Paper>
  )


}