import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles'
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"

import { Fade, Dialog, DialogTitle, DialogActions, Button, DialogContentText, DialogContent } from '@material-ui/core'

import MainDrawer from './components/App/Drawer'
import LoginOrSignup from './components/Login/LoginOrSignup'

import Dashboard from './components/Dashboard/Dashboard'
import Songs from './components/Songs/Songs'
import EditSong from './components/Songs/EditSong/EditSong'
import AudioRecorder from './components/AudioRecorder/AudioRecorder'
import Recordings from './components/Recordings/Recordings'
import LStudio from './components/LabelStudio/LStudio'
import Workspace from './components/WorkSpace/WorkSpace'
import Admin from './components/Admin/Admin'
import AIDemos from './components/AIDemos/AIDemos'

import ChordDetector from './components/ChordDetector/ChordDetector'

import config from './config.json'

const mainTheme = createMuiTheme({
  palette: {
    primary: { main: "#651fff" },
    secondary: { main: "#ff8a65" }
  }
})

const drawerWidthClosed = 73
const drawerWidthOpen = 240


const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    marginLeft: `${drawerWidthClosed}px`,
    width: `calc(100% - ${drawerWidthClosed}px)`,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "white"
  },
  drawerOpen: {
    transition: theme.transitions.create(['width', 'margin-left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create(['width', 'margin-left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
}))

const _ALLOWV1USER_REGISTRATION = true
export default function App() {
  const classes = useStyles();
  const [user, setUser] = useState<Labeller|null>(null)
  const [drawerState, setDrawerState] = useState(false)

  // Home, RecordingList, Recording, Workspace, Demos, Error
  const [page, setPage] = useState("Home")
  const [singleRecordingId, setSingleRecordingId] = useState("")
  const [singleSongId, setSingleSongId] = useState("")
  const [audioRecorderSongId, setAudioRecorderSongId] = useState("")

  const [hasAccess, setAccess] = useState(true)

  const checkAccess = (roles: string[], page: string) => {
    if (page === "Admin" && !roles.includes("ADMIN")) setAccess(false)
    else setAccess(true)
  }

  useEffect(() => {
    if (user) checkAccess(user.roles, page)
    else console.warn("No user!")
  },[user, page])

  useEffect(() => {
    Amplify.configure({
      Auth: {
        mandatorySignId: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        authenticationFlowType: 'USER_PASSWORD_AUTH'
      },
      Storage: {
        AWSS3: {
          bucket: 'oslyn-sound',
          region: 'us-east-2'
        }
      }
    })
    
    let authcheck = async () => {
      try {
        const user: awsCognitoUser = await Auth.currentAuthenticatedUser() as awsCognitoUser
        console.log(user)
        let comprehensiveUser = await getUserFromDB(user)
        console.log(comprehensiveUser)
        setUser( comprehensiveUser )
      } catch (err) {
        console.log(err)
      }
    }

    authcheck()
    setPage(getPage())
    setPageTitle()

    window.onpopstate = () => {
      goToPage()
      setPageTitle()
    }
  }, [])

  interface ListUsers {
    listLabellers: {
      items: Labeller[]
    }
  }

  const getUserFromDB = (user: awsCognitoUser): Promise<Labeller|null> => {
    return new Promise<Labeller|null>( async resolve => {
      let query = `query listUser {
        listLabellers(filter: {
            email: { eq: "${user.attributes.email}" }
          },
          limit: 10
        ) { items { id username email roles oslynTeacherEmail } }
      }`

      // removed filter: username: { eq: "${user.username}" }

      let data = ( await API.graphql(graphqlOperation(query))) as GraphQLResult<ListUsers>
      let userList = data.data? data.data.listLabellers.items : []

      if (userList.length === 1) {
        resolve(userList[0])
      } else if (userList.length === 0 && _ALLOWV1USER_REGISTRATION) {
        let params = {
          body: {
            username: user.username,
            email: user.attributes.email
          }
        }
        let data = await API.post('oslynstudiov1', '/studio/user/add', params ) as any
        if (data.error) {
          console.log(data.error)
          resolve(null)
        } else {
          console.log(data)
          let labeller = data.body.data.createLabeller as Labeller
          console.log(labeller)
          resolve(labeller)
        }
      } else if (userList.length === 0 && !_ALLOWV1USER_REGISTRATION){
        alert("V1 User registration is not allowed. Please contact our support support@oslyn.io")
        resolve(null)
      } else {
        alert("multiple users found. Please contact our support support@oslyn.io")
        resolve(null)
      }
    })
  }
  
  const openDrawer = () => { setDrawerState(true) }
  const closeDrawer = () => { setDrawerState(false) }

  const goToHome = () => {
    setPage("Home") //Dashboard
    window.history.pushState("", "Oslyn Studio | Home", "/")
    window.scrollTo(1,1)
    setPageTitle()
  }

  const goToRecordingList = () => {
    setPage("RecordingList")
    window.history.pushState("", "Oslyn Studio | Recordings", "/recordings")
    window.scrollTo(1,1)
    setPageTitle()
  }

  const goToRecording = (id: string) => {
    setSingleRecordingId(id)
    setPage("Recording")
    window.history.pushState("", "Oslyn Studio | Recordings", `/recordings/${id}`)
    window.scrollTo(1,1)
    setPageTitle()
  }

  const goToWorkspace = () => {
    setPage("Workspace")
    window.history.pushState("", "Oslyn Studio | My Workspace", `/my-workspace`)
    window.scrollTo(1,1)
    setPageTitle()
  }

  const goToAdmin = () => {
    setPage("Admin")
    window.history.pushState("", "Oslyn Studio | Admin", "/admin")
    window.scrollTo(1,1)
    setPageTitle()
  }

  const goToSongs = () => {
    setPage("Songs")
    window.history.pushState("", "Oslyn Studio | Songs", "/songs")
    window.scrollTo(1,1)
    setPageTitle()
  }

  const goToEditSong = (id: string) => {
    setSingleSongId(id)
    setPage("EditSong")
    window.history.pushState("", "Oslyn Studio | Song Editor", `/songs/${id}/edit`)
  }

  const goToAudioRecorder = (id: string) => {
    setAudioRecorderSongId(id)
    setPage("AudioRecorder")
    window.history.pushState("", "Oslyn Studio | Song Recorder", `/songs/${id}`)
  }

  const goToAIDemos = () => {
    setPage("Demos")
    window.history.pushState("", "Oslyn Studio | Demos", `/demos`)
  }

  const goToChordDetector = () => {
    setPage("ChordDetector")
    window.history.pushState("", "Oslyn Studio | Chord Detector", `/chord-detector`)
  }

  const getPage = () => {
    if (window.location.pathname === "/") return "Home"
    else if (window.location.pathname === "/recordings") return "RecordingList"
    else if (window.location.pathname === "/my-workspace") return "Workspace"
    else if (window.location.pathname === "/admin") return "Admin"
    else if (window.location.pathname === "/songs") return "Songs"
    else if (window.location.pathname === "/demos") return "Demos"
    else if (window.location.pathname === "/chord-detector") return "ChordDetector"

    else if (
      /\/songs\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}\/edit/g
      .test(window.location.pathname)
    ) {
      let pathName = window.location.pathname.split("/edit")[0]
      let pathName2 = pathName.split("/")
      setSingleSongId(pathName2[pathName2.length-1])
      return "EditSong"
    } else if (
      /\/songs\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}/g
          .test(window.location.pathname)){
      let pathName = window.location.pathname.split("/")
      setAudioRecorderSongId(pathName[pathName.length-1])
      return "AudioRecorder"
    } else if (
      /\/recordings\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}/g
          .test(window.location.pathname)){
      let pathName = window.location.pathname.split("/")
      setSingleRecordingId(pathName[pathName.length-1])
      return "Recording"
    } else return "Error"
  }

  const goToPage = () => {
    console.log(window.location.pathname)
    if (window.location.pathname === "/") { goToHome() }
    else if (window.location.pathname === "/recordings") {
      goToRecordingList()
    } else if (window.location.pathname === "/my-workspace") {
      goToWorkspace()
    } else if (window.location.pathname === "/admin") {
      goToAdmin()
    } else if (window.location.pathname === "/songs") {
      goToSongs() 
    } else if (window.location.pathname === "/demos") {
      goToAIDemos()
    } else if (window.location.pathname === "/chord-detector") {
      goToChordDetector()

    } else if (
      /\/songs\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}\/edit/g
      .test(window.location.pathname)
    ) {
      let pathName = window.location.pathname.split("/edit")[0]
      let pathName2 = pathName.split("/")
      setSingleSongId(pathName2[pathName2.length-1])
      goToEditSong(pathName2[pathName2.length-1])
    } else if (
      /\/songs\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}/g
      .test(window.location.pathname)
    ) {
      let pathName = window.location.pathname.split("/")
      setAudioRecorderSongId(pathName[pathName.length-1])
      goToAudioRecorder(pathName[pathName.length-1])
    } else if (
      /\/recordings\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}/g
          .test(window.location.pathname)){
      let pathName = window.location.pathname.split("/")
      setSingleRecordingId(pathName[pathName.length-1])
      goToRecording(pathName[pathName.length-1])
    }
    return
  }

  const setPageTitle = () => {
    if (window.location.pathname === "/") document.title = "Oslyn | Home"
    else if (window.location.pathname === "/recordings")  document.title = "Oslyn Studio | Recordings"
    else if (window.location.pathname === "/my-workspace")  document.title = "Oslyn Studio | My Workspace"
    else if (window.location.pathname === "/admin") document.title = "Oslyn Studio | Admin"
    else if (window.location.pathname === "/songs") document.title = "Oslyn Studio | Songs"
    else if (window.location.pathname === "/demos") document.title = "Oslyn Studio | Demos"
    else if (window.location.pathname === "/chord-detector") document.title = "Oslyn Studio | Chord Detector"
    else if (
      /\/songs\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}\/edit/g
      .test(window.location.pathname)) document.title = "Oslyn Studio | Song Editor"
    else if (
      /\/songs\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}/g
        .test(window.location.pathname)) document.title = "Oslyn Studio | Song Recorder"
    else if (
      /\/recordings\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}/g
        .test(window.location.pathname)) document.title = "Oslyn Studio | Recordings"
    else  document.title = "Oslyn Studio | Error"
  }

  return (
    <MuiThemeProvider theme={mainTheme}>
      <MainDrawer openDrawer={openDrawer} closeDrawer={closeDrawer} 
        goToDashboard={goToHome} goToRecordings={goToRecordingList} 
        goToWorkspace={goToWorkspace} goToAdmin={goToAdmin} 
        goToSongs={goToSongs} goToChordDetector={goToChordDetector}
        user={user} page={page}
      />
      <main
        className={clsx(classes.content, {
          [classes.drawerOpen]: drawerState,
          [classes.drawerClose]: !drawerState,
        })}
        style={{ 
          width: user ? ( drawerState ? `calc(100% - ${drawerWidthOpen}px)` : `calc(100% - ${drawerWidthClosed}px)` ) : '100%',
          marginLeft: user ? ( drawerState ? `${drawerWidthOpen}px` : `${drawerWidthClosed}px` ) : '0px'
        }
      }>
        { user ?  
          <div>
            <Fade in={page === "Home"}>
              <div style={{
                visibility: page === "Home"?"visible":"hidden",
                display: page === "Home"?"":"None",
                height: page !== "Home"?0:"auto"
              }}>
                <Dashboard page={page} initialWidth={`calc(100% - ${drawerWidthClosed}px)`}
                  goToSongs={goToSongs} goToRecordings={goToRecordingList} 
                />
              </div>
            </Fade>
            <Fade in={page === "Songs"}>
              <div style={{
                visibility: page === "Songs"?"visible":"hidden",
                display: page === "Songs"?"":"None",
                height: page !== "Songs"?0:"auto"
              }}>
                <Songs user={user} page={page} matchpage={"Songs"} 
                  goToEditSong={(id) => goToEditSong(id)}
                  goToAudioRecorder={(id) => goToAudioRecorder(id)} />
              </div>
            </Fade>
            <Fade in={page === "EditSong"} style={{overflowY:"hidden"}}>
              <div style={{
                visibility: page === "EditSong"?"visible":"hidden",
                display: page === "EditSong"?"":"None",
                height: page !== "EditSong"?0:"auto",
                overflowY:"hidden"
              }}>
                <EditSong id={singleSongId} page={page} goToSongs={goToSongs}/>
              </div>
            </Fade>
            <Fade in={page === "AudioRecorder"} style={{overflowY:"hidden"}}>
              <div style={{
                visibility: page === "AudioRecorder"?"visible":"hidden",
                display: page === "AudioRecorder"?"":"None",
                height: page !== "AudioRecorder"?0:"auto",
                overflowY:"hidden"
              }}>
                <AudioRecorder id={audioRecorderSongId} page={page} user={user}
                  goToLabelStudio={(id) => goToRecording(id)}
                />
              </div>
            </Fade>
            <Fade in={page === "RecordingList"}>
              <div style={{
                visibility: page === "RecordingList"?"visible":"hidden",
                display: page === "RecordingList"?"":"None",
                height: page !== "RecordingList"?0:"auto"
              }}>
                <Recordings 
                  user={user} title={"Recordings"}
                  goToLabelStudio={(id) => goToRecording(id)}
                  page={page} matchpage={"RecordingList"}
                />
              </div>
            </Fade>
            <Fade in={page === "Recording"}>
              <div style={{
                visibility: page === "Recording"?"visible":"hidden",
                display: page === "Recording"?"":"None",
                height: page !== "Recording"?0:"auto"
              }}>
                <LStudio id={singleRecordingId} goToRecordings={goToRecordingList} user={user} page={page}/>
              </div>
            </Fade>
            <Fade in={page === "Workspace"}>
              <div style={{
                visibility: page === "Workspace"?"visible":"hidden",
                display: page === "Workspace"?"":"None",
                height: page !== "Workspace"?0:"auto"
              }}>
                <Workspace user={user} page={page}
                  goToLabelStudio={(id) => goToRecording(id)}
                  goToRecordingList={goToRecordingList}
                  goToSongs={goToSongs}
                />
              </div>
            </Fade>
            <Fade in={page === "Demos"}>
              <div style={{
                visibility: page === "Demos"?"visible":"hidden",
                display: page === "Demos"?"":"None",
                height: page !== "Demos"?0:"auto"
              }}>
                <AIDemos />
              </div>
            </Fade>
            <Fade in={page === "Admin"}>
              <div style={{
                visibility: page === "Admin"?"visible":"hidden",
                display: page === "Admin"?"":"None",
                height: page !== "Admin"?0:"auto"
              }}>
                <Admin user={user} goToHome={goToHome}/>
              </div>
            </Fade>
            <Fade in={page === "ChordDetector"}>
              <div style={{
                visibility: page === "ChordDetector"?"visible":"hidden",
                display: page === "ChordDetector"?"":"None",
                height: page !== "ChordDetector"?0:"auto"
              }}>
                <ChordDetector />
              </div>
            </Fade>
            <Dialog
              open={!hasAccess}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } }}
            >
              <DialogTitle id="alert-dialog-title">Opps .. looks like you dont have access.</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  If you believe you <b>SHOULD</b> have access, you probably already know who to contact :)<br />
                  But heres a link for you to click on anyway <a href="mailto:support@oslyn.io">support@oslyn.io</a>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => { goToHome() }} color="primary" autoFocus>
                  Okay
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        : <LoginOrSignup login={async () => {
            try {
              const user = await Auth.currentAuthenticatedUser()
              let comprehensiveUser = await getUserFromDB(user) //GET user also writes user if none found
              console.log(comprehensiveUser)
              setUser( comprehensiveUser ) 
            } catch(error) {
              console.log(error)
            } 
        }}/>}
      </main>
    </MuiThemeProvider>
  )
}
