import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Box, Collapse, IconButton, Table, TableBody, TableCell,
        TableHead, TableRow, Typography, Button, Tooltip
      } from '@material-ui/core';

import { API, graphqlOperation } from 'aws-amplify'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import NotInterestedIcon from '@material-ui/icons/NotInterested';


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  toggleButton: {
    fontSize: 10
  }
});

export default function LabellerTableRow(props: { row: LabellerWithHistory }) {
  const { row } = props;
  const classes = useRowStyles()
  const [open, setOpen] = React.useState(false);
  const [roles, setRoles] = React.useState<('AE'|'AELEAD'|'ADMIN')[]>(['AE'])

  useEffect(() => {
    setRoles(row.roles)
  }, [props])

  const handleRoles = async (event: React.MouseEvent<HTMLElement>, newRoles: ('AE'|'AELEAD'|'ADMIN')[]) => {
    postRoleChange( row.id, newRoles )
    setRoles(newRoles)
  }

  const postRoleChange = async (
    id: string, roles: ('AE'|'AELEAD'|'ADMIN')[]
  ): Promise<boolean> => {
    return new Promise ( async resolve => {
      let params = {
        body: { userId: id, roles: JSON.stringify(roles) }
      }
  
      try {
        let data = await API.post('oslynstudiov1', '/studio/user/role', params) as any
        if (data.err) alert(`error: ${data.err}`)
        console.log(data)
      } catch (e) {
        alert(`error: ${e}`)
      }
    })
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="right">{row.username}</TableCell>
        <TableCell align="right">{row.email}</TableCell>
        <TableCell align="right">
          <ToggleButtonGroup
            value={roles}
            onChange={handleRoles}
            aria-label="text alignment"
          >
            <ToggleButton value="AE" aria-label="left aligned" className={classes.toggleButton}>AE</ToggleButton>
            <ToggleButton value="AELEAD" aria-label="centered" className={classes.toggleButton}>AE LEAD</ToggleButton>
            <ToggleButton value="ADMIN" aria-label="right aligned" className={classes.toggleButton}>ADMIN</ToggleButton>
          </ToggleButtonGroup>
        </TableCell>
        <TableCell align="right" >
          <div style={{display:"flex", flexDirection:"row", height:"100%" }}>
            {/*<Tooltip title="Track Payments">
              <Button color="primary" style={{padding:16}}><AttachMoneyIcon /></Button>
            </Tooltip>*/}
            <Tooltip title="Disable User">
              <Button style={{padding:16, color: "grey"}}><NotInterestedIcon /></Button>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Payment History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Payment Date</TableCell>
                    <TableCell>Cuttoff Date</TableCell>
                    <TableCell>Identifier</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Currency</TableCell>
                    <TableCell align="right">Administrator</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.id}>
                      <TableCell component="th" scope="row">
                        {historyRow.postdate}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {historyRow.cutoffmonth}/{historyRow.cutoffyear}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {historyRow.id}
                      </TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">{historyRow.currency}</TableCell>
                      <TableCell align="right">{historyRow.admin}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}