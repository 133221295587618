import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TableCell, TableHead, TableRow, TableSortLabel, Checkbox } from '@material-ui/core'

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean
  id: keyof FlattenedData
  label: string
  numeric: boolean
  isCenter: boolean
  roles: string[]
}

const _ALL = ["ADMIN", "AELEAD", "AE"]
const headCells: HeadCell[] = [
  { id: 'songTitle', numeric: false, disablePadding: true, isCenter: false, label: 'Song Title', roles: _ALL },
  { id: 'singerName', numeric: false, disablePadding: false, isCenter: true, label: 'Name', roles: ["ADMIN"] },
  { id: 'singerEmail', numeric: false, disablePadding: false, isCenter: false, label: 'Email', roles: ["ADMIN"] },
  { id: 'id', numeric: false, disablePadding: false, isCenter: true, label: 'Unique Identifier', roles: ["AELEAD", "AE"] },
  { id: 'labellerEmail', numeric: false, disablePadding: true, isCenter: true, label: 'Audio Engineer', roles: _ALL },
  { id: 'createDate', numeric: true, disablePadding: false, isCenter: true, label: 'Created', roles: _ALL },
  { id: 'status', numeric: false, disablePadding: false, isCenter: true, label: 'Status', roles: _ALL },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    margin: {
      margin: "0px",
    },
  }),
);

interface EnhancedTableProps {
  user: Labeller
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof FlattenedData) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

export default function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof FlattenedData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => {
          
          let contains = false
          for (let i in headCell.roles) {
            if (props.user.roles){
              if (props.user.roles.includes(headCell.roles[i])) {
                contains = true
                break
              }
            } else console.log("no user.roles in RecordingListTableBody")
          }

          return  contains ? <TableCell
                    key={headCell.id}
                    align={headCell.isCenter ? 'center' : (headCell.numeric ? 'right' : 'left') }
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell> : null

        })}
        <TableCell align="center">Action</TableCell>
      </TableRow>
    </TableHead>
  );
}