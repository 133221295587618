import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, Typography,
  DialogTitle, DialogContentText, Button, TextField } from '@material-ui/core';

import ChipInput from 'material-ui-chip-input'
import { lStudioAllowedLabels, recordingToLabelStudioInput } from '../LStudioUtils'

import getBlobDuration from 'get-blob-duration'
const _AUDIO_PADDING = 1000

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: "80px"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    textField: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '50ch',
    }
  }),
);

export interface AutoLabelProps {
  data: FlattenedData
  soundBlob: string|null
  open: boolean
  handleClose: () => void
  saveLabelStudioConfig: (lstudioIput: LStudioInputProps) => Promise<boolean>
}

export default function AutoLabel({ 
  data, soundBlob, open, handleClose, saveLabelStudioConfig
}: AutoLabelProps) {
  const classes = useStyles()

  const [chips, setChips] = React.useState<string[]>([])
  const [warning, setWarning] = React.useState<string>("")
  const [multiplier, setMultiplier] = React.useState<number|null>(1)

  const handleAddChip = (chip: string) => {

    let newChip = null as string|null
    for (let label of lStudioAllowedLabels) {
      if (chip.trim().toLowerCase() === label.toLowerCase()) {
        newChip = label; break
      }
    }

    if (newChip) {
      setWarning("")
      let newChips = [...chips]
      newChips.push(newChip)
      setChips(newChips)
    } else {
      if (chip === "A#") setWarning("Please use Bb instead!")
      else if (chip === "A#m") setWarning("Please use Bbm instead!")
      else setWarning(`"${chip}" is not a chord / acceptable label`)
    }
  }

  const handleDeleteChip = (chip: string, index: number) => {
    let newChips = [...chips]
    newChips.splice(index, 1)
    setChips(newChips)
  }

  const setRecordingLabelInput = async () => {

    let ourRecording = { song: [] } as Recording
    if (soundBlob && multiplier) {
      let duration = await getBlobDuration(soundBlob)
      duration = (duration * 1000) - _AUDIO_PADDING
      if (chips.length > 0 && multiplier > 0) {

        // Calculate the duration of each chord
        let chordDuration = duration / (chips.length * multiplier)
        let time = _AUDIO_PADDING / 2

        for (let j=0; j<multiplier; j++) {
          for (let i=0; i<chips.length; i++) {

            ourRecording.song.push({
              chord: chips[i],
              start: time,
              end: time + chordDuration
            })

            time = time + chordDuration
          }
        }

        let lstudioInput = recordingToLabelStudioInput(ourRecording, "")
        if ( await saveLabelStudioConfig(lstudioInput) ) {
          console.log("SAVED VIA AUTOLABEL, refresh")
          handleClose()
        } console.warn("could not save via AutoLabel")
      } console.warn("chips is empty or multiplier not > 0")
    } else console.warn("soundBlob is null or multiplier is non-existant")
  }

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Auto Label
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Repeat the chords you want "X" times. Input the chords below ie. A, C#m, Bb etc. 
            Press enter after every chord.<br />
            <span style={{color: "red"}}>
              <b>CAUTION</b>: This tool overrides whatever already exists.
            </span>
          </DialogContentText>

          <Typography><b>Input:</b> Chords / "Noise" / "Silence"</Typography>
          <ChipInput
            value={chips}
            onAdd={(chip) => handleAddChip(chip)}
            onDelete={(chip, index) => handleDeleteChip(chip, index)}
            allowDuplicates={true}
          /><br />
          <p style={{color: "red", fontSize: 10, minHeight: 20}}>{warning}</p>

          <TextField
            label="Multiplier"
            id="outlined-margin-dense"
            value={multiplier}
            type="number"
            className={classes.textField}
            helperText="Your sequence will be repeated this many times"
            margin="dense"
            variant="outlined"

            onChange={(e) => { 
              let newValue = parseInt(e.target.value)
              if (e.target.value === "") setMultiplier(null)
              else if (!isNaN(newValue) && newValue > 0) setMultiplier(newValue) }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={setRecordingLabelInput} color="primary" autoFocus>
            Set
          </Button>
        </DialogActions>
      </Dialog>
  )

}