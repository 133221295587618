import React, { useEffect } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'

import { rawChordsheetToIntermediaryJson } from '../../EditSongUtil'
import Step1SectionList from './Step1SectionList'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

export interface Step1Props {
  editorId: string
  intermediaryJson: IntermediaryJson|null
  setIntermediaryJson: (intermediaryJson: IntermediaryJson) => void
}

export default function Step1({ editorId, intermediaryJson, setIntermediaryJson }: Step1Props) {
  const classes = useStyles()
  
  const setSections = (section: IntermediaryJsonSection, index: number) => {
    if (intermediaryJson) {
      intermediaryJson.sections[index] = section
      setIntermediaryJson(intermediaryJson)
      //console.log(intermediaryJson)
    } else alert("Step1.tsx: could not set setIntermediaryJson.")
  }

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Parsed Chord Structure
        </ListSubheader>
      }
      className={classes.root}
    >   
      {intermediaryJson? intermediaryJson.sections.map( (section, index) => {
        let randomNum = Math.floor(Math.random() * 9999999)
        return (
          <Step1SectionList editorId={editorId} key={`${section.name}-${randomNum}`} 
            section={section} setSection={setSections} sectionIndex={index}
          />)
      }) : null}
    </List>
  );
}