export const LABELSTUDIO_KEY = "label-studio"
export const LABELSTUDIO_VERSION = "0.8.0"

export const OSLYNCSV_KEY = "oslyn-universal-label"
export const OSLYNCSV_VERSION = "1"

export function LStudioOutputToFileConverter (data : LStudioOutputProps): LStudioInputProps {
  let randomNum = Math.floor(Math.random() * 999999); 

  let completions = []
  let newResultList = [] as InputResult[]
  for (let i of Object.keys(data.areas)) {
    let area = data.areas[i]
    console.log(`area :: ${i}`)
    let newResult: InputResult|null = null

    for (let j in area.results) {
      let result = area.results[j]
      for (let k in result.value.labels) {
        if (newResult) {
          newResult.value.labels.push(result.value.labels[k]) 
         } else {
           newResult = {
             from_name: "label",
             id: result.id,
             source: "$audio",
             to_name: "audio",
             type: "labels",
             value: { 
               labels: [result.value.labels[k]],
               start: area.start,
               end: area.end
             }
           }
         }
      }
    }

    newResult ? newResultList.push(newResult) :
      console.log("No result array for LStudioInputProps completions")
  }

  let completion = {
    id: "1",
    lead_time: null,
    result: newResultList
  } as InputCompletion
  completions.push(completion)
  
  let newData: LStudioInputProps = {
    completions,
    predictions: [],
    id: randomNum,
    data: { url: "" }
  }
  
  return newData
}

export function LStudioInputToOutputConverter (data : LStudioInputProps ): LStudioOutputProps {
  return {} as LStudioOutputProps
}

export const lStudioAllowedLabels = [
    "A", "Am", "Bb", "Bbm", "B", "Bm", "C", "Cm", "C#", "C#m", 
    "D", "Dm", "D#", "D#m", "E", "Em", "F", "Fm", "F#", "F#m",
    "G", "Gm", "G#", "G#m", "Noise", "Silence"
  ]

export function LStudioConfig (title: string, key: string, tabs: string, audioTitle: string) {
  return `
  <View>
    <Header value='"${title}", Key: ${key}'></Header>
    <Text name="text-2" value="Tabs: ${tabs}" granularity="symbol" highlightColor="#ff0000" />
    <Labels name="label" toName="audio" choice="multiple">
      
      <!-- red 500 200 -->
      <Label value="A" background="#f44336"></Label>
      <Label value="Am" background="#ef9a9a"></Label>
      
      <!-- pink 500 200 -->
      <Label value="Bb" background="#e91e63"></Label>
      <Label value="Bbm" background="#f48fb1"></Label>
      
      <!-- purple 500 200 -->
      <Label value="B" background="#9c27b0"></Label>
      <Label value="Bm" background="#ce93d8"></Label>
      
      <!-- deepPurple 500 200 -->
      <Label value="C" background="#673ab7"></Label>
      <Label value="Cm" background="#b39ddb"></Label>
      
      <!-- indigo 500 200 -->
      <Label value="C#" background="#3f51b5"></Label>
      <Label value="C#m" background="#9fa8da"></Label>
      
      <!-- blue 500 200 -->
      <Label value="D" background="#2196f3"></Label>
      <Label value="Dm" background="#90caf9"></Label>
      
      <!-- teal 500 200 -->
      <Label value="D#" background="#009688"></Label>
      <Label value="D#m" background="#80cbc4"></Label>
      
      <!-- green 500 200 -->
      <Label value="E" background="#4caf50"></Label>
      <Label value="Em" background="#a5d6a7"></Label>
      
      <!-- lime 500 200 -->
      <Label value="F" background="#cddc39"></Label>
      <Label value="Fm" background="#e6ee9c"></Label>
      
      <!-- yellow 500 200 -->
      <Label value="F#" background="#ffeb3b"></Label>
      <Label value="F#m" background="#fff59d"></Label>
      
      <!-- orange 500 200 -->
      <Label value="G" background="#ff9800"></Label>
      <Label value="Gm" background="#ffcc80"></Label>
      
      <!-- deepOrange 500 200 -->
      <Label value="G#" background="#ff5722"></Label>
      <Label value="G#m" background="#ffab91"></Label>
      
      <!-- grey 800 300 -->
      <Label value="Noise" background="#424242"></Label>
      <Label value="Silence" background="#e0e0e0"></Label>
    </Labels>
    <Header value="${audioTitle}"></Header>
    <AudioPlus name="audio" value="$url"></AudioPlus>
  </View>`
}

export function recordingToLabelStudioInput(recording: Recording, url: string):LStudioInputProps {
  let completions = [{
    id: "1",
    lead_time: null,
    result: [] as InputResult[]
  } as InputCompletion]

  for (let i=0; i<recording.song.length; i++) {
    if (recording.song[i].start && recording.song[i].end) {
      let ir = { } as InputResult

      let start = recording.song[i].start as number
      if (i === 0) { start = 0 } // fix the very first number -- set it to 0

      let end = recording.song[i].end as number
      let id = `${Math.floor(Math.random() * 999999)}`

      // FIX CHORDs FOR LSTUDIO
      if (recording.song[i].chord.includes("Ab")) 
        recording.song[i].chord = "G#"+recording.song[i].chord.split("Ab")[1]
      if (recording.song[i].chord.includes("Eb")) 
        recording.song[i].chord = "D#"+recording.song[i].chord.split("Eb")[1]

      ir.id = id
      ir.value = {
        labels: [recording.song[i].chord],
        start: start/1000, end: end/1000
      }

      ir.from_name = "label"
      ir.id = `label@${id}`
      ir.source = "$audio"
      ir.to_name = "audio"
      ir.type = "labels"
      

      completions[0].result.push(ir)
    }
  }

  let output = {
    completions,
    predictions: [],
    id: Math.floor( Math.random() * 999999 ),
    data: { url }
  } as LStudioInputProps

  return output
}

export function labelStudioInputToCSV(data: LStudioInputProps): string {

  let parseItems = data.completions[0].result
  let items: OslynCSVElement[] = []
  for (let item of parseItems) {
    let element = {} as OslynCSVElement
    element.chord = item.value.labels[0]

    let start = item.value.start
    element.starttime = `${Math.floor(start/3600)}:${Math.floor((start%3600)/60)}:${Math.floor((start%60))}.${Math.floor( (start*1000)%1000 )}`

    let end = item.value.end
    element.endtime = `${Math.floor(end/3600)}:${Math.floor((end%3600)/60)}:${Math.floor((end%60))}.${Math.floor( (end*1000)%1000 )}`

    console.log(JSON.stringify(element))
    items.push(element)
  }

  // in case values are null
  const replacer = (key: string, value: any) => value === null ? '' : value 
  
  const header = Object.keys(items[0])
  const csv = [
    header.join(','),
    ...items.map( (row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
  ].join('\r\n')

  return csv
}

export function checkConfigEqual(config1: LStudioInputProps, config2: LStudioInputProps): boolean {
  let config1Results = config1.completions[0].result
  let config2Results = config2.completions[0].result

  if (config1Results.length !== config2Results.length) {
    console.warn("Configs have different lengths :: failed equality check")
    return false
  }

  for (let i=0; i<config1Results.length; i++) {
    let item1 = config1Results[i]
    let item2 = config2Results[i]

    if (item1.value.start !== item2.value.start) {
      console.warn(`Config values different :: start :: item1 ${item1.value.start} != item2 ${item2.value.start}`)
      return false
    }

    if (item1.value.end !== item2.value.end) {
      console.warn(`Config values different :: end :: item1 ${item1.value.end} != item2 ${item2.value.end}`)
      return false
    }

    if (item1.value.labels[0] !== item2.value.labels[0]) {
      console.warn(`Config values different :: labels :: item1 ${item1.value.labels[0]} != item2 ${item2.value.labels[0]}`)
      return false
    }
  }

  return true
}