import React, { useEffect } from "react"
import { withStyles, createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { 
  DialogContent, DialogContentText, TableHead, Tooltip,
  Table, TableRow, TableCell, Chip, TableBody
} from "@material-ui/core"

import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import { grey } from '@material-ui/core/colors'

import InfoIcon from '@material-ui/icons/Info'

import Section from './utils/Section'

const InfoCheckbox = withStyles({
  root: {
    color: grey[400],
    '&$checked': {
      color: grey[500],
    },
  },
  checked: {},
})((props: CheckboxProps) =>
  <Checkbox checked color="default" icon={<InfoIcon />} checkedIcon={<InfoIcon />} name="checkedI" {...props} />)


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    table: {
      maxWidth: 550
    },
  }),
);

export interface SongChangeSummaryProps {
  oldIJ: IntermediaryJson,
  newIJ: IntermediaryJson,

  newMatches: number[],
  setNewMatches: (m: number[]) => void

  setSectionIndex: (i: number) => void
}

export default function SongChangeSummary ({
  newIJ, oldIJ, newMatches, setNewMatches, setSectionIndex
}: SongChangeSummaryProps) {
  const classes = useStyles()

  const [unmatchedChips, setUnmatchedChips] = React.useState<JSX.Element[]>([])

  useEffect(() => {
    let tempUnmatched = []

    for (let i=0; i<oldIJ.sections.length; i++) {
      if (!newMatches.includes(i)) {
        tempUnmatched.push(
          <Chip key={i}
            style={{ backgroundColor: "#e57373", color: "#b71c1c" }}
            label={<b>{oldIJ.sections[i].name}</b>}
            onClick={() => { }}
            onDelete={() => { }}
            deleteIcon={<span style={{color: "#d32f2f"}}>{i}</span>}
          />
        )
      }
    }

    setUnmatchedChips(tempUnmatched)
  },[newMatches, oldIJ])

  return (<div>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Oh! Looks like the song structure has changed. Don't worry, we'll guide you through.
        </DialogContentText>
        <DialogContentText>
          We've taken the liberty to "match" the new chord sheet - with the old chord durations! <br />
          Set to "None" to create a brand new section (no chord duration data will be transfered).
        </DialogContentText>
        <DialogContentText component="div" id="alert-dialog-description-2">

          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <Tooltip title={
                    <div>
                      <p>What do these symbols mean?? I'm glad you asked.</p>
                      <ul>
                        <li>The "Check" means a full match between the chord sheet and the old chord durations. No action needed!</li>
                        <li>
                          The "Minus" means that there are discrepancies. Maybe you added a "G" chord, or removed a "D" chord in the chord sheet, etc.
                          These changes can result in the lost of chord duration data. Review! You'll be happy you did.
                        </li>
                        <li>
                          "New" means the section was not detected anywhere else in the old chord durations data! 
                          It should be acompanied by a <em>None</em> in the "matches" dropdown.
                          You can change this to a "Check"/"Minus" by forcing a match to a song section that already exists. 
                          We will then try our best to match the chords within. Once again, review!
                        </li>
                      </ul>
                    </div>
                  }>
                  <TableCell padding="checkbox" align={'center'}>
                    <InfoCheckbox />
                  </TableCell>
                </Tooltip>
                <TableCell colSpan={2} align={'center'} style={{borderRight: 'solid 1px lightgrey'}}>Chord Sheet</TableCell>
                <TableCell colSpan={2} align={'center'}>Old Extracted Chords</TableCell>
                <TableCell align={'center'}>View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {newIJ.sections.map((section, index) => {
                  //console.log(section)
                  return <Section key={index} index={index} 
                            sectionName={section.name} oldIJ={oldIJ} newIJ={newIJ}
                            matchIndex={newMatches[index]} 
                            setMatchIndex={(oldSectionNumber) => {
                              newMatches[index] = oldSectionNumber
                              setNewMatches([...newMatches])
                            }}
                            setSectionIndex={setSectionIndex}
                          />
                })}
            </TableBody>
          </Table>
        </DialogContentText>
        <DialogContentText style={{paddingTop: 20}}>
          The following sections (in the side pannel), will be DELETED and cannot be recovered. <br />
          To remove these, <b>match them above</b>. Just a warning, it's fine to have it deleted.
        </DialogContentText>
        <DialogContentText component="div" style={{border: "solid 2px", padding: 5, borderRadius: 5}}>
          {unmatchedChips}
        </DialogContentText>
      </DialogContent>
  </div>)
}