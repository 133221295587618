import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';

import { getChordByNumber, getLeftSpacing } from '../../Songs/EditSong/EditSongUtil'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 500
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    transposeTextField: {
      width: 80
    }
  }),
);

export interface ChordAndLyricProps {
  phrases: OslynSongJsonPhrase[]
  chordPosition: number
  position: number
  rawKey: string

  transpose: number
}

export default function ChordAndLyric({
  phrases, chordPosition, position, rawKey, transpose
}: ChordAndLyricProps) {
  const classes = useStyles()
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight? window.innerHeight: 500)

  const updateWindowDimensions = () => { setWindowHeight(window.innerHeight) }
  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  const [limitPhrases, setLimitPhrases] = React.useState<OslynSongJsonPhrase[]>([])

  useEffect(() => {
    let endPosition = position + 2
    console.log(endPosition)
    // if (endPosition > phrases.length) {
    //   endPosition = phrases.length
    // }

    // let tempLimitPhrases = [] as OslynSongJsonPhrase[]
    // for (let i=position; i<endPosition; i++) {
    //   if (phrases[i].section === phrases[position].section) {
    //     tempLimitPhrases.push(phrases[i])
    //   } else break
    // }

    let tempLimitPhrases = [] as OslynSongJsonPhrase[]
    for (let i=position; i<endPosition; i++) {
      tempLimitPhrases.push(phrases[i])
    }

    setLimitPhrases(tempLimitPhrases)
  }, [position, phrases])

  return (
    <Paper className={classes.paper} style={{minHeight: windowHeight - 310}}>
      { phrases.length > 0 ? 
        <Typography variant="h4" style={{paddingBottom: 10}}>
          {phrases[position].section}
        </Typography> : null 
      }
      {limitPhrases.map( (phrase, index) => {
        let chordLine = [] as JSX.Element[]
        let isCurrentSection = true
        let p = 0

        if (phrase) {
          isCurrentSection = limitPhrases[0].section === phrase.section

          for (let i=0; i<phrase.chords.length; i++) {
            let divisable = Math.floor( (phrase.chords[i].chord + transpose)/12 )
            let chordNum = divisable > 0 ? phrase.chords[i].chord + transpose - 12*divisable : phrase.chords[i].chord + transpose
            
            let paddingLeft = getLeftSpacing(phrase.lyric.substring(p, phrase.chords[i].position))*6
            chordLine.push(
              <span key={`${Math.floor(Math.random()*9999999)}`} style={{
                paddingLeft: `${paddingLeft === 0? 5 : paddingLeft}px`
              }}>
                <span style={{backgroundColor: chordPosition-1 === i && index === 0 ? "rgba(101,31,255, 0.2)" : "inherit"}}>
                  {getChordByNumber(chordNum, phrase.chords[i].isMinor, rawKey)}
                </span>
              </span>
            )
            p = phrase.chords[i].position + 1
          }
        }

        return (
          <div key={`${Math.floor(Math.random()*9999999)}`} style={{
            paddingTop: isCurrentSection ? 0 : 50 
          }}>
            { !isCurrentSection ? 
              <Typography variant="h6"
                style={{color: "grey", fontSize: 18}}
              >{phrase.section}</Typography> : null
            }
            <Typography variant="h6"
              style={{color: isCurrentSection ? "black" : "grey", fontSize: isCurrentSection ? 20 : 15}}
            >{chordLine}</Typography>
            <Typography variant="h6"
              style={{color: isCurrentSection ? "black" : "grey", fontSize: isCurrentSection ? 20 : 15}}
            >{phrase ? phrase.lyric : ""}</Typography>
          </div>
        )
      })}
    </Paper>
  )
}
