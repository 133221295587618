import React, { useEffect } from 'react'
import throttle from "lodash.throttle"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TableBody, TableCell, TableRow, Checkbox,
  ButtonGroup, Button, Tooltip, CircularProgress} from '@material-ui/core'

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import InputIcon from '@material-ui/icons/Input'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { RowsAndToken } from '../RecordingsList'
import { FilterObj } from '../../Popups/FilterPopup'

const _LOADING_CIRCLE_ID = "recordings-loading-circle"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    margin: {
      margin: "0px",
    },
  }),
)

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string | boolean }, b: { [key in Key]: number | string | boolean }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface RecordingListTableBodyProps {
  user: Labeller
  page: string
  matchpage: string

  filter: FilterObj|null
  rowsAndToken: {nextToken: string|null, rows: FlattenedData[] }
  setRowsAndToken: (o: {nextToken: string|null, rows: FlattenedData[]}) => void

  selected: string[]
  handleClick: (event: React.MouseEvent<unknown>, name: string | number) => void
  goToLabelStudio: (id: string) => void
  setAssignOpen: (id: string) => void
  order: Order
  orderBy: keyof FlattenedData
  getData: (nextToken: string|null, rows: FlattenedData[], filter:FilterObj) => Promise<RowsAndToken|null>
  assignLabellerToRecording: (recordingId: string) => void
  unassignLabellerToRecording: (recordingId: string) => void


  //isStart: boolean
  //setIsStart: (b: boolean) => void
}

export default function RecordingListTableBody({ 
    user, selected, handleClick, goToLabelStudio, setAssignOpen, 
    order, orderBy, getData, filter,
    assignLabellerToRecording, unassignLabellerToRecording,
    page, matchpage, rowsAndToken, setRowsAndToken
  }: RecordingListTableBodyProps ) {
  const classes = useStyles()
  const isSelected = (name: string | number ) => selected.indexOf(name.toString()) !== -1
  
  const [prevRowsAndToken, setPrevRowsAndToken] = React.useState<RowsAndToken|null>(null)
  const loadingElement = React.useRef<HTMLDivElement>(null)
  const offset = 0

  /**
   * When the object is initiated, rows = []
   * We need to refresh the event listener AFTER EVERY UPDATE to rows
   */
  useEffect(() => {
    if (page === matchpage) {
      console.log(`${matchpage} ${page}: adding scroll listener`)
      document.addEventListener('scroll', scrollWrapper, true)
      
      console.log(`REMOVING LISTENER -- ${prevRowsAndToken} ?`)
      if (prevRowsAndToken && rowsAndToken.nextToken !== prevRowsAndToken.nextToken) {
        console.log(`REMOVING LISTENER -- ${prevRowsAndToken.rows.length}`)
        document.removeEventListener('scroll', prevScrollWrapper, true)
        setPrevRowsAndToken(rowsAndToken)
      } else if (rowsAndToken && prevRowsAndToken === null) { 
        setPrevRowsAndToken(rowsAndToken)
        console.log("old removed?")
      }
    } else {
      console.log(`${matchpage} ${page}: removing scroll listener`)
      document.removeEventListener('scroll', () => scrollWrapper, true)
    }

    return () => document.removeEventListener('scroll', scrollWrapper, true)
  }, [rowsAndToken, prevRowsAndToken, page, matchpage])

  const isLoadingVisible = throttle(async (
    el: HTMLElement|null, nextToken: string|null, 
    rows: FlattenedData[], filter: FilterObj
  ) => {
    console.warn(`isLoadingVisible() :: ${nextToken} :: ${JSON.stringify(filter)}`)
    if (el) {
      let bounding = el.getBoundingClientRect()
      if (window.innerHeight/60 && bounding.bottom <= window.innerHeight) {
        console.log("BEFORE GET DATA")
        let temp = await getData(nextToken, rows, filter)
        console.log("GETTING DATA")
        console.log(temp)
        temp ? setRowsAndToken(temp) : console.log("isLoadingVisible() could not set RowsAndToken")
      } else {
        console.log("isLoadingVisible(): rows.length > 20 or bottom > innerHeight")
      }
    }
  }, 1000)

  const scrollWrapper = () => { 
    filter? onScroll(rowsAndToken.nextToken, rowsAndToken.rows, filter) : console.log("scrollWrapper(): filter is null, OK")
  }
  const prevScrollWrapper = () => { 
    filter ? onScroll(
      prevRowsAndToken?prevRowsAndToken.nextToken:null, 
      prevRowsAndToken?prevRowsAndToken.rows:[], 
      filter
    ) : console.log("scrollWrapper(): filter is null, OK")
  }
  const onScroll = throttle( async (
      nextToken: string|null, rows: FlattenedData[], filter: FilterObj
    ) => {
    console.log("ON SCROLL")
    if (!loadingElement.current) { return }
    const top = loadingElement.current.getBoundingClientRect().top;
    let visibility = top + offset >= 0 && top - offset <= window.innerHeight

    console.log(`onScroll(): ${rows.length}`)

    if (visibility && nextToken && rows) {
      let temp = await getData(nextToken, rows, filter)
      temp ? setRowsAndToken(temp) : console.log("onScroll() could not set RowsAndToken")
    }
  }, 1000)

  return (<TableBody>
    {stableSort(rowsAndToken.rows, getComparator(order, orderBy))
      .map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.id)}>
              <Checkbox
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </TableCell>
            <TableCell component="th" id={labelId} scope="row" padding="none" onClick={(event) => handleClick(event, row.id)}>
              {row.songTitle}
            </TableCell>
            {user.roles? user.roles.includes("ADMIN") ? 
              <TableCell align="right" onClick={(event) => handleClick(event, row.id)}>{row.singerName}</TableCell>:null:null
            }
            {user.roles? user.roles.includes("ADMIN") ? 
              <TableCell align="right" onClick={(event) => handleClick(event, row.id)}>{row.singerEmail}</TableCell>:null:null
            }
            {user.roles? user.roles.includes("AE") || user.roles.includes("AELEAD") ? 
              <TableCell align="right" onClick={(event) => handleClick(event, row.id)}>{row.id}</TableCell>:null:null
            }
            <TableCell align="right">
              {row.labellerEmail === ""? "not assigned" : 
              <Tooltip title={`${row.labellerEmail}`}>
                <a style={{textDecoration:"underline"}}
                  href={`mailto: ${row.labellerEmail}`}>{row.labellerUsername}
                </a>
              </Tooltip>
              }
            </TableCell>
            <TableCell align="right" onClick={(event) => handleClick(event, row.id)}>{new Date(row.createDate).toDateString()}</TableCell>
            <TableCell align="right" onClick={(event) => handleClick(event, row.id)}>{row.status}</TableCell>
            <TableCell align="right">
              <ButtonGroup color="primary" aria-label="outlined primary button group" style={{display: "flex", flexDirection: "row"}}>
                <Tooltip title="Assign a colleague">
                  <Button aria-label="assign-colleague" className={classes.margin}
                      onClick={() => { setAssignOpen(row.id) }}>
                    <AssignmentIndIcon fontSize="small" />
                  </Button>
                </Tooltip> 
                { row.labellerEmail !== user.email ?
                  <Tooltip title="Assign me!">
                    <Button aria-label="assign-me" className={classes.margin}
                      onClick={() => {
                        assignLabellerToRecording(row.id)
                      }}
                    >
                      <AssignmentTurnedInIcon fontSize="small" />
                    </Button>
                  </Tooltip>:
                  <Tooltip title="Unassign Me">
                    <Button aria-label="unassign-me" className={classes.margin}
                      onClick={() => {
                        unassignLabellerToRecording(row.id)
                      }}
                    >
                      <RemoveCircleOutlineIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                }
                <Tooltip title="Work on it now!">
                  <Button aria-label="work-now" className={classes.margin}
                      onClick={() => { goToLabelStudio(row.id) }}>
                    <InputIcon fontSize="small" />
                  </Button>
                </Tooltip> 
              </ButtonGroup>
            </TableCell>
          </TableRow>
        );
      })}
      <TableRow style={{display: rowsAndToken.nextToken?"":"none"}}>
        <TableCell colSpan={8} style={{textAlign:"center", alignContent: "center", height: "60px"}}>
            <CircularProgress id={_LOADING_CIRCLE_ID} color="inherit" size={20} ref={loadingElement} style={{margin: "0 auto"}}/>
        </TableCell>
      </TableRow>
  </TableBody>)
}