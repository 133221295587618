import React, { useState } from 'react'
import { 
  Avatar, Button, CircularProgress, TextField, 
  FormControlLabel, Checkbox, Typography 
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    display: "block",
    color: "#f44336",
    transition: "opacity 0.3s ease-in-out"
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -12,
  },
}));

async function signIn(username: string, password: string) {
  try {
    console.log(username)
    console.log(password)
    let user = await Auth.signIn(username, password)
    console.log(user)
    return user
  } catch (error) {
    console.log('error signing in', error)
    return { error: error }
  }
}

async function changePassword(currentUser: string, newPass1: string, newPass2: string) {
  if (newPass1 === newPass2) {
    try {
      return await Auth.completeNewPassword(
        currentUser,
        newPass1
      )
    } catch (error) {
      console.log('error in change password', error)
      return {error: error}
    }
  } else {
    return  {error: { message: "miss-match passwords."}}
  }
}

export interface LoginProps {
  login: () => void
  
  email: string
  setEmail: (email: string) => void
}

const ERROR_WAIT_TIME = 15000
export default function Login({ login, email, setEmail }: LoginProps) {
  const classes = useStyles()

  const [pass, setPass] = useState('')
  const [stage, setStage] = useState('') // NEW_PASSWORD_REQUIRED

  const [loginErrorMsg, setLoginErrorMsg] = useState('1')
  const [showLoginErr, setShowLoginErr] = useState(false)

  const [newPass1, setNewPass1] = useState('')
  const [newPass2, setNewPass2] = useState('')

  const [user, setUser] = useState<string|null>(null)
  const [loading, setLoading] = React.useState(false)

  return (
    <div className={classes.paper} style={{paddingTop:"50px"}}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        { stage === 'NEW_PASSWORD_REQUIRED' ?
        <form className={classes.form} noValidate>
          <TextField disabled className="admin-input"
            value={pass} variant="outlined"
            margin="normal" required fullWidth name="temp_password" label="Temp Password" type="password"
            id="temp_password"
          />

          <TextField className="admin-input"
            value={newPass1} onChange={(e) => setNewPass1(e.target.value)} variant="outlined"
            margin="normal" required fullWidth name="new_password" label="New Password" type="password"
            id="new_password" autoComplete="current-password"
          />
          <TextField className="admin-input"
            value={newPass2} onChange={(e) => setNewPass2(e.target.value)} variant="outlined"
            margin="normal" required fullWidth name="retype_password" label="Retype Password" type="password"
            id="retype_password" autoComplete="current-password"
          />
          <Typography className={classes.error}
            variant="caption" style={{opacity:showLoginErr?1:0}}>
            {loginErrorMsg}
          </Typography>
          <div className={classes.wrapper}>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}
              onClick={(event) => {
                  event.preventDefault()
                  setLoading(true)
                  console.log("change password clicked!")
                  if (user) changePassword(user, newPass1, newPass2)
                    .then( data => {
                      if (data) {
                        if (data.error) {
                          console.log(data.error)
                          setLoginErrorMsg(data.error.message)
                          setShowLoginErr(true)
                          setLoading(false)
                          setTimeout(() => {
                            setShowLoginErr(false)
                          }, ERROR_WAIT_TIME)
                        } else {
                          let user = data
                          console.log(user)
                          login()
                          setLoading(false)
                        }
                      } else {
                        let user = data
                        console.log(user)
                        login()
                        setLoading(false)
                      }
                    })
                  else {console.warn("User is null!"); setLoading(false)}
                }
              }
            >Change Password</Button>
          </div>
        </form>:
        <form className={classes.form} noValidate>
          <TextField className="admin-input"
            value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined"
            margin="normal" required fullWidth id="email" label="Email or Username" name="email"
            autoComplete="email" autoFocus
          />
          <TextField className="admin-input"
            value={pass} onChange={(e) => setPass(e.target.value)} variant="outlined"
            margin="normal" required fullWidth name="password" label="Password" type="password"
            id="password" autoComplete="current-password"
          />
          <Typography className={classes.error}
            variant="caption" style={{opacity:showLoginErr?1:0}}>
            {loginErrorMsg}
          </Typography>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <div className={classes.wrapper}>
            <Button type="submit" fullWidth variant="contained" 
              color="primary" className={classes.submit} disabled={loading}
              onClick={(event) => {
                  event.preventDefault()
                  setLoading(true)
                  signIn(email, pass).then( data => {
                    if (data.error) {
                      console.log(data.error)
                      setLoginErrorMsg(data.error.message)
                      setShowLoginErr(true)
                      setTimeout(() => {
                        setShowLoginErr(false)
                      }, ERROR_WAIT_TIME)
                      setLoading(false)
                    } else {
                      localStorage.setItem("session", data.Session)
                      setUser(data)
                      setStage(data.challengeName)

                      if (data.challengeName !== "NEW_PASSWORD_REQUIRED") login()
                      else { console.log(`${data.challengeName} + ${stage}`) }
                    }
                  })
                }
              }
            >Sign In</Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </form>}
      </div>
  )
}