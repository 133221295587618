import React, { ChangeEvent, useEffect, useRef } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { 
  Button, TextField, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle
} from '@material-ui/core'

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import config from '../../../config.json'


const useStyles = makeStyles((theme: Theme) => ({
  num: {
    width: 50,
    marginLeft: 5,
    marginRight: 5
  },
  dashMiddle: {
    padding: 10,
    paddingTop: 35,
    fontSize: 30
  }
}));

export interface SignupVerificationCodeProps {
  open: boolean,
  email: string,
  submitCode: (code: string) => Promise<boolean>,
  isRegistration: boolean
}

export default function SignupVerificationCode({ 
  open, submitCode, email, isRegistration }: SignupVerificationCodeProps ) {
  const classes = useStyles()

  const [values, setValues] = React.useState(["","","","","",""])
  const [canSubmit, setCanSubmit] = React.useState(false)
  const refs = [
    useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)
  ]

  useEffect(() => {
    if (open) {
      // Focus on the first input
      setTimeout(() => {
        let newFocus = refs[0].current
        if (newFocus) {
          newFocus.children[0].classList.add("Mui-focused");
          (newFocus.children[0].children[0] as HTMLInputElement).focus()
        } else console.log("CANNOT FOCUS")
      }, 500)

      // document.addEventListener('keydown', backPress)
      // return () => {document.removeEventListener('keydown', backPress)}
    } else {
      // document.removeEventListener('keydown', backPress)
    }
  }, [open])

  // const backPress = (event: KeyboardEvent): any => {
  //   if (event.code === 'Backspace') {
  //     event.preventDefault()

  //     if (index+1 < 6) {
  //       console.log(refs[index+1].current)
  
  //       let newFocus = refs[index+1].current
  //       if (newFocus) {
  //         newFocus.children[0].classList.add("Mui-focused");
  //         (newFocus.children[0].children[0] as HTMLInputElement).focus()
  //       }
  //     }
  //   }
  // }

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    values[index] = e.target.value
    setValues([...values])
    
    if (e.target.value === "") {
      // We believe you've just removed a number / hit backspace
      if (index-1 >= 0) {
        console.log(refs[index-1].current)
        let newFocus = refs[index-1].current
        if (newFocus) {
          newFocus.children[0].classList.add("Mui-focused");
          (newFocus.children[0].children[0] as HTMLInputElement).focus()
        }
      }
    } else if (e.target.value.length > 1) {
      // We belive you pasted OR try to enter more digits into a single cell
      console.log("MORE THAN 1 CHAR IN A SINGLE CELL.")
      let max = Math.min(index + e.target.value.length, 6)
      for (let i=index; i < max; i++) {
        values[i] = e.target.value[i-index]
      }
      setValues([...values])
      
      let newFocus = refs[max-1].current
        if (newFocus) {
          newFocus.children[0].classList.add("Mui-focused");
          (newFocus.children[0].children[0] as HTMLInputElement).focus()
        }
    } else {
      if (index+1 < 6) {
        console.log(refs[index+1].current)
  
        let newFocus = refs[index+1].current
        if (newFocus) {
          newFocus.children[0].classList.add("Mui-focused");
          (newFocus.children[0].children[0] as HTMLInputElement).focus()
        }
      }
    }

    let mightSubmit = true
    console.log(values)
    for (let value of values) { if (value === "") { mightSubmit = false; break; } }
    setCanSubmit(mightSubmit)
  }

  const submitCodeLocal = () => {
    let code = values.join('')
    submitCode(code)
  }

  const resendCode = (username: string): Promise<boolean> => {
    return new Promise( async resolve => {
      var poolData = {
        UserPoolId: config.cognito.USER_POOL_ID, // Your user pool id here
        ClientId: config.cognito.APP_CLIENT_ID, // Your client id here
      }
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

      let userData = {
        Username: username,
        Pool: userPool
      }
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

      cognitoUser.resendConfirmationCode(function(err, result) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        console.log('call result: ' + result);
      })
    })
  }

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth='xs'>
        <DialogTitle id="form-dialog-title">Verification Code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Check your <b>email</b> for a 6-digit verification code from admin@oslyn.io.
            Be sure to check your spam folder!
          </DialogContentText>
          {values.map((v, index) => {
            return (
              <span key={index}>
                {index === 3 ? <span className={classes.dashMiddle}>-</span>: null}
                <TextField variant="outlined" id={`num${index}`} 
                  className={classes.num} value={v} ref={refs[index]}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, index)} 
                />
              </span>
            )
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => resendCode(email)} color="secondary" disabled={!isRegistration}>
            Resend Code
          </Button>
          <Button onClick={submitCodeLocal} disabled={!canSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}