import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, 
      DialogContentText, DialogTitle } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: "80px"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

export interface RecordingDataProps {
  data: FlattenedData
  open: boolean
  handleClose: () => void
}

export default function RecordingData({ data, open, handleClose }: RecordingDataProps) {
  const classes = useStyles()

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Recording Info
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b style={{fontWeight:700}}>Title</b>: {data.songTitle}<br />
            <b style={{fontWeight:700}}>Singer</b>: {data.singerName}<br />
            <b style={{fontWeight:700}}>Email</b>: {data.singerEmail}<br /><br />
            <b style={{fontWeight:700}}>Tabs</b>: {data.tabLinkUsed}<br />
            <b style={{fontWeight:700}}>Transposition</b>: {data.transpositionFromTab}<br />
            <b style={{fontWeight:700}}>Key</b>: {data.key}<br /><br />
            <b style={{fontWeight:700}}>Assigned AE</b>: {data.labellerEmail}<br />
            <b style={{fontWeight:700}}>Status</b>: {data.status}<br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}




/**
 * 
 * 
 * 
 */