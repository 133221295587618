import React, { useState, useEffect } from 'react'
import { 
  CssBaseline, Link, Grid, Box, Typography, Container, Snackbar 
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import queryString from 'query-string'

import Login from './components/Login'
import Signup from './components/Signup'
import ForgotPassword from './components/ForgotPassword'


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://oslyn.io">
        Oslyn.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  customLink: {
    backgroundColor: "initial",
    outline: "none",
    cursor: "pointer",
    transition: "color .3s"
  }
}));

export interface LoginOrSignupProps {
  login: () => void
}

export default function LoginOrSignup({ login }: LoginOrSignupProps) {
  const classes = useStyles()

  // Signin, Signup, Forgotpass
  const [onPage, setOnPage] = useState("Signin")
  const [email, setEmail] = useState("")
  
  const [openSuccessReset, setOpenSuccessReset] = useState(false)

  useEffect(() => {
    const params = queryString.parse(window.location.search)
    console.log(window.location.search)
    console.log(params)
  }, [])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      { onPage === "Signup" ? <Signup email={email} setEmail={setEmail} login={login} /> : null }
      { onPage === "Forgotpass" ? <ForgotPassword email={email} setEmail={setEmail} 
      goToSignin={() => {
        setOpenSuccessReset(true)
        setOnPage("Signin")
      }} /> : null }
      { onPage === "Signin" ? <Login login={login} email={email} setEmail={setEmail} /> : null }
      <Grid container>
        <Grid item xs>
          <Link variant="body2" onClick={() => { 
            if (onPage === "Forgotpass") setOnPage("Signin")
            else setOnPage("Forgotpass")
          }}>
            {onPage === "Forgotpass"? "Sign In":"Forgot password?"}
          </Link>
        </Grid>
        <Grid item>
          <Link variant="body2" onClick={() => {
            if (onPage === "Signup") setOnPage("Signin")
            else setOnPage("Signup")
          }}>
            {onPage === "Signup" ? "Already have an account? Sign in" : "Don't have an account? Sign Up"}
          </Link>
        </Grid>
      </Grid>
      <Box mt={8}>
        <Copyright />
      </Box>
      <Snackbar open={openSuccessReset} autoHideDuration={6000} onClose={() => { setOpenSuccessReset(false) }}>
        <Alert severity="success">
          Password Successfully reset!
        </Alert>
      </Snackbar>
    </Container>
  );
}