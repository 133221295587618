import React, { useEffect } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout'
import { makeStyles } from '@material-ui/core/styles'
import { 
  Button, Card, CardActions, CardContent, Grid,
  Typography
} from '@material-ui/core'

import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"

import 'react-resizable/css/styles.css';
import './styles/app.css';

// Hardcoded visualization examples
// import Heatmap from './Visualizations/Heatmap';
// import Bar from './Visualizations/Bar';
// import Radar from './Visualizations/Radar';
// import Line from './Visualizations/Line';

import RecordingHistory from './Visualizations/RecordingHistory'
import RecordingHistory2 from './Visualizations/RecordingHistory2'

// TO BE DELETED ONCE VISUALIZATION EXAMPLES DELETED
const ResponsiveGridLayout = WidthProvider(Responsive);
const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 320 };
const cols = { lg: 4, md: 4, sm: 1, xs: 1, xxs: 1 };

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}))

const _SONGLIMIT = 20000
const _RECORDINGLIMIT = 200000

export interface Dashboard extends React.HTMLAttributes<HTMLDivElement & React.Component> {
  page: string
  initialWidth: string
  goToSongs: () => void
  goToRecordings: () => void
 }

function Dashboard({ initialWidth, page, goToSongs, goToRecordings }: Dashboard) {
  const classes = useStyles()
  const [totalSongs, setTotalSongs] = React.useState(0)
  const [totalRecordings, setTotalRecordings] = React.useState(0)
  const [completedRecordings, setCompletedRecordings] = React.useState(0)

  const getTotalSongCount = async () => {
    let query = `query listSongs {
      listSongs( limit: ${_SONGLIMIT} ) {
        items { id }
      }
    }`
    let data
    try {
      data = (await API.graphql(graphqlOperation(query))) as GraphQLResult<{listSongs: {items: {id: string}[]}}>
    } catch (e) { console.log(e) }

    let items = data ? data.data ? data.data.listSongs.items : [] : []
    if (items) setTotalSongs(items.length)
  }

  const getTotalRecordingsCount = async () => {
    let query = `query listRecordings {
      listRecordings( limit: ${_RECORDINGLIMIT} ) {
        items { id }
      }
    }`
    let data
    try {
      data = (await API.graphql(graphqlOperation(query))) as GraphQLResult<{listRecordings: {items: {id: string}[]}}>
    } catch (e) { console.log(e) }

    let items = data ? data.data ? data.data.listRecordings.items : [] : []
    if (items) setTotalRecordings(items.length)
  }

  const getCompletedRecordingsCount = async () => {
    let query = `query listRecordings {
      listRecordings( limit: ${_RECORDINGLIMIT} 
        filter: { status: { eq: COMPLETE } } ) {
        items { id }
      }
    }`
    let data
    try {
      data = (await API.graphql(graphqlOperation(query))) as GraphQLResult<{listRecordings: {items: {id: string}[]}}>
    } catch (e) { console.log(e) }

    let items = data ? data.data ? data.data.listRecordings.items : [] : []
    if (items) setCompletedRecordings(items.length)
  }

  useEffect(() => {
    if (page === "Home") {
      getTotalSongCount()
      getTotalRecordingsCount()
      getCompletedRecordingsCount()
    }
  }, [page])

  //"Validation error of type WrongType: argument 'filter.status.eq' with value 'StringValue{value='COMPLETED'}' is not a valid 'recordingStatus' @ 'listRecordings'"

  return (
    <div className="App">
      <Grid container spacing={4} style={{ marginTop: 60, padding: "20px"}}>
        <Grid item key={1} xs={12} sm={6} md={4} lg={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography style={{color:"grey"}}>
                Total Songs
              </Typography>
              <Typography gutterBottom variant="h2" component="h2"
                style={{color: totalSongs > 200 ? "green": "GoldenRod"}}
              >{totalSongs}</Typography>
              <Typography variant="caption" display="block">
                * Number of <b style={{fontWeight: 600, color: "black"}}>chord sheets</b> on the platform.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="secondary" style={{color: "grey"}}>View</Button>
              <Button size="small" color="primary"
                onClick={() => { goToSongs() }}
              >Work on this</Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item key={2} xs={12} sm={6} md={4} lg={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography style={{color:"grey"}}>
                Total Recordings (not completed)
              </Typography>
              <Typography gutterBottom variant="h2" component="h2"
                style={{color: totalRecordings < 400? "DarkRed": (totalRecordings > 3000 ? "green" : "GoldenRod")}}
              >{totalRecordings}</Typography>
              <Typography variant="caption" display="block">
                * Number of <b style={{fontWeight: 600, color: "black"}}>labelled / unlabelled</b> recordings on the platform.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="secondary" style={{color: "grey"}}>View</Button>
              <Button size="small" color="primary"
                onClick={() => { goToRecordings() }}
              >Work on this</Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item key={3} xs={12} sm={6} md={4} lg={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography style={{color:"grey"}}>
                Completed Recordings
              </Typography>
              <Typography gutterBottom variant="h2" component="h2"
                style={{color: completedRecordings < 50? "DarkRed": (completedRecordings > 300 ? "green" : "GoldenRod")}}
              >{completedRecordings}</Typography>
              <Typography variant="caption" display="block">
                * Indicates the number of recordings ready for <b style={{fontWeight: 600, color: "black"}}>AI Training</b>
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="secondary" style={{color: "grey"}}>View</Button>
              <Button size="small" color="primary">Work on this</Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item key={4} xs={12} sm={6} md={4} lg={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography style={{color:"grey"}}>
                Sound Libraries released to Oslyn!
              </Typography>
              <Typography gutterBottom variant="h2" component="h2"
                style={{color: "green"}}
              > 5 </Typography>
              <Typography variant="caption" display="block">
                * As of now for the month of <b style={{fontWeight: 600, color: "black"}}>April</b>
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary"
                onClick={() => {window.open('https://oslyn.io/admin', "_blank")}}
              >Oslyn</Button>
            </CardActions>
          </Card>
        </Grid>

      </Grid>
      <Grid container style={{marginTop: 0, marginBottom: 60, marginLeft: 10}}>
        <Grid item xs={12}>
          <RecordingHistory2 page={page} />
        </Grid>
      </Grid>
      {/*<Grid container style={{marginTop: 0, marginBottom: 60, marginLeft: 10}}>
        <Grid item xs={12}>
          <RecordingHistory />
        </Grid>
      </Grid>*/}
      {/** 
      <div className="w-full">
        <header className="flex bg-gray-900 m-5 p-5 shadow-lg rounded-lg" style={{marginLeft: 40}}>
          <h1 className="text-2xl text-teal-400">DEV Examples:</h1>
        </header>
        <ResponsiveGridLayout
          className="my-5 mx-8"
          breakpoints={breakpoints}
          cols={cols}
        >
          <div
            className="grid-cell"
            key="1"
            data-grid={{ x: 0, y: 0, w: 2, h: 3, static: true }}
          >
            <h3 className="grid-header">Daily Community Engagement</h3>
            <Heatmap />
          </div>

          <div
            className="grid-cell"
            key="2"
            data-grid={{ x: 2, y: 0, w: 2, h: 3 }}
          >
            <h3 className="grid-header">Members' Favorite Food</h3>
            <Bar />
          </div>
          <div
            className="grid-cell"
            key="3"
            data-grid={{ x: 0, y: 3, w: 1, h: 2 }}
          >
            <h3 className="grid-header">Members' Favorite Drinks</h3>
            <Radar />
          </div>
          <div
            className="grid-cell"
            key="4"
            data-grid={{ x: 2, y: 3, w: 3, h: 2 }}
          >
            <h3 className="grid-header">Members' Favorite Transportion</h3>
            <Line />
          </div>
        </ResponsiveGridLayout>
      </div>*/}
    </div>
  );
}

export default Dashboard;