import React, { useEffect, useState } from 'react'

import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles'
import { API, graphqlOperation, loadingSceneName } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"
import AWS from 'aws-sdk'

import 'label-studio/build/static/css/main.css'
import "label-studio/build/static/js/main"
import { 
  LABELSTUDIO_KEY, LABELSTUDIO_VERSION, 
  OSLYNCSV_KEY, OSLYNCSV_VERSION, labelStudioInputToCSV,
  LStudioConfig, LStudioOutputToFileConverter,
  checkConfigEqual
} from './LStudioUtils'

import RecordingDataFAB from './RecordingDataFAB'
import env, { s3 } from '../../env'

import getBlobDuration from 'get-blob-duration'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  studioStyle: {
    padding: theme.spacing(3)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

interface Msg {
  status: "error"|"warning"|"info"|"success"
  msg: string
}

export interface LStudioProps {
  id: string,
  user: Labeller,
  page: string,
  goToRecordings: () => void
}

export default function LStudio({ id, user, goToRecordings, page }: LStudioProps) {
  const classes = useStyles();
  
  const [loading, setLoading] = useState(true)
  //const [user, setUser] = useState<any>(null)
  const [data, setData] = useState({} as FlattenedData)

  const [s3AudioString, setS3AudioString] = useState<string|null>(null)
  const [lStudioConfig, setLStudioConfig] = useState<LStudioInputProps|null>(null)

  const [msg, setMsg] = useState<Msg|null>(null)
  const [msgOpen, setMsgOpen] = useState(false)

  interface GetRecording {
    getRecording: Data|null
  }

  const getRecordData = async (id: string): Promise<FlattenedData|null> => {
    return new Promise<FlattenedData|null> ( async resolve => {
      let query = `query getSingleRecord {
        getRecording(
          id: "${id}"
        ) { 
          id songTitle formId key tabLinkUsed
          rawTranspositionFromTab transpositionFromTab fileExtension
          prelabelTool prelabelToolVersion
          labelTool labelToolVersion comment
          labeller { id email oslynTeacherEmail }
          isLabelerRejected labelerRejectionReason
          singerName singerEmail gender status
          createDate updateDate lastOULGenerateDate
        }
      }`
  
      try {
        let data = ( await API.graphql(graphqlOperation(query))) as GraphQLResult<GetRecording>
        let item = data ? data.data ? data.data.getRecording : null : null
        if (item) {
          resolve({
            id: item.id,
            songTitle: item.songTitle,
            formId: item.formId,
            key: item.key,
            tabLinkUsed: item.tabLinkUsed,
            rawTranspositionFromTab: item.rawTranspositionFromTab, 
            transpositionFromTab: item.transpositionFromTab,
            fileExtension: item.fileExtension,
    
            labellerEmail: item.labeller? item.labeller.email : "not assigned",
            isLabelerRejected: item.isLabelerRejected,
            
            singerEmail: item.singerEmail,
            singerName: item.singerName,
            
            status: item.status,
            comment: item.comment,
    
            createDate: Date.parse(item.createDate),
            updateDate: Date.parse(item.updateDate),
            lastOULGenerateDate: Date.parse(item.lastOULGenerateDate)
          } as FlattenedData)
        } else resolve(null)
      } catch(e) { resolve(null) }
      
    })
  }

  const getAudioFromS3 = (id: string, fileExtension: string): Promise<string|null> => {
    return  new Promise<string|null>(async resolve => {
      const s3 = new AWS.S3({
        accessKeyId: env.s3.accessKeyId,
        secretAccessKey: env.s3.secretAccessKey
      })
  
      s3.getObject({
        Bucket: env.s3PiplineBucket,
        Key: `public/${id}/raw/recording.${fileExtension}`
      }, async (err, data: any) => {
        if (err) {
          setMsg({status: "error", msg: "Could not get the audio (recording.wav) from S3"})
          console.error(err)
          resolve(null)
        } else {
          console.log(data)

          var byteArray = new Uint8Array(data.Body);
          var blob = new Blob([byteArray], { type: data.ContentType });
          let soundString = URL.createObjectURL(blob)

          let duration = await getBlobDuration(soundString)
          console.log(`duration: ${duration}`)

          resolve(soundString)
        }
      })
    })
  }

  const saveLabelStudioConfig = (id: string, data: LStudioInputProps): Promise<boolean> => {
    return new Promise<boolean>(async resolve => {
      const s3 = new AWS.S3({
        accessKeyId: env.s3.accessKeyId,
        secretAccessKey: env.s3.secretAccessKey
      })

      s3.putObject({
        Bucket: env.s3PiplineBucket,
        Key: `public/${id}/${LABELSTUDIO_KEY}/${LABELSTUDIO_KEY}_v${LABELSTUDIO_VERSION}.json`,
        Body: JSON.stringify(data)
      }, function (err, res) {
        if (err) {
          setMsg({status: "error", msg: "Could not save labels to S3."})
          console.error(err)
          resolve(false)
        } else {
          setMsg({status: "success", msg: "Saved!"})
          console.log(res)
          resolve(true)
        }
      })
    })
  }

  const getLabelStudioConfig = (id: string, audioUrl: string):Promise<LStudioInputProps> => {
    return new Promise<LStudioInputProps>(async (resolve) => {
      const s3 = new AWS.S3({
        accessKeyId: env.s3.accessKeyId,
        secretAccessKey: env.s3.secretAccessKey
      })

      s3.getObject({
        Bucket: env.s3PiplineBucket,
        Key: `public/${id}/${LABELSTUDIO_KEY}/${LABELSTUDIO_KEY}_v${LABELSTUDIO_VERSION}.json`
      }, (err, data: any) => {
        if (err) {
          setMsg({status: "error", msg: "Could not get labels from S3."})
          console.log(err, err.toString().includes("NoSuchKey"))
          resolve({ data: { url: audioUrl} } as LStudioInputProps)
        } else {
          console.log(data)
          var fromS3 = JSON.parse(data.Body)
          fromS3.data = { url: audioUrl}
          console.log(fromS3)
          resolve(fromS3 as LStudioInputProps)
        }
      })
    })
  }

  const postMarkComplete = (user: Labeller, song: FlattenedData): Promise<boolean> => {
    return new Promise (async resolve => {
      let params = {
        body: {
          id: song.id, userId: user.id, 
          currentStatus: song.status
        }
      }
      let data = await API.post('oslynstudiov1', '/studio/recording/mark-complete', params) as any
      if (data.error) { console.log(data.error); resolve(false) }
      else { resolve(true) }
    })
  }

  const saveOslynAICSV = (id: string, config: LStudioInputProps): Promise<boolean> => {
    return new Promise ( async resolve => {
      const s3 = new AWS.S3({
        accessKeyId: env.s3.accessKeyId,
        secretAccessKey: env.s3.secretAccessKey
      })

      let csv = labelStudioInputToCSV(config)
      console.log(csv)

      s3.putObject({
        Bucket: env.s3PiplineBucket,
        Key: `public/${id}/${OSLYNCSV_KEY}-v${OSLYNCSV_VERSION}.csv`,
        Body: csv
      }, function (err, res) {
        if (err) {
          setMsg({status: "error", msg: "Could not save AI CSV to S3."})
          console.warn(err)
          resolve(false)
        } else {
          setMsg({status: "error", msg: "Could not save AI CSV to S3."})
          console.log(res)
          resolve(true)
        }
      })

    })
  }

  const setLabelStudio = (config: LStudioInputProps, data: FlattenedData) => {
    setLoading(false)
    new (window as any).LabelStudio('label-studio', {
      config: LStudioConfig(data.songTitle,
        data.key, data.tabLinkUsed,
        "Listen to the audio:"),
  
      interfaces: [ "update", "controls", "side-column",
        // "completions:menu",
        // "completions:add-new",
        // "completions:delete",
        // "predictions:menu",
        // "panel", 
      ],
      user: {
        pk: 1,
        firstName: user ? user.username: "NA",
        lastName: ""
      },
      task: config,
      onLabelStudioLoad: function(LS: any) {
        var c = LS.completionStore.addCompletion({
          userGenerate: true
        });
        LS.completionStore.selectCompletion(c.id);
      },

      onSubmitCompletion: async (ls: any, completion: any) => {
        console.log("Press Submit")
        // we only save input on S3
        let data = JSON.parse(JSON.stringify(completion))
        let toSave = LStudioOutputToFileConverter(data)
        if (await saveLabelStudioConfig(id, toSave)) {
          setLStudioConfig(toSave)
        } else { setMsg({status: "error", msg: "Could not save LabelStudioConfig to backend"}) }
      },
      
      onUpdateCompletion: async (ls: any, completion: any) => {
        console.log("Press Update")
        let data = JSON.parse(JSON.stringify(completion))
        let toSave = LStudioOutputToFileConverter(data)
        if (await saveLabelStudioConfig(id, toSave)) {
          setLStudioConfig(toSave)
        } else { setMsg({status: "error", msg: "Could not save LabelStudioConfig to backend"}) }
      }
    })
  }

  const loadLStudio = ():Promise<{
    data: FlattenedData,
    s3AudioString: string,
    config: LStudioInputProps
  }|null> => {
    return new Promise ( async resolve => {
      if (user) {
        let record = await getRecordData(id)
        if (record) {
          let s3AudioString = await getAudioFromS3(id, record.fileExtension)
          if (s3AudioString) {
            let config = await getLabelStudioConfig(id, s3AudioString)
            setData(record)
            setS3AudioString(s3AudioString)
            setLStudioConfig(config)
  
            await setLabelStudio(config, record)
            console.log("ALL DATA SET IN LSTUDIO")
            resolve({ data: record, s3AudioString, config })

          } else setMsg({status: "error", msg: "Could not get audio file from S3."})
        } else setMsg({status: "error", msg: "Could not get recording data."})
      } else setMsg({status: "error", msg: "Could not get user."})
      resolve(null)
    })
  }

  const markComplete = async (data: FlattenedData, s3AudioString: string|null, lStudioConfig: LStudioInputProps|null) => {

    console.log(user)
    console.log(data)
    console.log(s3AudioString)
    console.log(lStudioConfig)

    if (user) {
      if (data && s3AudioString && lStudioConfig) {
        let config = await getLabelStudioConfig(data.id, s3AudioString)
        if (checkConfigEqual(config, lStudioConfig)) {
          if (postMarkComplete(user, data)) {
            if (saveOslynAICSV(data.id, config)) {
              goToRecordings()
            } else setMsg({status: "error", msg: "Could not store oslyn AI CSV to S3."})
          } else setMsg({status: "error", msg: "Could not post complete status to servers."})
        } else setMsg({status: "error", msg: "Could not get the latest config file or config != lStudioConfig."})
      } else setMsg({status: "error", msg: "Missing data or s3AudioString or lStudioConfig."})
    } else setMsg({status: "error", msg: "Could not load user info."})
  }

  useEffect(() => {
    if (id && page === "Recording"){
      setLoading(true)
      loadLStudio()
    }
  }, [id, page])

  useEffect(() => {
    if (msg && page === "Recording")
      setMsgOpen(true)
  }, [msg])

  return (
    <div className="App" style={{paddingTop: "50px"}}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop> 
      <div style={{minHeight: "200px"}} className={classes.studioStyle} id="label-studio" />
      <RecordingDataFAB data={data} soundBlob={s3AudioString} 
        saveLabelStudioConfig={async ( lstudioInput ) => {
          return new Promise<boolean>( async (resolve) => {
            let returnBool = await saveLabelStudioConfig(data.id, lstudioInput)
            if (returnBool) {
              setLStudioConfig(lstudioInput)
              await loadLStudio()
              resolve(true)
            } else resolve(false)
          })
        }}
        markComplete={() => { markComplete(data, s3AudioString, lStudioConfig)}}/>
      <Snackbar open={msgOpen} autoHideDuration={6000} onClose={
        (event?: React.SyntheticEvent, reason?: string) => {
          reason === "clickaway"? console.log("click away") : setMsgOpen(false) 
        }
      } anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
        <Alert onClose={() => { setMsgOpen(false) }}
          severity={msg? msg.status : "error"}>{msg? msg.msg : "Unknwon Error."}</Alert>
      </Snackbar>
    </div>
  )
}
