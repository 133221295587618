import React, { useState } from 'react'
import { Avatar, Button, CircularProgress, TextField, FormControlLabel, 
  Checkbox, Link, Grid, Box, Typography, Container, Divider } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"
import { Auth } from 'aws-amplify'

import 'cross-fetch/polyfill'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import config from '../../../config.json'
import SignupVerificationCode from './SignupVerificationCode'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    display: "block",
    color: "#f44336",
    transition: "opacity 0.3s ease-in-out"
  },
  textField: {

  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -12,
  },
}));

interface listLabellers {
  listLabellers: {
    items: Labeller[]
  }
}

export interface SignupProps {  
  email: string
  login: () => void
  setEmail: (email: string) => void
}

const ERROR_WAIT_TIME = 15000
export default function Signup({ email, login, setEmail }: SignupProps) {
  const classes = useStyles()

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [recieveUpdatePref, setRecieveUpdatePref] = React.useState(true)

  const [newPass1, setNewPass1] = useState('')
  const [newPass2, setNewPass2] = useState('')

  const [loginErrorMsg, setLoginErrorMsg] = useState('1')
  const [showLoginErr, setShowLoginErr] = useState(false)

  const [username, setUsername] = React.useState('')
  const [openSignup, setOpenSignup] = React.useState(false)

  const [newUserId, setNewUserId] = React.useState('')

  const [loading, setLoading] = React.useState(false)

  const showError = (msg: string) => {
    setLoginErrorMsg(msg)
    console.warn(msg)
    setShowLoginErr(true)
    setTimeout(() => {
      setShowLoginErr(false)
    }, ERROR_WAIT_TIME)
  }

  const getAvailUsername = (firstname: string, lastname: string, email: string): Promise<string|null> => {
    return new Promise(async (resolve) => {
      let query = `query listUser {
        listLabellers(filter: {
            email: { eq: "${email}" }
          },
          limit: 10
        ) { items { 
            id username email roles oslynTeacherEmail 
            firstName lastName recieveUpdatesFromOslyn
            isActivated createDate
          }
        }
      }`

      let data
      try {
        data = ( await API.graphql(graphqlOperation(query))) as GraphQLResult<listLabellers>
      } catch (e) { console.log(e) }

      let users = data ? data.data ? data.data.listLabellers.items : null : null
      if (users) {
        for (let user of users) { // if this list exist, and first user is not V1 user, resolve to null (fail)
          if (!user.firstname && !user.lastname && user.recieveUpdatesFromOslyn === null) {
            // THIS IS A V1 login -- we will allow
            if (user.username === firstname.toLowerCase()+lastname.toLowerCase() && email === user.email) { 
              resolve( firstname.toLowerCase()+lastname.toLowerCase() )
            } else {
              showError("While this was detected as a V1 login, email/username doesn't match.")
              resolve( null )
            }
            break
          } else {
            showError("This email already exists! Did you forget your password?")
            resolve( null )
          }
        }
      }

      let query2 = `query listUser {
        listLabellers(filter: {
            username: { contains: "${firstname.toLowerCase()+lastname.toLowerCase()}" }
          },
          limit: 10
        ) { items { 
            id username email roles oslynTeacherEmail 
            firstName lastName recieveUpdatesFromOslyn
            isActivated createDate
          }
        }
      }`
      try {
        data = ( await API.graphql(graphqlOperation(query2))) as GraphQLResult<listLabellers>
      } catch (e) { console.log(e) }

      let users2 = data ? data.data ? data.data.listLabellers.items : null : null
      console.log(users2)

      let baseUserName = firstname.toLowerCase()+lastname.toLowerCase()
      let currentIncr = 0
      let unavailableUsernames = []
      
      if (users2) {
        for (let user2 of users2) { unavailableUsernames.push(user2.username) }
      }

      let currentUserName = baseUserName
      while (unavailableUsernames.includes(currentUserName)) {
        currentIncr = currentIncr + 1
        currentUserName = baseUserName+currentIncr
      }

      resolve(currentUserName)
    })
  }

  async function signUp (
    firstname: string, lastname: string, 
    username: string, password: string, 
    email: string, recieveUpdatePref: boolean
  ): Promise<boolean> {
    return new Promise( async (resolve) => {
      var poolData = {
        UserPoolId: config.cognito.USER_POOL_ID, // Your user pool id here
        ClientId: config.cognito.APP_CLIENT_ID, // Your client id here
      }
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
      var attributeList = [];
      var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'email',
        Value: email,
      })
      var attributeName = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'name',
        Value: firstname+' '+lastname
      })
      
      attributeList.push(attributeEmail);
      attributeList.push(attributeName);
      userPool.signUp(
        username, password, attributeList, [], 
        async function(err, result
      ) {
        if (err) {
          if (err.message) { showError(err.message) }
          resolve( false )
        }
        var cognitoUser = result?.user;
        console.log('user name is ' + cognitoUser?.getUsername());
    
        if (cognitoUser) { 
          console.log("User created in cognito -- posting to DB")
          let outcomeBool = await postSignupUnconfirmed(firstname, lastname, username, email, recieveUpdatePref)
          resolve( outcomeBool )
        }
      })
    })
  }

  const postSignupUnconfirmed = async (
    firstname: string,
    lastname: string,
    username: string,
    email: string,
    recieveUpdatesFromOslyn: boolean
  ): Promise<boolean> => {
    return new Promise (async resolve => {
      let params = {
        body: {
          firstname, lastname, username, 
          email, recieveUpdatesFromOslyn,
        }
      }

      let data = await API.post('oslynstudiov1', '/studio/user/v2/add/unconfirmed', params) as any
      if (data.err && data.msg) { 
        showError(data.msg)
        resolve(false)
      } else {
        console.log(data)
        let id = data.body.data.createLabeller?.id || data.body.data.updateLabeller?.id
        console.log(id)
        if (id) {
          setNewUserId(id)
          resolve(true)
        }
      }
    })
  }

  async function submitCode(id: string, password: string, username: string, code: string): Promise<boolean> {
    return new Promise ( (resolve) => {
      var poolData = {
        UserPoolId: config.cognito.USER_POOL_ID, // Your user pool id here
        ClientId: config.cognito.APP_CLIENT_ID, // Your client id here
      }
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
      var userData = {
        Username: username,
        Pool: userPool
      }
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
      cognitoUser.confirmRegistration(code, true, async function(err, result) {
        if (err) {
          if (err.message) { showError(err.message) }
          resolve( false )
        }
        console.log('call result: ' + result)
        if (await postSignupConfirm(id)) {
          signIn(email, password).then( data => {
            if (data.error) {
              console.log(data.error)
              setLoginErrorMsg(data.error.message)
              setShowLoginErr(true)
              setTimeout(() => {
                setShowLoginErr(false)
              }, ERROR_WAIT_TIME)
            } else {
              localStorage.setItem("session", data.Session)
              //setUser(data)
              //setStage(data.challengeName)

              if (data.challengeName !== "NEW_PASSWORD_REQUIRED") login()
              else console.warn("NEW_PASSWORD_REQUIRED -- unexpected for this workflow")
            }
          })
          resolve( true )
        } else {console.log("postSignupConfirm failed."); resolve(false)}
        resolve( await postSignupConfirm(id) )
      })
    })
  }

  const postSignupConfirm = (id: string): Promise<boolean> => {
    return new Promise (async resolve => {
      let params = {
        body: { id }
      }

      let data = await API.post('oslynstudiov1', '/studio/user/v2/add/confirm', params) as any
      if (data.err && data.msg) { 
        showError(data.msg)
        resolve(false)
      } else { resolve(true) }
    })
  }

  const signIn = async (username: string, password: string) => {
    try {
      console.log(username)
      console.log(password)
      let user = await Auth.signIn(username, password)
      console.log(user)
      return user
    } catch (error) {
      console.log('error signing in', error)
      return { error: error }
    }
  }

  return (
    <div className={classes.paper} style={{paddingTop:"50px"}}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Create an Account!
      </Typography>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                margin="normal"
                value={firstName}
                onChange={e => { setFirstName(e.target.value) }}
              />
          </Grid>
          <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                margin="normal"
                value={lastName}
                onChange={e => { setLastName(e.target.value) }}
              />
          </Grid>
          <Grid item xs={12}>
            <TextField className="admin-input"
              value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined"
              required fullWidth id="email" label="Email" name="email"
              autoComplete="email"
            />
            <Typography className={classes.error}
              variant="caption" style={{opacity:showLoginErr?1:0}}>
              {loginErrorMsg}
            </Typography>
            <Divider style={{marginTop: 20}} />
          </Grid>
          <Grid item xs={12}>
            <TextField className="admin-input"
              value={newPass1} onChange={(e) => setNewPass1(e.target.value)} variant="outlined"
              margin="normal" required fullWidth name="new_password" label="New Password" type="password"
              id="new_password" autoComplete="current-password"
            />
            <TextField className="admin-input"
              value={newPass2} onChange={(e) => setNewPass2(e.target.value)} variant="outlined"
              margin="normal" required fullWidth name="retype_password" label="Retype Password" type="password"
              id="retype_password" autoComplete="current-password"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="I want to receive updates from Oslyn.io!"
              checked={recieveUpdatePref}
              onChange={e => { setRecieveUpdatePref((e.target as any).checked) }}
            />
          </Grid>
        </Grid>
        <div className={classes.wrapper}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
            onClick={async (e) => {
              const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (!firstName || firstName === '') { showError("Missing First Name!") } 
              else if (!lastName || lastName === '') { showError('Missing Last Name!') }
              else if (!email || !re.test(String(email).toLowerCase())) { 
                showError('Email is not of the correct format!')
              } else if (!(newPass1 && newPass2 && newPass1 === newPass2 && newPass1)) {
                showError('Passwords do not match!')
              } else {
                console.log(`Signup(${firstName}, ${lastName}, ${newPass1}, ${email})`)
                e.preventDefault()
                setLoading(true)

                let un = await getAvailUsername(firstName, lastName, email)
                console.log(un)

                if (un) {
                  setUsername(un)
                  if (await signUp(firstName, lastName, un, newPass1, email, recieveUpdatePref)) {
                    setOpenSignup(true)
                  } else { console.error("Couldn't signup .. Error should appear above.") }
                }
              }
            }}
          >Sign Up</Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </form>
      <SignupVerificationCode 
        open={openSignup} email={email} isRegistration={true}
        submitCode={(code: string) => submitCode(newUserId, newPass1, username, code)}/>
    </div>
  )
}