import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
  Paper, Grid, Typography, TextField, IconButton, Button,
  InputAdornment,
} from '@material-ui/core'

import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"

import { DraftHandleValue, Editor, EditorState, ContentState,
  Modifier, CompositeDecorator, ContentBlock } from 'draft-js'
import { SplitPane } from "react-collapse-pane" // https://collapse-pane.zurg.dev/#/

import EditSongStepper from './Stepper/EditSongStepper'
import { convertToProportionalFont, convertToMonospacedFont, 
  chordRegexForTextBlock, sectionRegexForTextBlock } from './EditSongUtil'

import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import AccountCircle from '@material-ui/icons/AccountCircle'


const _EDITORSCROLLID = "scroll-chordsheet-editor"
const _intermediaryChordVersion_ = 1
const _oslynSongVersion_ = 1

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 500
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
  }),
);

const sectionStrategy = (contentBlock: ContentBlock, 
  callback: (start: number, end: number) => void, 
  contentState: ContentState) => {
    const text = contentBlock.getText()
    let matchArr, start, end
    let regex = new RegExp(sectionRegexForTextBlock, 'g')

    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index
      end = start + matchArr[0].length
      callback(start, start + matchArr[0].length)
    }
}

const sectionSpan = (oldProps: any) => {
  let props = {...oldProps}

  if (props.contentState || props.contentState === null) delete props.contentState
  if (props.decoratedText || props.decoratedText === null) delete props.decoratedText
  if (props.blockKey || props.blockKey === null) delete props.blockKey
  if (props.entityKey || props.entityKey === null) delete props.entityKey
  if (props.offsetKey || props.offsetKey === null) delete props.offsetKey

  return (
    <span {...props} style={{
          fontSize:"1rem", fontWeight: 600, 
          backgroundColor: "rgba(255,138,101,0.2)", 
          cursor:"pointer", margin: 5
        }}
      onClick={() => {
        console.log(`onClick: "${oldProps.decoratedText.trim()}", position: ${oldProps.start}-${oldProps.end}`)
      }}
    >
      {props.children}
    </span>
  )
}



// Try this example later? https://medium.com/swlh/rich-editor-for-your-react-typescript-project-using-hooks-21c669e54df2
const chordStrategy = (contentBlock: ContentBlock, 
  callback: (start: number, end: number) => void, 
  contentState: ContentState) => {
    const text = contentBlock.getText()
    let matchArr, start, end
    let regex = new RegExp(chordRegexForTextBlock, 'g')

    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index
      end = start + matchArr[0].length
      if (matchArr[0].length === 3 && matchArr[0][1] === 'a') {
        //console.log(`Caught: len 3, matchArr[0][1] === a :: ${matchArr[0]}`)
        if (start-1 > 0 && end+1 < text.length ) {
          if (/^[a-zA-Z]/g.test(text.charAt(start-1)) && /^[a-zA-Z]/g.test(text.charAt(end+1)) ) continue
        }
      }

      callback(start, start + matchArr[0].length)
    }
}

const chordSpan = (oldProps: any) => {
  let props = {...oldProps}
  // console.log(props)

  if (props.contentState || props.contentState === null) delete props.contentState
  if (props.decoratedText || props.decoratedText === null) delete props.decoratedText
  if (props.blockKey || props.blockKey === null) delete props.blockKey
  if (props.entityKey || props.entityKey === null) delete props.entityKey
  if (props.offsetKey || props.offsetKey === null) delete props.offsetKey

  let chordId = `${oldProps.decoratedText.trim()}-${props.start}-${oldProps.end}`

  return (
    <span {...props} id={chordId}
      style={{fontWeight: 600, backgroundColor: "rgba(101,31,255, 0.2)", cursor:"pointer"}}
      onClick={() => {
        console.log(`onClick() chord id = "${chordId}"`)
      }}
    >
      {props.children}
    </span>
  )
}

interface GetSong {
  getSong: Song
}

export interface EditSongProps {
  id: string
  page: string
  goToSongs: () => void
}

export default function EditSong({ id, page, goToSongs }: EditSongProps) {
  const classes = useStyles()
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight? window.innerHeight: 500)
  
  const [panelSize, setPanelSize] = React.useState([2,1])
  const [forceUpdateKey, setForceUpdateKey] = React.useState(Math.random())

  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(
      new CompositeDecorator([
        {
          strategy: chordStrategy,
          component: chordSpan,
        },
        {
          strategy: sectionStrategy,
          component: sectionSpan,
        }
      ])
    ))
  const [song, setSong] = React.useState<Song|null>(null)
  const [openRawChordsheetChangeWarning, setopenRawChordsheetChangeWarning] = React.useState(false)

  const [tempSongTitle, setTempSongTitle] = React.useState("")
  const [editSongTitle, setEditSongTitle] = React.useState(false)

  const [tempSongAuthor, setTempSongAuthor] = React.useState("")
  const [editSongAuthor, setEditSongAuthor] = React.useState(false)

  const getData = async (id: string): Promise<boolean> => {
    return new Promise (async resolve => {
      let query = `query getSong {
        getSong(
          id: "${id}"
        ) {
          id title songAuthor isApproved olderSongVersion { id }
          newerSongVersion { id } songVersion chordSheetRaw beats
          chordSheetRawKey chordSheetPlatform chordSheetLink
          intermediaryChordJson intermediaryChordVersion 
          isIntermediaryChordChanged status
          oslynSongVersion recordings { items { 
            id songTitle formId key tabLinkUsed rawTranspositionFromTab
            transpositionFromTab fileExtension
            labeller { id email username oslynTeacherEmail email roles } 
            isLabelerRejected singerEmail singerName 
            status comment createDate updateDate
            lastOULGenerateDate
          } }
        }
      }`

      let data
      try {
        data = (await API.graphql(graphqlOperation(query))) as GraphQLResult<GetSong>
      } catch (e) { console.log(e); resolve(false) }

      // TODO: data.recordings has "items", while Song interface does not expect this ...
      let item = data ? data.data ? data.data.getSong : null : null
      setSong(item)

      // let text = editorState.getCurrentContent().getPlainText('\u0001')
      if (item) {
        let newEditorState = EditorState.push(
            editorState, ContentState.createFromText(""), 'remove-range')

        const newContent = Modifier.insertText(
          newEditorState.getCurrentContent(),
          newEditorState.getSelection(),
          convertToProportionalFont(item.chordSheetRaw),
          //OrderedSet.of("CODE")
        )
  
        setEditorState(EditorState.push(
          newEditorState,
          newContent,
          'insert-characters'
        ))
      } else {
        console.log("EditSong: No data.data.getSong found.")
        setopenRawChordsheetChangeWarning(true)
      }
      resolve(true)
    })
  }

  const postStep0Data = async (
    id: string, chordSheetRaw: string,
    chordSheetRawKey: string, chordSheetLink: string,
    nextStatus: number, beats: number
  ): Promise<boolean> => {
    return new Promise (async resolve => {
      let params = {
        body: {
          id, chordSheetRaw, chordSheetRawKey, 
          chordSheetLink, nextStatus, beats,
        }
      }

      console.log(chordSheetRaw)
      let data = API.post('oslynstudiov1', '/studio/songs/update/step-0', params) as any
      if (data.error) { console.log(data.error); resolve(false) }
      else { resolve(true) }
    })
  }

  const postStep1Data = async (
    id: string, intermediaryChordJson: string,
    nextStatus: number
  ): Promise<boolean> => {
    return new Promise (async resolve => {
      let params = {
        body: {
          id, intermediaryChordJson, nextStatus,
          intermediaryChordVersion: _intermediaryChordVersion_,
        }
      }
      let data = API.post('oslynstudiov1', '/studio/songs/update/step-1', params) as any
      if (data.error) { console.log(data.error); resolve(false) }
      else { resolve(true) }
    })
  }

  const postStep2Data = async (
    id: string, oslynSongJson: string,
    nextStatus: number
  ): Promise<boolean> => {
    return new Promise (async resolve => {
      let params = {
        body: {
          id, oslynSongJson, nextStatus,
          oslynSongVersion: _oslynSongVersion_
        }
      }
      let data = API.post('oslynstudiov1', '/studio/songs/update/step-2', params) as any
      if (data.error) { console.log(data.error); resolve(false) }
      else { resolve(true) }
    })
  }

  const postTitle = async (
    id: string, title: string
  ): Promise<boolean> => {
    return new Promise (async resolve => {
      let params = {
        body: {
          songId: id, title
        }
      }

      let data = API.post('oslynstudiov1', '/studio/song/update-title', params) as any
      if (data.error) { console.log(data.error); resolve(false) }
      else { resolve(true) }
    })
  }

  const postAuthor = async (
    id: string, author: string
  ): Promise<boolean> => {
    return new Promise (async resolve => {
      let params = {
        body: {
          songId: id, author
        }
      }

      let data = API.post('oslynstudiov1', '/studio/song/update-author', params) as any
      if (data.error) { console.log(data.error); resolve(false) }
      else { resolve(true) }
    })
  }

  const step2Next = async (oslynSongJson: OslynSongJson, nextStatus: number):Promise<boolean> => {
    return new Promise ( async resolve => {
      console.log("ACTION step2Next")
      if (song){
        let oslynJson = JSON.stringify(oslynSongJson)
        let returnStatus = await postStep2Data(song.id, oslynJson, nextStatus)
        if (returnStatus) {
          setSong({
            ...song, oslynSongJson: oslynJson, status: nextStatus,
            oslynSongVersion: _oslynSongVersion_,
          })
          resolve(true)
        } else { resolve(false) }
      }
    })
  }

  const step1Next = async (intermediaryChordJson: IntermediaryJson, nextStatus: number):Promise<boolean> => {
    return new Promise ( async resolve => {
      console.log("ACTION step1Next")
      if (song){
        console.log(intermediaryChordJson)
        let intermediaryJson = JSON.stringify(intermediaryChordJson)
        let returnStatus = await postStep1Data(song.id, intermediaryJson, nextStatus)
        if (returnStatus){
          setSong({
            ...song, intermediaryChordVersion: _intermediaryChordVersion_, 
            intermediaryChordJson: intermediaryJson, status: nextStatus
          })
          resolve(true)
        } else { resolve(false) }
      }
    })
  }

  const step0Next = async (link: string, key: string, nextStatus: number, beats: number): Promise<boolean> => {
    return new Promise ( async resolve => {
      console.log("ACTION step0Next")
      let text = editorState.getCurrentContent().getPlainText('\n') //editorState.getCurrentContent().getPlainText('\u0001')
      let monoText = convertToMonospacedFont(text)
      if (song){
        let returnStatus = await postStep0Data(song.id, monoText, key, link, nextStatus, beats)
        if (returnStatus) {
          // clear Doc -- goal is to reset all start/end numbers
          let newEditorState = EditorState.push(
            editorState, ContentState.createFromText(""), 'remove-range')

          const newContent = Modifier.insertText(
            newEditorState.getCurrentContent(),
            newEditorState.getSelection(),
            text,
            //OrderedSet.of("CODE")
          )
    
          setEditorState(EditorState.push(
            newEditorState,
            newContent,
            'insert-characters'
          ))
        }

        setSong({ // Im missing chordSheetPlatform .. but its not useful within EditSong anyway
          ...song, chordSheetLink: link, chordSheetRaw: monoText, chordSheetRawKey: key, status: nextStatus, beats: beats
        })

        resolve(returnStatus)
      } else { resolve(false) }
    })
  }

  useEffect(() => {
    // we expect an ID change here.
    if (id) {
      getData(id)
      setForceUpdateKey(Math.random())
      setPanelSize([2,1])
    }
  }, [id])

  const updateWindowDimensions = () => { setWindowHeight(window.innerHeight)}
  useEffect(() => {
    // TEST LETTER WIDTH USING THIS:
    // const newContent = Modifier.insertText(
    //   editorState.getCurrentContent(),
    //   editorState.getSelection(),
    //   "||\n                                                                                                    |                                                                                                    |"
    // )

    // setEditorState(EditorState.push(
    //   editorState,
    //   newContent,
    //   'insert-characters'
    // ))

    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  const handlePastedText = (text: string, html: string|undefined, currentEditorState: EditorState): DraftHandleValue => {
    let newText = convertToProportionalFont(text)

    const newContent = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      newText,
      //OrderedSet.of("CODE")
    )

    setEditorState(EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    ))

    return "handled" as DraftHandleValue
  }

  return (
    <div className={classes.root}>
      <SplitPane split="vertical" initialSizes={panelSize} key={forceUpdateKey}
          resizerOptions={{
            css: {width:'5px', backgroundColor: 'rgba(0, 0, 0, 0.1)'},
            hoverCss: {width:'5px', backgroundColor: 'rgba(0, 0, 0, 0.3)'}
          }}>
        <div style={{marginLeft:82, marginRight:10, marginTop:80}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                { editSongTitle ?
                  <div style={{display: "flex"}}>
                    <TextField fullWidth value={tempSongTitle} 
                      onChange={(e) => { setTempSongTitle(e.target.value) }}
                    />
                    <Button style={{marginLeft: 10}}
                      onClick={() => {
                        if (song){
                          postTitle(song.id, tempSongTitle)
                          setSong({...song, title: tempSongTitle})
                          setEditSongTitle(false)
                          setTempSongTitle("")
                        }
                      }}
                    ><SaveIcon style={{color: "grey"}}/></Button>
                  </div>:
                  <Typography variant="h4">
                    {song? song.title : "Title"} 
                    <IconButton style={{marginLeft: 5}}
                      onClick={() => {
                        if (song) setTempSongTitle(song.title)
                        setEditSongTitle(true)
                      }}
                    ><EditIcon/></IconButton>
                  </Typography>
                }
                { editSongAuthor ? 
                  <div style={{display: "flex"}}>
                    <TextField value={tempSongAuthor} 
                      onChange={(e) => { setTempSongAuthor(e.target.value) }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button style={{marginLeft: 10}}
                      onClick={() => {
                        if (song){
                          postAuthor(song.id, tempSongAuthor)
                          setSong({...song, songAuthor: tempSongAuthor})
                          setEditSongAuthor(false)
                          setTempSongAuthor("")
                        }
                      }}
                    ><SaveIcon style={{color: "grey"}}/></Button>
                  </div>:
                  <Typography variant="subtitle1" gutterBottom style={{paddingLeft: 5}}>
                    by {song? song.songAuthor : "Author"}
                    <IconButton style={{padding: 2, marginLeft: 5}}
                      onClick={() => {
                        if (song) setTempSongAuthor(song.songAuthor)
                        setEditSongAuthor(true)
                      }}
                    ><EditIcon style={{fontSize: 15}}/></IconButton>
                  </Typography>
                }
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div style={{overflow: "auto", zIndex: 500, height: windowHeight - 250}} id={_EDITORSCROLLID} >
                <Paper className={classes.paper} style={{minHeight: windowHeight - 260}}>
                  <Editor editorState={editorState} onChange={setEditorState}
                    textAlignment="left" placeholder="Paste Lyrics &#38; Chords here .."
                    handlePastedText={handlePastedText}
                  />
                </Paper>
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{
          marginLeft:14, marginRight: 10, marginTop:80,
          overflowY: "auto", overflowX: "hidden", zIndex: 500, height: windowHeight - 100
        }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <EditSongStepper song={song} editorId={_EDITORSCROLLID}
                step0Next={step0Next} step1Next={step1Next} step2Next={step2Next}
                goToSongs={goToSongs}  />
            </Paper>
          </Grid>
        </Grid>
        </div>
      </SplitPane>
    </div>
  )
}