import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Paper, Chip, Typography } from '@material-ui/core'

import { distanceFromKey, getChordByNumber } from '../../Songs/EditSong/EditSongUtil'
import MusicNoteIcon from '@material-ui/icons/MusicNote'
import ControlPointIcon from '@material-ui/icons/ControlPoint'

import A_chordImage from '../../../img/chords/A.png'
import Am_chordImage from '../../../img/chords/Am.png'

import Bflat_chordImage from '../../../img/chords/Bb.png'
import Bflatm_chordImage from '../../../img/chords/Bbm.png'

import B_chordImage from '../../../img/chords/B.png'
import Bm_chordImage from '../../../img/chords/Bm.png'

import C_chordImage from "../../../img/chords/C.png"
import Cm_chordImage from "../../../img/chords/Cm.png"

import Dflat_chordImage from "../../../img/chords/Db.png"
import Csharpm_chordImage from "../../../img/chords/Csm.png"

import D_chordImage from "../../../img/chords/D.png"
import Dm_chordImage from '../../../img/chords/Dm.png'

import Eflat_chordImage from "../../../img/chords/Eb.png"
import Eflatm_chordImage from "../../../img/chords/Empty_diagram.png"

import E_chordImage from "../../../img/chords/E.png"
import Em_chordImage from "../../../img/chords/Em.png"

import F_chordImage from "../../../img/chords/F.png"
import Fm_chordImage from "../../../img/chords/Fm.png"

import Fsharp_chordImage from "../../../img/chords/Fs.png"
import Fsharpm_chordImage from "../../../img/chords/Fsm.png"

import G_chordImage from "../../../img/chords/G.png"
import Gm_chordImage from "../../../img/chords/Gm.png"

import Aflat_chordImage from "../../../img/chords/Ab.png"
import Gsharpm_chordImage from "../../../img/chords/Gsm.png"

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors'

const StyleChip = withStyles({
  root: {
    borderColor: green[500],
  }
})(Chip)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 500
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    transposeTextField: {
      width: 80
    }
  }),
)

const chordImageMapKey = "A"
const ChordImageMap = [
  [A_chordImage, Am_chordImage],
  [Bflat_chordImage, Bflatm_chordImage],
  [B_chordImage, Bm_chordImage],
  [C_chordImage, Cm_chordImage],
  [Dflat_chordImage, Csharpm_chordImage],
  [D_chordImage, Dm_chordImage],
  [Eflat_chordImage, Eflatm_chordImage],
  [E_chordImage, Em_chordImage],
  [F_chordImage, Fm_chordImage],
  [Fsharp_chordImage, Fsharpm_chordImage],
  [G_chordImage, Gm_chordImage],
  [Aflat_chordImage, Gsharpm_chordImage]
]

interface ChordToShowProps {
  img: any,
  chord: string,
  index: number
}

export interface ChordImagesProps {
  originalKey: string,
  transpose: number,
  chords: OslynSongJsonChord[],
  chordPosition: number

  capo: number
  openPopup: () => void
  isRecording: boolean

  useSpaceBarless: boolean,
  curCount: number
}

export default function ChordImages({
  originalKey, transpose, chords,
  chordPosition, capo, openPopup, isRecording,
  useSpaceBarless, curCount
}: ChordImagesProps) {
  const classes = useStyles()
  const [chordsToShow, setChordsToShow] = React.useState<ChordToShowProps[]>([])

  useEffect(() => {
    let tempChordsToShow = [] as ChordToShowProps[]
    let keyDif = distanceFromKey(chordImageMapKey, originalKey)
    
    if (keyDif) {
      for(let i=0; i<chords.length; i++) {
        let chordInt = (chords[i].chord - keyDif + transpose) % 12
        chordInt = chordInt >= 0 ? chordInt : 12 + chordInt

        // console.log(`${chordInt}, ${ChordImageMap[chordInt]}, ${chords[i]}`)

        let chordImage = {
          img: ChordImageMap[chordInt][chords[i].isMinor ? 1 : 0],
          chord: getChordByNumber(chordInt, chords[i].isMinor, chordImageMapKey),
          index: i
        } as ChordToShowProps
        
        tempChordsToShow.push(chordImage)
      }
      setChordsToShow(tempChordsToShow)
    } else console.warn("ChordImages: keyDif is null")
  }, [originalKey, transpose, chords])

  return (
    <div style={{}}>
      <Paper className={classes.paper} 
        style={{
          display:"flex", flexDirection: "row", justifyContent: "center"}}>
        <div style={{flexGrow: 1}} />
        <div>
          { chordsToShow.map( chord => {
            return (
              <img key={`${Math.floor(Math.random()*9999999)}`} 
                style={{height: 150, background: chordPosition-1 === chord.index ? "rgba(101,31,255, 0.2)" : "inherit" }} 
                src={chord.img} alt={chord.chord} />
            )
          }) }
        </div>
        <div style={{flexGrow: 0.40}} />
        { useSpaceBarless ? <div style={{
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
            paddingBottom: 20
        }}>
          <div style={{display: "block", position: "relative", fontSize: 12}}>Beat</div>
          <div style={{display: "flex", fontSize: 30, alignItems: "center"}}>
            <div style={{
              border: "solid grey", borderRadius: 10, width: 60, height: 60, 
              display: "flex", alignItems: "center", justifyContent: "center",
            }}>
              <div style={{color: "black", paddingTop: 2}}>{curCount}</div>
            </div>
          </div>
        </div>:null }
        <div style={{flexGrow: 0.40}} />
      </Paper>

      <Chip style={{position: "absolute", top: 135-(useSpaceBarless?10:0), left: 108}}
          icon={<MusicNoteIcon />}
          label={<span>Play Key: <b>{getChordByNumber(transpose, false, originalKey)}</b></span>}
          clickable={!isRecording}
          onClick={() => { if (!isRecording) openPopup() }}
          color="primary"
          variant="outlined"
        />
        <Chip style={{position: "absolute", top: 170-(useSpaceBarless?10:0), left: 130}}
          icon={<ControlPointIcon />}
          label={<span>Capo: <b>{capo}</b></span>}
          clickable={!isRecording}
          onClick={() => { if (!isRecording) openPopup() }}
          variant="outlined"
        />
        {useSpaceBarless ? 
          <StyleChip style={{position: "absolute", top: 195, left: 126, display: "flex", alignItems: "center"}}
            icon={<CheckCircleOutlineIcon style={{color: green[500]}} />}
            label={<span style={{color: green[500]}}>Count In</span>}
            clickable={true}
            onClick={() => {}}
            variant="outlined"
          />:null
        }
    </div>
  )
}