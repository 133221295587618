import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
  Paper, Grid, Typography, IconButton, Backdrop, CircularProgress, Fab
} from '@material-ui/core'

import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"
import AWS from 'aws-sdk'

import { SplitPane } from "react-collapse-pane"
import { getChordByNumber } from '../Songs/EditSong/EditSongUtil'

import StopIcon from '@material-ui/icons/Stop'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import ReplayIcon from '@material-ui/icons/Replay'
import TuneIcon from '@material-ui/icons/Tune'

import ChordImages from './RecorderComponents/ChordImages'
import ChordAndLyric from './RecorderComponents/ChordAndLyric'
import Instructions from './RecorderComponents/Instructions'
import SubmitRecording from './RecorderComponents/SubmitRecording'
import KeyAndCapo from './RecorderComponents/KeyAndCapo'
import AutoTapHUD from './RecorderComponents/AutoTapHUD'
import ChordSheetDisplay from './RecorderComponents/ChordSheetDisplay'

import { LABELSTUDIO_KEY, LABELSTUDIO_VERSION, recordingToLabelStudioInput } from '../LabelStudio/LStudioUtils'
import env, { s3 } from '../../env'

const _EDITORSCROLLID = "scroll-chordsheet-recording"
const _COUNTERID = "song-count-in"


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 500
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    transposeTextField: {
      width: 80
    },
    largeIcon: {
      width: 35, height: 35
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    fabPosition: {
      display: "flex",
      position: "relative",
      bottom: theme.spacing(10)
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }),
);

const scrollToId = (chord: string, start: number, end: number, position: number) => {
  if (position) {
    let id = `${chord}-${start}-${end}-editor`
    console.log(`asking for chordId: ${id}`)

    let chordElm = document.getElementById(id)
    let editor = document.getElementById(_EDITORSCROLLID)
    console.log(chordElm)

    var elementPosition = chordElm?.getBoundingClientRect().top
    if (elementPosition) {
      //var offsetPosition = elementPosition - offset

      if (chordElm) {
        console.log(`chordElm: ${id} position: ${elementPosition}`)

        //if (elementPosition > 240 && elementPosition < 300) {
        if (elementPosition > 240 && elementPosition < 400) {
          console.log(`chordElm: ${id} is InViewport, position: ${elementPosition}`)
        } else {
          let offset = 0 + (45 * position)
          console.log(`chordElm: ${id} is NOT InViewport, position: ${elementPosition} :: offset: ${offset}`)
          editor?.scrollTo({
            top:  offset,
            behavior: "smooth"
          })
        }
      }
    }
  }
}

interface GetSong {
  getSong: Song
}

export interface AudioRecorderProps {
  id: string,
  user: Labeller,
  page: string,
  goToLabelStudio: (id: string) => void
}

export default function AudioRecorder({ id, page, user, goToLabelStudio }: AudioRecorderProps) {
  const classes = useStyles()
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight? window.innerHeight: 500)

  const [panelSize, setPanelSize] = React.useState([2,1])
  const [forceUpdateKey, setForceUpdateKey] = React.useState(Math.random())

  const [song, setSong] = React.useState<Song|null>(null)
  const [oslynSongJson, setOslynSongJson] = React.useState<OslynSongJson|null>(null)
  const [position, setPosition] = React.useState(0)
  const [chordPosition, setChordPosition] = React.useState(0)

  // key = original key (DO NOT CHANGE PER SONG)
  const [key, setKey] = React.useState(song ? song.chordSheetRawKey : "C")
  const [transpose, setTranspose] = React.useState(0)
  const [capo, setCapo] = React.useState(0)

  const [isRecording, setIsRecording] = React.useState(false)
  const [openIntroDialog, setOpenIntroDialog] = React.useState(false)

  const [recorder, setRecorder] = React.useState<MediaRecorder|null>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [recording, setRecording] = React.useState<Recording>({ song: [] } as Recording)
  const [timerStart, setTimerStart] = React.useState<number>(0)
  const [openSubmitDialog, setOpenSubmitDialog] = React.useState(false)
  
  const [audioBlob, setAudioBlob] = React.useState<Blob|null>(null)

  const [keyAndCapoDialogOpen, setKeyAndCapoDialogOpen] = React.useState(false)
  
  const [useSpaceBarless, setUseSpaceBarless] = React.useState(false)
  const [beatTimer, setBeatTimer] = React.useState<number>(0)
  const [curBeatCount, setCurBeatCount] = React.useState<number>(0)

  const [curChordBeat, setCurChordBeat] = React.useState(0)
  const [curBeat, setCurBeat] = React.useState(3)
  const [autoTap, setAutoTap] = React.useState(false)
  const [EOS, setEOS] = React.useState(false)

  const resetState = () => {
    setPosition(0)
    setChordPosition(0)
    setRecording({ song: [] } as Recording)
  }

  const getData = async (id: string): Promise<boolean> => {
    return new Promise (async resolve => {
      let query = `query getSong {
        getSong(
          id: "${id}"
        ) {
          id title songAuthor isApproved olderSongVersion { id }
          newerSongVersion { id } songVersion chordSheetRaw beats
          chordSheetRawKey chordSheetPlatform chordSheetLink
          intermediaryChordJson intermediaryChordVersion 
          isIntermediaryChordChanged status oslynSongJson
          oslynSongVersion recordings { items { 
            id songTitle formId key tabLinkUsed rawTranspositionFromTab
            transpositionFromTab fileExtension
            labeller { id email username oslynTeacherEmail email roles } 
            isLabelerRejected singerEmail singerName 
            status comment createDate updateDate
            lastOULGenerateDate
          } } isSpaceBarless
        }
      }`

      let data
      try {
        data = (await API.graphql(graphqlOperation(query))) as GraphQLResult<GetSong>
      } catch (e) { console.log(e); resolve(false) }

      // TODO: data.recordings has "items", while Song interface does not expect this ...
      let item = data ? data.data ? data.data.getSong : null : null
      setSong(item)

      if (item) {
        if (item.oslynSongJson) {
          setOslynSongJson(JSON.parse(item.oslynSongJson) as OslynSongJson)
          console.log(JSON.parse(item.oslynSongJson))
        } else {
          setOslynSongJson(null)
          console.warn(`No oslynSongJson found in Song within AudioRecorder`)
        }

        if (item.chordSheetRawKey) {
          setKey(item.chordSheetRawKey)
        } else {
          setKey("C")
          console.warn(`No chordSheetRawKey found in Song within AudioRecorder`)
        }

        setPosition(0)
        setChordPosition(0)
      } else {
        console.log("EditSong: No data.data.getSong found.")
      }
      resolve(true)
    })
  }

  const updateWindowDimensions = () => { setWindowHeight(window.innerHeight) }
  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getData(id)
      setForceUpdateKey(Math.random())
      setPanelSize([2,1])
    }
  }, [id])

  useEffect(() => {
    if (page && oslynSongJson && user) { setIsLoading(false) }
    else { setIsLoading(true) }
  }, [page, oslynSongJson, user])

  useEffect(() => {
    if (page === 'AudioRecorder' && !isLoading) {
      if (localStorage.getItem("AudioRecorder-Intro-Skip") !== "true")
        setOpenIntroDialog(true)
      else setKeyAndCapoDialogOpen(true)
      getMicrophone()
      return () => {
        setOpenIntroDialog(false)
        closeMicrophone()
      }
    } else { 
      setOpenIntroDialog(false)
      closeMicrophone()
    }
  }, [page, isLoading])

  useEffect(() => {
    if (page === 'AudioRecorder') {
      document.addEventListener('keydown', spacebarPress)
      return () => {document.removeEventListener('keydown', spacebarPress)}
    } else {
      document.removeEventListener('keydown', spacebarPress)
    }
  }, [
    recorder, position, chordPosition, timerStart, recording, transpose, capo, 
    useSpaceBarless, beatTimer, curBeatCount, curChordBeat])

  
  const closeMicrophone = () => {
    recorder? recorder.ondataavailable = null : console.log("could not deregister recorder.ondataavailable")
    recorder? recorder.onstop = null : console.log("could not deregister recorder.onstop")
    setRecorder(null)
    console.log(`closed Microphone!`)
  }

  const getMicrophone = () => {
    // needs to be SSL to work on other devices other than localhost
    navigator.mediaDevices.getUserMedia({
      audio: { 
        echoCancellation: false,
        noiseSuppression: false,
      }, video: false
    }).then((stream: MediaStream) => {
      console.log(`Audio Stream set -`)
      console.log(stream)
      var audioRecorder = new MediaRecorder(stream)
      setRecorder(audioRecorder)

      var chunks = [] as BlobPart[]
      if (audioRecorder) {
        audioRecorder.ondataavailable = (ev) => {
          chunks.push(ev.data)
        }

        audioRecorder.onstop = async ev => {
          let blob = new Blob(chunks, { type: 'audio/wav' })
          setAudioBlob(blob)
          setOpenSubmitDialog(true)
          chunks = [] as BlobPart[]
        }
      } else console.log("no audioRecorder!")
      
    }).catch(e => {
      console.log(e.name, e.message)
      //if (e.name )
    })
  }

  const startRecorder = () => {
    console.log(recorder)
    if (recorder && !isRecording) {
      recorder.start()
      setIsRecording(true)
      setTimerStart(performance.now())
    } else {
      console.warn("No Audio Recorder to start!")
    }
  }
  const stopRecorder = () => { 
    //console.log(recorder)
    if (recording) {
      if (recording.song.length > 0) {
        let tempRecording = recording
        let time = performance.now() - timerStart
        tempRecording.song[recording.song.length-1].end = time

        console.log(recordingToLabelStudioInput(tempRecording, ""))
        setRecording(tempRecording)
      } else console.warn("Cannot not end recording, Recording Jason .song is empty at this time.")
    } else console.warn("No recording [chord labels for audio] when stop called!")

    if (recorder && isRecording) {
      recorder.stop()
      setIsRecording(false)
      setTimerStart(0)
    } else console.warn("No Audio Recorder to stop!")
  }
  
  const createRecording = (): Promise<string> => { 
    return new Promise ( async resolve => {
      if (song && user) {

        console.log(user)
        console.log(song)

        let params = {
          body: {
            singerName: user.username,
            singerEmail: user.email,
            songTitle: song.title,
            key: getChordByNumber(transpose + capo, false, song.chordSheetRawKey),
            tabLinkUsed: song.chordSheetLink,
            transpositionFromTab: transpose + capo,
            fileExtension: "wav",
            status: "INAUDIT",
            isLabelerRejected: false,
            songId: song.id,

            labellerId: user.id
          }
        }
        let data = await API.post('oslynstudiov1', '/studio/recordings/create', params) as any
        if (data.error) { console.log(data.error); resolve("") }
        else {
          console.log(data)
          if (data.body){
            if (data.body.data) {
              if (data.body.data.createRecording) {
                if (data.body.data.createRecording.id) resolve(data.body.data.createRecording.id)
                else console.log("could not find data.body.data.createRecording.id")
              } else console.log("could not find data.body.data.createRecording")
            } else console.log("could not find data.body.data")
          } else console.log("could not find data.body")
          resolve("")
        }
      } else { console.log(`AudioRecorder(): the song or user is missing`) }
    })
  }

  const saveAudio = (id: string, blob: Blob):Promise<boolean> => {
    return new Promise<boolean>( async resolve => {
      const s3 = new AWS.S3({
        accessKeyId: env.s3.accessKeyId,
        secretAccessKey: env.s3.secretAccessKey
      })

      // This await is important 
      // - if you remove this line, s3 will get a promise instead of blob
      console.log( await blob.arrayBuffer() )
  
      s3.putObject({
        Bucket: env.s3PiplineBucket,
        Key: `public/${id}/raw/recording.wav`,
        Body: blob
      }, function (err, res) {
        if (err) { console.warn(err); resolve(false) } 
        else { console.log(res); resolve(true) }
      })
    })
  }

  const saveLabelStudioInput = (id: string, recording: Recording) => {
    return new Promise<boolean>(async resolve => {
      const s3 = new AWS.S3({
        accessKeyId: env.s3.accessKeyId,
        secretAccessKey: env.s3.secretAccessKey
      })

      let data = recordingToLabelStudioInput(recording, "")

      s3.putObject({
        Bucket: env.s3PiplineBucket,
        Key: `public/${id}/${LABELSTUDIO_KEY}/${LABELSTUDIO_KEY}_v${LABELSTUDIO_VERSION}.json`,
        Body: JSON.stringify(data)
      }, function (err, res) {
        if (err) {
          console.warn(err)
          resolve(false)
        } else {
          console.log(res)
          resolve(true)
        }
      })
    })
  }

  const spacebarPress = (event: KeyboardEvent): any => {
    if (event.code === 'Space') {
      event.preventDefault()
      if (!useSpaceBarless) nextChord()
      else nextBeatCount()
    }
  }

  const nextBeatCount = () => {
    if (curBeatCount === 0) {
      setTimerStart(performance.now())
      setCurBeatCount(curBeatCount+1)
    } else if (curBeatCount < 3) {
      let time = performance.now() - timerStart
      setBeatTimer(time)
      setTimerStart(performance.now())
      setCurBeatCount(curBeatCount+1)
    } else {
      setCurBeatCount(curBeatCount+1)
      console.log("STARTING AUTO-TAP")
      //let tempRecording = setChord2( { song: [] } as Recording, 0, 1 )

      // firstRound -- the listener is not registered yet, I need to set recording / chordPosition myself
      //setChordPosition(1)
      //setRecording(tempRecording)
      setAutoTap(true)
    }
    console.log(`Auto-tap ${curBeatCount}`)
  }

  useEffect(() => {
    if (page === 'AudioRecorder' && autoTap) {
      startRecorder()

      let beat = -1
      let position = 0
      let chordPosition = 1
      let eos = false
      
      let tempRecording =  {song: []} as Recording //setChord2( { song: [] } as Recording, 0, 1 )

      const interval = window.setInterval(() => {
        // calculate beat/position/chord
        if (oslynSongJson && song) {
          let c = oslynSongJson.song[position].chords[chordPosition-1]
          console.log(`Chord ${getChordByNumber(c.chord, c.isMinor, song.chordSheetRawKey)}, beats: ${c.beats} >= current: ${beat}`)
          if (c.beats && beat < c.beats-1) {
            beat = beat + 1
          } else {
            beat = 0
            if (chordPosition < oslynSongJson.song[position].chords.length) {
              tempRecording = setChord2( tempRecording, position, chordPosition+1 )
              chordPosition = chordPosition + 1
            } else {
              if (position+1 < oslynSongJson.song.length) {
                tempRecording = setChord2( tempRecording, position+1, 1 )
                position = position + 1
                chordPosition = 1
              } else {
                console.log(`End of song reached, stop recorder`)
                clearInterval(interval)
                eos = true
              }
            }
          }
        }

        document.body.dispatchEvent(
          new CustomEvent('next-beat-event', { 
            detail: {
              beat, position, chordPosition, eos, 
              recording: tempRecording
            } 
          })
        )
      }, beatTimer)

      const listener = (e: any) => {
        console.log("IN next-beat-event EVENT")
        console.log(e)
  
        setCurBeat(b => (b+1)%4) //TODO should be based on time signiture
        if (e.detail.beat || e.detail.beat === 0) { setCurChordBeat(e.detail.beat) }
        if (e.detail.position){ setPosition(e.detail.position) }
        if (e.detail.chordPosition){ setChordPosition(e.detail.chordPosition) }
        if (e.detail.recording){ 
          console.log(e.detail.recording)
          setRecording(e.detail.recording)
        }
  
        if (e.detail.eos) { 
          clearInterval(interval)
          setTimeout(() => {
            setEOS(true)
          }, 500)
        }
      }

      document.body.addEventListener('next-beat-event', listener)
      return () => document.body.removeEventListener('next-beat-event', listener)
    }
  }, [autoTap, page])

  const resetAutoTap = () => {
    setAutoTap(false)

    setBeatTimer(0)
    setCurBeatCount(0)
    setCurBeat(3)
  }

  useEffect(() => {
    if (autoTap && EOS && page === 'AudioRecorder') {
      stopRecorder()

      setEOS(false)
      resetAutoTap()
    }
  }, [autoTap, EOS, page])

  useEffect(() => {
    let counterElm = document.getElementById(_COUNTERID+`_${curBeatCount}`)
    if (counterElm) {
      console.log(`counterElm found `)
      
      counterElm.style.visibility = "visible"
      counterElm.style.opacity = "0.01"
    }
  }, [curBeatCount])

  const setChord2 = (
    recording: Recording,
    position: number,
    chordPosition: number
  ): Recording => {
    if (oslynSongJson && song) {
      console.log(`transpose: ${transpose}, capo: ${capo}`)

      let tempRecording = recording
      let chord = getChordByNumber(
        oslynSongJson.song[position].chords[chordPosition-1].chord + transpose + capo, 
        oslynSongJson.song[position].chords[chordPosition-1].isMinor,
        song.chordSheetRawKey
      )
      let time = performance.now() - timerStart
      console.log(`chord: "${chord}", start: ${time}`)
      if (tempRecording.song.length > 0)
        tempRecording.song[tempRecording.song.length-1].end = time
      tempRecording.song.push({ chord, start: time, end: null} as RecordingPhrase)

      return tempRecording
    } else {
      console.error(`setChord2(): oslynSongJson or song is null; returning recording as is.`)
      return recording
    }
  }

  const nextChord = () => {
    console.log(isRecording)
    if (oslynSongJson && song) {
      console.log(`position: ${position}, chordPosition: ${chordPosition}`)
      if (!isRecording && position === 0 && chordPosition === 0) { 
        console.log("recording started ...")
        startRecorder()

        let chordString = getChordByNumber(
          oslynSongJson.song[0].chords[0].chord,
          oslynSongJson.song[0].chords[0].isMinor,
          song.chordSheetRawKey
        )
        if (chordString)
          scrollToId( chordString,
            oslynSongJson.song[0].chords[0].meta.start,
            oslynSongJson.song[0].chords[0].meta.end,
            0
          )
        setChord(oslynSongJson, 0, 1, song, transpose, capo)
        setChordPosition(1)
      } else {
        if (chordPosition < oslynSongJson.song[position].chords.length) {
          //console.log(`SpacebarPress: chord position + 1 = ${chordPosition+1}, ${oslynSongJson.song[position].chords.length}`)
          let chordString = getChordByNumber(
            oslynSongJson.song[position].chords[chordPosition].chord,
            oslynSongJson.song[position].chords[chordPosition].isMinor,
            song.chordSheetRawKey
          )
          if (chordString)
            scrollToId( chordString,
              oslynSongJson.song[position].chords[chordPosition].meta.start,
              oslynSongJson.song[position].chords[chordPosition].meta.end,
              0
            )
          setChord(oslynSongJson, position, chordPosition+1, song, transpose, capo)
          setChordPosition(chordPosition+1)
        } else {
          console.log(`SpacebarPress: moving to next prhase .. ${position+1}`)
          if (position < oslynSongJson.song.length-1) {
            let chordString = getChordByNumber(
              oslynSongJson.song[position+1].chords[0].chord,
              oslynSongJson.song[position+1].chords[0].isMinor,
              song.chordSheetRawKey
            )
            if (chordString)
              scrollToId( chordString,
                oslynSongJson.song[position+1].chords[0].meta.start,
                oslynSongJson.song[position+1].chords[0].meta.end,
                position+1
              )
            setChord(oslynSongJson, position+1, 1, song, transpose, capo)
            setPosition(position+1) 
            setChordPosition(1)
          } else {
            console.log("End of song reached, stoping recorder.")
            stopRecorder()
          }
        }
      }
    } else console.warn(`no "oslynSongJson" or "song" found in AudioRecorder`)
  }

  const setChord = (
    oslynSongJson: OslynSongJson, position: number, chordPosition: number, 
    song: Song, transpose: number, capo: number
  ) => {
    console.log(`transpose: ${transpose}, capo: ${capo}`)

    let tempRecording = recording
    let chord = getChordByNumber(
      oslynSongJson.song[position].chords[chordPosition-1].chord + transpose + capo, 
      oslynSongJson.song[position].chords[chordPosition-1].isMinor,
      song.chordSheetRawKey
    )
    let time = performance.now() - timerStart
    console.log(`chord: "${chord}", start: ${time}`)
    if (tempRecording.song.length > 0)
      tempRecording.song[tempRecording.song.length-1].end = time
    tempRecording.song.push({ chord, start: time, end: null} as RecordingPhrase)
    
    setRecording(tempRecording)
  }

  const submitRecording = ():Promise<boolean> => {
    return new Promise (async resolve => {
      if ( audioBlob && recording ) {
        let recordingId = await createRecording()
        console.log(`recordingId: ${recordingId}`)

        if (recordingId) {
          if (await saveAudio(recordingId, audioBlob)) {
            if (await saveLabelStudioInput(recordingId, recording)) {
              resetState()
              goToLabelStudio(recordingId)
              resolve(true)
            } else { console.warn(`submitRecording(): failed to save recording [chord labels for audio] to s3`); resolve(false) }
          } else { console.warn(`submitRecording(): failed to save .wav file to s3`); resolve(false) }
        } else { console.warn(`submitRecording(): recording data entry could not be created. No recordingId found!`); resolve(false) }
      } else { console.warn(`submitRecording(): No AudioBlob or recording [chord labels for audio] found!`); resolve(false) }
    })
  }

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={
        isLoading && recorder == null
      }>
        <CircularProgress color="inherit" />
      </Backdrop> 
      <SplitPane split="vertical" initialSizes={panelSize} key={forceUpdateKey}
          resizerOptions={{
            css: {width:'5px', backgroundColor: 'rgba(0, 0, 0, 0.1)'},
            hoverCss: {width:'5px', backgroundColor: 'rgba(0, 0, 0, 0.3)'}
          }}>
        <div style={{marginLeft:82, marginRight:10, marginTop:80}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ChordImages originalKey={key} chordPosition={chordPosition} isRecording={isRecording}
                transpose={transpose} capo={capo} openPopup={() => setKeyAndCapoDialogOpen(true)}
                chords={oslynSongJson ? oslynSongJson.song[position].chords : [] as OslynSongJsonChord[]} 
                useSpaceBarless={useSpaceBarless} curCount={autoTap ? curBeat+1: 0}
              />
            </Grid>
            <Grid item xs={12}>
              <ChordAndLyric rawKey={key} chordPosition={chordPosition} transpose={transpose}
                position={position} phrases={oslynSongJson? oslynSongJson.song:[] as OslynSongJsonPhrase[]}/>
              <div className={classes.fabPosition}>
                { useSpaceBarless && oslynSongJson ? 
                  <AutoTapHUD 
                    oslynJson={oslynSongJson} position={position} 
                    chordPosition={chordPosition} rawKey={key}
                  /> 
                  : null}
                <div style={{flexGrow: 1}} />
                <Fab variant="extended" size="medium" style={{marginRight: 20}} 
                    onClick={() => {setKeyAndCapoDialogOpen(true)}} disabled={isRecording}>
                  <TuneIcon className={classes.extendedIcon}/>
                  Key &amp; Capo
                </Fab>
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{
          marginLeft:14, marginRight: 10, marginTop:80,
          height: windowHeight - 100
        }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h4">
                {song? song.title : "Title"}
                { isRecording ? 
                  <span className={isRecording? "blink_me" : ""} style={{marginLeft: 20}}>
                    <FiberManualRecordIcon style={{color: "red"}}/>
                  </span>: null
                }
              </Typography>
              <Typography variant="subtitle1" gutterBottom style={{paddingLeft: 5}}>
                by {song? song.songAuthor : "Author"}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            { song? <ChordSheetDisplay chordSheetRaw={song.chordSheetRaw} />: null }
            <Paper className={classes.paper} style={{display:"flex", flexDirection: "row", justifyContent: "center", marginTop: 17}}>
                <div style={{width: 15}}/>
                <IconButton color="primary" style={{border: "solid #d3d3d3 1px", padding: 16}}
                  onClick={() => { 
                    if (isRecording) { 
                      stopRecorder()
                      if (autoTap) {
                        document.body.dispatchEvent(
                          new CustomEvent('next-beat-event', { 
                            detail: {
                              eos: true, 
                            } 
                          })
                        )
                      }
                    }
                    else { startRecorder() }
                  }}
                >
                  {isRecording ? 
                    <StopIcon className={classes.largeIcon} style={{color: "red"}} />:
                    <RecordVoiceOverIcon className={classes.largeIcon} />}
                </IconButton>
                <IconButton color="primary" style={{marginTop:18, marginLeft: 2}}
                  onClick={() => {
                    if (isRecording) stopRecorder()
                    resetState()
                    //setKeyAndCapoDialogOpen(true)
                  }}
                >
                  <ReplayIcon />
                </IconButton>
            </Paper>
          </Grid>
        </Grid>
        </div>
      </SplitPane>
      <Instructions openIntroDialog={openIntroDialog} setOpenIntroDialog={setOpenIntroDialog} setKeyAndCapoOpen={setKeyAndCapoDialogOpen} />
      <SubmitRecording open={openSubmitDialog} setOpen={setOpenSubmitDialog} 
        reset={ async () => {
          if (isRecording) stopRecorder()
          resetState()
          setKeyAndCapoDialogOpen(true)
        }}
        submit={ async () => {
          setIsLoading(true)
          await submitRecording()
          setIsLoading(false)
        }}/>
      <KeyAndCapo 
        openDialog={keyAndCapoDialogOpen} originalKey={key} 
        setOpenDialog={b => { setKeyAndCapoDialogOpen(b) }}
        capo={capo} changeCapo={ capo => setCapo(capo) }
        transpose={transpose} changeTranspose={ transpose => setTranspose(transpose)}

        disabled={song?song.isSpaceBarless? !song.isSpaceBarless : true:true}
        useSpaceBarless={useSpaceBarless}
        setUseSpaceBarless={setUseSpaceBarless}
      />

      { useSpaceBarless ?
        [1, 2, 3, 4].map((val) => {
          return <div id={_COUNTERID+`_${val}`} key={val}
            style={{
              position: "fixed", left: "50%", top: "50%",
              transform: "translate(-50%, -50%)", fontSize: 80,
              
              opacity: 1, visibility: "hidden",
              transition: "opacity 1s"
            }}>
              {val}
            </div>
        }) : null
      }
      
    </div>
  )
}