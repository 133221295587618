import React, { useEffect } from 'react'
import throttle from "lodash.throttle"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TableBody, TableCell, TableRow, Checkbox,
  ButtonGroup, Button, Tooltip, CircularProgress} from '@material-ui/core'


import EditIcon from '@material-ui/icons/Edit';
import MicIcon from '@material-ui/icons/Mic';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { grey, green } from '@material-ui/core/colors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    margin: {
      margin: "0px",
    },
  }),
)

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string | boolean }, b: { [key in Key]: number | string | boolean }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface SongTableBodyProps {
  user: Labeller
  page: string
  matchpage: string
  rows: FlattenedSong[]
  selected: string[]
  handleClick: (event: React.MouseEvent<unknown>, name: string | number) => void
  goToAudioRecording: (id: string) => void
  goToEditSong: (id: string) => void
  setAssignOpen: (id: string) => void
  order: Order
  orderBy: keyof FlattenedSong
  getData: (nextToken: string|null) => Promise<boolean>
  nextToken: string|null
}

const MD_SCREEN = 960
const LG_SCREEN = 1280
export default function SongListTableBody({ 
  user, rows, selected, handleClick, goToAudioRecording, goToEditSong,
  order, orderBy, getData, nextToken, setAssignOpen, page, matchpage
}: SongTableBodyProps ) {
  const classes = useStyles();
  const [width, setWidth] = React.useState(0)

  const isSelected = (name: string | number ) => selected.indexOf(name.toString()) !== -1
  const loadingElement = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleResize() { setWidth(window.innerWidth) }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => { window.window.removeEventListener('resize', handleResize) }
  }, [])

  //const offset = 0
  //var canSendNextRequest = true

  /**
   * When the object is initiated, rows = []
   * We need to refresh the event listener AFTER EVERY UPDATE to rows
   */
  // useEffect(() => {
  //   if (page === matchpage) {
  //     console.log(`${matchpage} ${page}: adding scroll listener`)
  //     document.addEventListener('scroll', onScroll, true)
  //   } else {
  //     console.log(`${matchpage} ${page}: removing scroll listener`)
  //     document.removeEventListener('scroll', onScroll, true)
  //   }
  //   return () => document.removeEventListener('scroll', onScroll, true)
  // }, [rows, nextToken])

  // const onScroll = throttle( async () => {
  //   console.log("ON SCROLL")
  //   if (!loadingElement.current) { return }
    
  //   const top = loadingElement.current.getBoundingClientRect().top;
  //   let visibility = top + offset >= 0 && top - offset <= window.innerHeight
  //   console.log(`list length: ${rows.length}`)
  //   if (visibility) { 
  //     if (canSendNextRequest && nextToken) {
  //       canSendNextRequest = false
  //       canSendNextRequest = await getData(nextToken)
  //     } else console.log("Cannot send next request yet.")
  //   }
  // }, 1000)

  return (<TableBody>
    {stableSort(rows, getComparator(order, orderBy))
      .map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.id)}>
              <Checkbox
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </TableCell>
            <TableCell component="th" id={labelId} scope="row" padding="none" onClick={(event) => handleClick(event, row.id)}>
              {row.title}
            </TableCell>
            <TableCell align="right" onClick={(event) => handleClick(event, row.id)}>{row.songAuthor}</TableCell>
            <TableCell align="right" onClick={(event) => handleClick(event, row.id)}>{row.id}</TableCell>
            {width > LG_SCREEN ? <TableCell align="right" onClick={(event) => handleClick(event, row.id)}>{row.chordSheetLink}</TableCell>:null }
            <TableCell align="right" onClick={(event) => handleClick(event, row.id)}>{row.numOfRecordings}</TableCell>
            <TableCell align="right">
              {row.creatorName === ""? "unknown" : 
              <Tooltip title={`${row.creatorEmail}`}>
                <a style={{textDecoration:"underline"}}
                  href={`mailto: ${row.creatorEmail}`}>{row.creatorName}
                </a>
              </Tooltip>
              }
            </TableCell>
            <TableCell>
              <CheckCircleOutlineIcon style={{color: row.isSpaceBarless?green[500]:grey[400]}} />
            </TableCell>
            <TableCell align="right">
              <ButtonGroup color="primary" aria-label="outlined primary button group" style={{display: "flex", flexDirection: "row"}}>
                {(user.roles.includes('AELEAD') || user.roles.includes('ADMIN')) ?
                  <Tooltip title="Edit Song">
                    <Button aria-label="edit-song" className={classes.margin}
                      onClick={() => { goToEditSong(row.id) }}
                    >
                      <EditIcon fontSize="small" />
                    </Button>
                </Tooltip> :
                  <Button aria-label="edit-song" className={classes.margin} disabled={true}>
                    <EditIcon fontSize="small" />
                  </Button>
                }
                
                {row.oslynSongVersion ? 
                <Tooltip title="Record now!">
                  <Button aria-label="record-now" className={classes.margin}
                      onClick={() => { goToAudioRecording(row.id) }}
                  >
                    <MicIcon fontSize="small" />
                  </Button>
                </Tooltip>:
                <Button aria-label="record-now" className={classes.margin}
                    onClick={() => { goToAudioRecording(row.id) }}
                    disabled
                >
                  <MicIcon fontSize="small" />
                </Button>}
              </ButtonGroup>
            </TableCell>
          </TableRow>
        );
      })}
      <TableRow style={{display: nextToken?"":"none"}}>
        <TableCell colSpan={8} style={{textAlign:"center", alignContent: "center", height: "60px"}}>
            <CircularProgress color="inherit" size={20} ref={loadingElement} style={{margin: "0 auto"}}/>
        </TableCell>
      </TableRow>
  </TableBody>)
}