import React from 'react'
import { 
  Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button,
} from '@material-ui/core'

export interface SubmitRecordingProps {
  open: boolean,
  setOpen: (b: boolean) => void
  submit: () => void
  reset: () => void
}

export default function SubmitRecording ({ open, setOpen, submit, reset }: SubmitRecordingProps) {

  return (
    <Dialog
        open={open}
        onClose={() => { setOpen(false) }}
        aria-labelledby="instructions"
        aria-describedby="instructions"
      >
        <DialogTitle id="instructions">Awesome!</DialogTitle>
        <DialogContent>
          <DialogContentText id="instructions-1">
            Thank you for recording with us! What did you think of this recording? 
            You will get to review the labels in the next step! Don't worry!
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{paddingLeft: 20, paddingRight: 20}}>
          <Button color="secondary" onClick={() => {
            reset()
            setOpen(false)
          }}>
            Discard &amp; Replay
          </Button>
          <Button onClick={() => { 
            submit()
            setOpen(false)
          }} color="primary"
          >Submit</Button>
        </DialogActions>
      </Dialog>
  )
}