import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface ProfileDialogProps {
  open: boolean
  user: Labeller
  handleClose: () => void
}

export default function ProfileDialog({open, user, handleClose}: ProfileDialogProps ) {

  useEffect(() => {
    console.log(user)
  }, [user])

  return (
    <div>
      <Dialog style={{padding:"25px"}}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{paddingLeft:"30px"}}>{`Profile`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{paddingLeft: "30px", paddingRight: "30px"}}>
            <b style={{fontWeight: 700}}>ID:</b> {user.id}<br />
            <b style={{fontWeight: 700}}>Username:</b> {user.username}<br />
            <b style={{fontWeight: 700}}>Email:</b> {user.email}<br />
            <b style={{fontWeight: 700}}>Oslyn Teacher Email:</b> {user.oslynTeacherEmail}<br />
            <b style={{fontWeight: 700}}>Roles:</b>{user.roles}<br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}