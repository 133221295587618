import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core'

import { Editor, EditorState, ContentState,
  Modifier, CompositeDecorator, ContentBlock
} from 'draft-js'

import { 
  chordRegexForTextBlock, sectionRegexForTextBlock, 
  getChordByNumber, convertToProportionalFont
} from '../../Songs/EditSong/EditSongUtil'

const _EDITORSCROLLID = "scroll-chordsheet-recording"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 500
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    transposeTextField: {
      width: 80
    },
    largeIcon: {
      width: 35, height: 35
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    fabPosition: {
      display: "flex",
      position: "relative",
      bottom: theme.spacing(10)
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }),
);

const sectionStrategy = (contentBlock: ContentBlock, 
  callback: (start: number, end: number) => void, 
  contentState: ContentState) => {
    const text = contentBlock.getText()
    let matchArr, start, end
    let regex = new RegExp(sectionRegexForTextBlock, 'g')

    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index
      end = start + matchArr[0].length
      callback(start, start + matchArr[0].length)
    }
}

const sectionSpan = (oldProps: any) => {
  let props = {...oldProps}

  if (props.contentState || props.contentState === null) delete props.contentState
  if (props.decoratedText || props.decoratedText === null) delete props.decoratedText
  if (props.blockKey || props.blockKey === null) delete props.blockKey
  if (props.entityKey || props.entityKey === null) delete props.entityKey
  if (props.offsetKey || props.offsetKey === null) delete props.offsetKey

  return (
    <span {...props} style={{
          fontSize:"1rem", fontWeight: 600, 
          backgroundColor: "rgba(255,138,101,0.2)", 
          cursor:"pointer", margin: 5
        }}
      onClick={() => {
        console.log(`onClick: "${oldProps.decoratedText.trim()}", position: ${oldProps.start}-${oldProps.end}`)
      }}>
      {props.children}
    </span>
  )
}

const chordStrategy = (contentBlock: ContentBlock, 
  callback: (start: number, end: number) => void, 
  contentState: ContentState) => {
    const text = contentBlock.getText()
    let matchArr, start, end
    let regex = new RegExp(chordRegexForTextBlock, 'g')

    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index
      end = start + matchArr[0].length
      if (matchArr[0].length === 3 && matchArr[0][1] === 'a') {
        console.log(`Caught: len 3, matchArr[0][1] === a :: ${matchArr[0]}`)
        if (start-1 > 0 && end+1 < text.length ) {
          if (/^[a-zA-Z]/g.test(text.charAt(start-1)) && /^[a-zA-Z]/g.test(text.charAt(end+1)) ) continue
        }
      }
      callback(start, start + matchArr[0].length)
    }
}

const chordSpan = (oldProps: any) => {
  let props = {...oldProps}

  if (props.contentState || props.contentState === null) delete props.contentState
  if (props.decoratedText || props.decoratedText === null) delete props.decoratedText
  if (props.blockKey || props.blockKey === null) delete props.blockKey
  if (props.entityKey || props.entityKey === null) delete props.entityKey
  if (props.offsetKey || props.offsetKey === null) delete props.offsetKey

  let chordId = `${oldProps.decoratedText.trim()}-${props.start}-${oldProps.end}-editor`
  return (
    <span {...props} id={chordId}
      style={{fontWeight: 600, backgroundColor: "rgba(101,31,255, 0.2)", cursor:"pointer"}}
      onClick={() => {
        console.log(`onClick() chord id = "${chordId}"`)
      }}>
      {props.children}
    </span>
  )
}

const scrollToId = (chord: string, start: number, end: number, position: number) => {
  if (position) {
    let id = `${chord}-${start}-${end}-editor`
    console.log(`asking for chordId: ${id}`)

    let chordElm = document.getElementById(id)
    let editor = document.getElementById(_EDITORSCROLLID)
    console.log(chordElm)

    var elementPosition = chordElm?.getBoundingClientRect().top
    if (elementPosition) {
      //var offsetPosition = elementPosition - offset

      if (chordElm) {
        console.log(`chordElm: ${id} position: ${elementPosition}`)

        //if (elementPosition > 240 && elementPosition < 300) {
        if (elementPosition > 240 && elementPosition < 400) {
          console.log(`chordElm: ${id} is InViewport, position: ${elementPosition}`)
        } else {
          let offset = 0 + (45 * position)
          console.log(`chordElm: ${id} is NOT InViewport, position: ${elementPosition} :: offset: ${offset}`)
          editor?.scrollTo({
            top:  offset,
            behavior: "smooth"
          })
        }
      }
    }
  }
}

export interface ChordSheetDisplayProps {
  chordSheetRaw: string
}

export default function ChordSheetDisplay({
  chordSheetRaw
}: ChordSheetDisplayProps) {
  const classes = useStyles()
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight? window.innerHeight: 500)

  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(
      new CompositeDecorator([
        {
          strategy: chordStrategy,
          component: chordSpan,
        },
        {
          strategy: sectionStrategy,
          component: sectionSpan,
        }
      ])
    ))

  const updateWindowDimensions = () => { setWindowHeight(window.innerHeight) }
  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  useEffect(() => {
    let newEditorState = EditorState.push(
      editorState, ContentState.createFromText(""), 'remove-range')

    const newContent = Modifier.insertText(
      newEditorState.getCurrentContent(),
      newEditorState.getSelection(),
      convertToProportionalFont(chordSheetRaw)
    )

    setEditorState(EditorState.push(
      newEditorState,
      newContent,
      'insert-characters'
    ))
  }, [chordSheetRaw])

  return (
    <Paper className={classes.paper} style={{maxHeight: windowHeight - 350, overflowY: "auto"}} id={_EDITORSCROLLID}>
      <Editor editorState={editorState} onChange={setEditorState}
        textAlignment="left" placeholder="Loading ..." readOnly={true}
      />
    </Paper>
  )
}