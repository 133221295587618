import React from 'react'
import { 
  Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText,
  FormGroup, FormControlLabel, Checkbox, Button,
} from '@material-ui/core'

export interface InstructionsProp {
  openIntroDialog: boolean,
  setOpenIntroDialog: (b: boolean) => void
  setKeyAndCapoOpen: (b: boolean) => void
}

export default function Instructions ({
  openIntroDialog, setOpenIntroDialog,
  setKeyAndCapoOpen
}: InstructionsProp) {

    const [skipIntro, setSkipIntro] = React.useState(false)
  
    return (
      <Dialog
        open={openIntroDialog}
        onClose={() => { setOpenIntroDialog(false) }}
        aria-labelledby="instructions"
        aria-describedby="instructions"
      >
        <DialogTitle id="instructions">Welcome!</DialogTitle>
        <DialogContent>
          <DialogContentText id="instructions-1">
            Let Oslyn Studio access your microphone by clicking "Accept" when prompted for permissions from your browser.
          </DialogContentText>
          <DialogContentText id="instructions-2">
            Put the keyboard near your foot, and press the space bar every time the chord changes.
          </DialogContentText>
          <DialogContentText id="instructions-3">
            Press the space bar to begin recording!
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{paddingLeft: 20, paddingRight: 20}}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox style={{transform: "scale(0.8)"}}
                  checked={skipIntro}
                  onChange={() => { setSkipIntro( !skipIntro ) }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={<span style={{left: -20, fontSize: 10}}>Dont show me this again.</span>}
            />
          </FormGroup>
          <div style={{flexGrow: 1}}/>
          <Button onClick={() => { 
            if (skipIntro) localStorage.setItem("AudioRecorder-Intro-Skip", "true")
            setKeyAndCapoOpen(true)
            setOpenIntroDialog(false)
          }} color="primary"
          >Got it!</Button>
        </DialogActions>
      </Dialog>
    )
}