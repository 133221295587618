import React, { useEffect } from "react"
import { withStyles, createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { 
  FormControl, Select, MenuItem, TableRow, TableCell, Button
} from "@material-ui/core"

import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import { green, grey, red } from '@material-ui/core/colors'

import FiberNewIcon from '@material-ui/icons/FiberNew'
import VisibilityIcon from '@material-ui/icons/Visibility'

import { checkForChanges } from './SongChangeUtils'

const NoChangeCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

const HasChangedCheckbox = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[500],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" indeterminate {...props} />)

const IsNewCheckbox = withStyles({
  root: {},
  checked: {},
})((props: CheckboxProps) => 
  <Checkbox checked color="primary" icon={<FiberNewIcon />} checkedIcon={<FiberNewIcon />} name="checkedG" {...props} />)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    table: {
      maxWidth: 550
    },
  }),
);

export interface SectionProps {
  index: number,
  sectionName: string,
  
  newIJ: IntermediaryJson,
  oldIJ: IntermediaryJson,

  matchIndex: number,
  setMatchIndex: (match: number) => void

  setSectionIndex: (i: number) => void
}

export default function Section ({ 
  index, sectionName, oldIJ, newIJ,
  matchIndex, setMatchIndex, setSectionIndex
}: SectionProps) {
  const classes = useStyles()

  //const [menu, setMenu] = React.useState([<MenuItem key={-1} value={-1}><em style={{color: grey[400]}}>None</em></MenuItem>])
  //const [menu, setMenu] = React.useState<JSX.Element[]>([])
  const [isFullMatch, setIsFullMatch] = React.useState(true)

  // useEffect(() => {
  //   let tempMenu = [<MenuItem key={-1} value={-1}><em style={{color: grey[400]}}>None</em></MenuItem>]
  //   for (let i=0; i<oldIJ.sections.length; i++){
  //     tempMenu.push(<MenuItem key={i} value={i}>{oldIJ.sections[i].name}</MenuItem>)
  //   }
  //   setMenu(tempMenu)
  // }, [oldIJ])

  useEffect(() => {
    let oldTempIJ = { sections: [ oldIJ.sections[matchIndex] ] }
    let newTempIJ = { sections: [ newIJ.sections[index] ] }
    setIsFullMatch(!checkForChanges(oldTempIJ, newTempIJ))
  }, [oldIJ, newIJ])

  return (
    <TableRow>
      <TableCell padding="checkbox">
        { matchIndex === -1 ?
          <IsNewCheckbox checked={true} onChange={() => {}} name="checkedG" /> :
          isFullMatch ? 
            <NoChangeCheckbox checked={true} onChange={() => {}} name="checkedG" /> :
            <HasChangedCheckbox checked={true} onChange={() => {}} name="checkedG" />
        }
      </TableCell>
      <TableCell padding="checkbox" align={'center'}>{index+1}</TableCell>
      <TableCell align={'center'} style={{borderRight: 'solid 1px lightgrey'}}>
        {sectionName}
      </TableCell>
      <TableCell align={'right'}>
        <span style={{}}>matches:</span>
      </TableCell>
      <TableCell padding="checkbox">
        <FormControl className={classes.formControl}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={matchIndex}
            onChange={(e) => { setMatchIndex(e.target.value as number) }}
          >
            <MenuItem key={999999999} value={-1}><em style={{color: grey[400]}}>None</em></MenuItem>
            {oldIJ.sections.map((item, i) => {
              return <MenuItem key={i} value={i}>{item.name}</MenuItem>
            })}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell style={{paddingTop: 0, paddingBottom: 0}} align={'center'} padding="checkbox">
        <Button onClick={() => { setSectionIndex(index) }}
        ><VisibilityIcon style={{color: grey[600]}}/></Button>
      </TableCell>
    </TableRow>
  )

}