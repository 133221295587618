import React, { useEffect } from "react"
import { withStyles, createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { ChordContext } from "../PerSectionChangeSummary"
import { useDrop } from "react-dnd"
import { ItemTypes } from "./SongChangeUtils"

import DeleteIcon from '@material-ui/icons/Delete';

const _DEFAUT_PADDING_LEFT = 40

export interface DroppableChordProps {
  paddingLeft: number,
  chord: string,

  matchIndex: number,
  setMatchIndex: (n: number) => void
}

export default function DroppableChord ({
  paddingLeft, chord, matchIndex, setMatchIndex
}: DroppableChordProps) {

  const chordCont = React.useContext(ChordContext)
  const [isAssigned, setIsAssigned] = React.useState(false)
  //const [matchIndex, setMatchIndex] = React.useState(-1)

  const [newPaddingLeft, setNewPaddingLeft] = React.useState(paddingLeft)

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CHORD,
    drop: (item, monitor) => {
      console.log(item)
      setMatchIndex((item as any).chordIndex)
    },
    collect: (monitor: any) => ({
      isOver: monitor.isOver() 
    })
  })

  useEffect(() => {
    setIsAssigned( matchIndex !== -1 )
  }, [matchIndex])

  useEffect(() => {
    if (paddingLeft < _DEFAUT_PADDING_LEFT) setNewPaddingLeft(_DEFAUT_PADDING_LEFT)
    else setNewPaddingLeft(paddingLeft)
  }, [paddingLeft])

  return (
    <div ref={drop} style={{ display: "inline-block", position: "relative", height: 32 }}>
      <div style={{
        display: "inline", paddingLeft: newPaddingLeft-(chord.length*7-7), position: 'relative', 
        color: isAssigned?"purple":"grey", zIndex: isAssigned? 500:0
      }}>{chord}
        {isAssigned ? <small style={{fontSize: 8}}>[{matchIndex+1}]</small>:null}
      </div>
      {isAssigned ? 
      <div style={{
        display: "inline-flex", position: "absolute", width: 50, height: 50, top: -12, left: newPaddingLeft-17,
        alignItems: "center", justifyItems: "center", zIndex: 100, 
        backgroundColor: isOver?"grey":"white", borderRadius: 5, opacity: 0.8, border: "solid 2px purple"
      }}>
        <div style={{
          display: "flex", position: "absolute", width: 22, height: 22, borderRadius: 11, 
          backgroundColor: 'purple', color: "white",
          top: -6, right: -6, alignItems: "center", justifyContent: "center",
          cursor: "pointer"
        }} onClick={() => { setMatchIndex(-1) }}>
          <DeleteIcon style={{fontSize: 12}}/>
        </div>
      </div> :
      <div style={{
        display: "inline-flex", position: "absolute", width: 50, height: 50, top: -12, left: newPaddingLeft-17,
        alignItems: "center", justifyItems: "center", zIndex: 100, 
        backgroundColor: "grey", borderRadius: 5, opacity: isOver? 1: 0.1
      }} /> }
    </div>
  )
}