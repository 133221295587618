import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Paper, Grid, Fab, Dialog, DialogTitle, DialogContent,
  DialogContentText, Button, DialogActions, TextField
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import { API } from 'aws-amplify'

import SongsList from './SongListTable/SongsList'
import DeleteSongPopup from './Popups/DeleteSongPopup'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: "80px",
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    fabPosition: {
      position: "fixed",
      right: theme.spacing(5),
      bottom: theme.spacing(5)
    }
  }),
);

export interface SongsProps {
  user: Labeller,
  page: string
  matchpage: string
  goToEditSong: (id: string) => void
  goToAudioRecorder: (id: string) => void
}

export default function Recordings({ 
    user, goToEditSong, page, matchpage, goToAudioRecorder
  }: SongsProps) {
  const classes = useStyles();

  const [newSongOpen, setNewSongOpen] = React.useState(false)
  const [newSongTitle, setNewSongTitle] = React.useState("")
  const [newSongAuthor, setNewSongAuthor] = React.useState("")

  const [selected, setSelected] = React.useState<string[]>([])
  const [deleteOpen, setDeleteOpen] = React.useState(false)

  const createSong = (title: string, author: string) : Promise<string> => {
    return new Promise ( async resolve => {
      let params = {
        body: {
          songTitle: title, userId: user.id, songAuthor: author
        }
      }
      let data = await API.post('oslynstudiov1', '/studio/songs/create', params) as any
      if (data.error) { console.log(data.error); resolve("") }
      else { 
        console.log(data)
        if (data.body){
          if (data.body.data) {
            if (data.body.data.createSong) {
              if (data.body.data.createSong.id) resolve(data.body.data.createSong.id)
              else console.log("could not find data.body.data.createSong.id")
            } else console.log("could not find data.body.data.createSong")
          } else console.log("could not find data.body.data")
        } else console.log("could not find data.body")
        resolve("")
      }
    })
  }

  const handleNewSongDialogOpen = () => { setNewSongOpen(true) }
  const handleNewSongDialogClose = () => { setNewSongOpen(false) }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item lg={1} />
        <Grid item xs={12} lg={10}>
          <Paper className={classes.paper}>
            <SongsList user={user} title="Songs"
              page={page} matchpage={matchpage}
              setDeleteOpen={selected => { 
                setSelected(selected)
                setDeleteOpen(true)
              }} setDeleteClose={() => {}}
              setAssignOpen={() => {}} setFilterOpen={() => {}}
              goToAudioRecording={goToAudioRecorder} goToEditSong={goToEditSong}
            />
          </Paper>
        </Grid>
      </Grid>
      { user.roles.includes("ADMIN") || user.roles.includes("AELEAD") ? <Fab color="primary" aria-label="add" className={classes.fabPosition}
        onClick={handleNewSongDialogOpen}>
        <AddIcon />
      </Fab> : "" }
      <Dialog open={newSongOpen} onClose={handleNewSongDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Chord Sheet</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please use the official name of the song &#38; author.
          </DialogContentText>
          <TextField
            autoFocus
            value={newSongTitle}
            margin="dense"
            id="songtitle"
            label="Song Title"
            type="text"
            fullWidth
            variant="outlined"
            onChange={e => { setNewSongTitle(e.target.value) }}
          />
          <TextField
            value={newSongAuthor}
            margin="dense"
            id="songauthor"
            label="Author"
            type="text"
            fullWidth
            variant="outlined"
            onChange={e => { setNewSongAuthor(e.target.value) }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNewSongDialogClose} color="primary">
            Cancel
          </Button>
          <Button color="primary"
            onClick={ async () => {
              let id = await createSong(newSongTitle, newSongAuthor)
              if (id !== "") {
                handleNewSongDialogClose()
                setNewSongTitle("")
                setNewSongAuthor("")
                console.log(`song id: ${id}`)
                goToEditSong(id)
              } else alert("Error! Could not create a new chordsheet!")
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteSongPopup 
        isOpen={deleteOpen} setClose={() => {setDeleteOpen(false)}} user={user} 
        selected={selected}
      />
    </div>
  )
}