import React from "react"
import { withStyles, createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { ListItem, Avatar, ListItemAvatar, ListItemText } from '@material-ui/core'
import { useDrag } from "react-dnd"

import { ItemTypes } from './SongChangeUtils'

export interface DraggableChordProps {
  duration: number|null,
  chord: string,
  chordIndex: number,

  isUsed: boolean
}

export default function DraggableChord ({
  duration, chord, chordIndex, isUsed
}: DraggableChordProps) {

  //const { markAsDone } = React.useContext(ChordContext)

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: ItemTypes.CHORD,
    item: { chordIndex, duration, chord },
    collect: monitor => ({
      isDragging: monitor.isDragging() 
    })
  }))

  return (<div>
      { isUsed ?
        <ListItem ref={drag} style={{opacity: isDragging? 0.2: 0.5}}>
          <ListItemAvatar><Avatar ref={dragPreview} style={{borderRadius: 5}}>{chord}</Avatar></ListItemAvatar>
          <ListItemText primary={<span>Chord: {chord} {<small>[{chordIndex+1}]</small>}</span>} secondary={`Duration: ${duration} beats`} />
        </ListItem>:
        <ListItem ref={drag} style={{opacity: isDragging? 0.2: 1}}>
          <ListItemAvatar><Avatar ref={dragPreview} style={{borderRadius: 5}}>{chord}</Avatar></ListItemAvatar>
          <ListItemText primary={<span>Chord: {chord} {<small>[{chordIndex+1}]</small>}</span>} secondary={`Duration: ${duration} beats`} />
        </ListItem>
      }
    </div>
  )
}