import { FilterObj } from "./Popups/FilterPopup";




export const getFilterString = (filter: FilterObj): string => {
  let rstring = "filter: { and: {"

  if (filter.status) {
    rstring = rstring + `status: { eq: ${filter.status} } `
  }

  if (filter.labeller) {
    rstring = rstring + `singerName: { eq: "${filter.labeller.username}" } `
  }

  if (filter.title) {
    rstring = rstring + `songTitle: { eq: "${filter.title}" } `
  }

  return rstring !== "filter: { and {" ? rstring+"} }" : ""
}