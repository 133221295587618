import React from 'react';
import { 
  Button, TextField, Dialog, DialogActions, 
  DialogContent, DialogContentText, DialogTitle,
  FormGroup, FormControlLabel, Checkbox
} from '@material-ui/core'
import { API, graphqlOperation } from 'aws-amplify'

export interface DeletePopupProps {
  user: Labeller
  isOpen: boolean
  setClose: () => void
  selected: string[]
}

export default function DeletePopup({user, isOpen, setClose, selected} : DeletePopupProps) {

  const [hardDelete, setHardDelete] = React.useState(false)
  const [comment, setComment] = React.useState("")

  const handleDelete = async () => {
    if (hardDelete && selected.length > 0) {
      for (let id of selected) {
        let params = { body: { 
          id, userId: user.id, comment
        } }
        let data = await API.del('oslynstudiov1', '/studio/recording/hard-delete', params)
        if (data.error) { console.log(data.error) }
        else { setClose() }
      }
    } else if (selected.length > 0) {
      for (let id of selected) {
        let params = { body: { 
          id, userId: user.id, comment
        } }
        let data = await API.del('oslynstudiov1', '/studio/recording/soft-delete', params)
        if (data.error) { console.log(data.error) }
        else { setClose() }
      }
    } else {
      alert("TODO: Soft delete not implemented.")
    }
    setHardDelete(false)
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={setClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reason for Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the reason for deletion. To HARD delete, check the checkbox.
            Otherwise, all deletes are "soft deletes" and can be recovered.
            Permanent deletes will take place upon auditing this comment.
          </DialogContentText>
          <TextField autoFocus multiline rows={4} variant="outlined"
            id="comment" margin="dense" label="Comment" fullWidth
            value={comment} onChange={(e) => {setComment(e.target.value)}}
          />
        </DialogContent>
        <DialogActions style={{paddingLeft: 20, paddingRight: 20}}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox style={{transform: "scale(0.8)", color: user.roles.includes("ADMIN")?"red":""}}
                  checked={hardDelete}
                  onChange={() => { setHardDelete(!hardDelete) }}
                  name="checkedB"
                  color="primary"
                  disabled={!user.roles.includes("ADMIN")}
                />
              }
              label={
                <span style={{
                  left: -20, fontSize: 10, 
                  color: user.roles.includes("ADMIN")?"red":""
                }}>Hard Delete (caution)</span>}
            />
          </FormGroup>
          <div style={{flexGrow: 1}}/>
          <Button onClick={setClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}