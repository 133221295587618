import React, { useEffect } from 'react';
import '../../../../node_modules/react-vis/dist/style.css'

import { Paper, Typography } from '@material-ui/core'
import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"

import { recordingHistoryToNivoData } from '../DashboardUtil'
import {
  XYPlot,
  XAxis,
  YAxis,
  ChartLabel,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  LineSeriesCanvas,
  DiscreteColorLegend,
  LineMarkSeries,
  Hint
} from 'react-vis';

const _REACTVISOFFSET = 300
const _LIMIT = 10000

export interface RecordingHistory2Props {
  page: string
}

export default function RecordingHistory2 ({ page }: RecordingHistory2Props) {
  const [width, setWidth] = React.useState(1000)
  const [data, setData] = React.useState<RecordingHistoryNivoElement[]>([])
  
  const [hoveredPoint, setHoveredPoint] = React.useState<{
    user: string
    x: string | number | Date
    y: string | number | Date
  }>()

  const getData = (): Promise<boolean> => {
      return new Promise (async resolve => {
        let query = `query listRecordingHistory {
          listRecordingHistorys (
            limit: ${_LIMIT}
            filter: { newAction: { eq: "COMPLETE" } }
          ) { 
            items { id recording { id songTitle }
              labeller { id username email }
              newAction date
            }
          }
        }`
        let data
        try {
          data = ( await API.graphql(graphqlOperation(query))) as GraphQLResult<{ listRecordingHistorys: { items: RecordingHistory[] } }>
        } catch (e) { console.log(e); resolve(false) }
  
        let temp = data? data.data? data.data.listRecordingHistorys.items : null : null
        console.log(temp)
        if (temp) { 
          let nivoData = recordingHistoryToNivoData(temp)
          console.log(nivoData)
          setData(nivoData); resolve(true) }
        else { console.warn("RecordingHistory[] is empty"); resolve(false) }
      })
    }

  useEffect(() => {
    // setData(_data as RecordingHistoryNivoElement[])
    if (page === "Home"){
      getData()
      setWidth(window.innerWidth - _REACTVISOFFSET)

      window.addEventListener('resize', updateWindowDimensions)
      return () => window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [page])

  const updateWindowDimensions = () => { setWidth(window.innerWidth - _REACTVISOFFSET) }

  return (
    <Paper style={{height: 300, padding: 20, marginLeft: 10, marginRight:30, display: "flex"}}>
      <div>
        <Typography variant={"h4"}>
          Completion History
        </Typography>
        <XYPlot xType="time" width={width} height={200}>
          <HorizontalGridLines />
          <VerticalGridLines />
          {
            data.map((value, index) => {
              let newData = [] as {x:Date, y:number}[]
              for(let onePlot of value.data) {
                newData.push({x: new Date(onePlot.x), y: onePlot.y})
              }
              return <LineMarkSeries 
                key={value.id}

                onValueClick={(datapoint, event) => {
                  console.log(value.id)
                  console.log(datapoint)
                  setHoveredPoint({
                    user: value.id,
                    x: datapoint.x,
                    y: datapoint.y,
                  })
                }}

                data={newData as any}
              />
            })
          }
          {hoveredPoint && <Hint value={hoveredPoint} />}
          <XAxis />
          <YAxis />
          <ChartLabel 
            text="X Axis"
            className="alt-x-label"
            includeMargin={false}
            xPercent={0.025}
            yPercent={1.01}
          />

          <ChartLabel 
            text="Y Axis"
            className="alt-y-label"
            includeMargin={false}
            xPercent={0.06}
            yPercent={0.06}
            style={{
              transform: 'rotate(-90)',
              textAnchor: 'end'
            }}
          />
        </XYPlot>
      </div>
      <div style={{marginTop: 40}}>
        <DiscreteColorLegend height={200} width={_REACTVISOFFSET - 100}
          items={data.map(({id: name, ...rest}) => ({title: name, ...rest})) as any}
        />
      </div>
    </Paper>
  );
}

const _data = [
  {
    "id": "jasonli",
    "data": [
      {
        "x": "2021-01-18",
        "y": 2
      },
      {
        "x": "2021-01-22",
        "y": 1
      },
      {
        "x": "2021-01-26",
        "y": 2
      },
      {
        "x": "2021-01-29",
        "y": 1
      },
      {
        "x": "2021-02-04",
        "y": 1
      },
      {
        "x": "2021-02-06",
        "y": 1
      },
      {
        "x": "2021-02-11",
        "y": 1
      },
      {
        "x": "2021-02-12",
        "y": 1
      },
      {
        "x": "2021-02-17",
        "y": 1
      },
      {
        "x": "2021-02-19",
        "y": 1
      },
      {
        "x": "2021-02-23",
        "y": 1
      },
      {
        "x": "2021-02-26",
        "y": 1
      },
      {
        "x": "2021-03-01",
        "y": 0
      }
    ]
  },
  {
    "id": "domfung",
    "data": [
      {
        "x": "2021-01-21",
        "y": 1
      },
      {
        "x": "2021-01-22",
        "y": 1
      },
      {
        "x": "2021-01-28",
        "y": 4
      },
      {
        "x": "2021-01-29",
        "y": 4
      },
      {
        "x": "2021-01-30",
        "y": 11
      },
      {
        "x": "2021-02-01",
        "y": 9
      },
      {
        "x": "2021-02-02",
        "y": 8
      },
      {
        "x": "2021-02-03",
        "y": 5
      },
      {
        "x": "2021-02-05",
        "y": 5
      },
      {
        "x": "2021-02-06",
        "y": 10
      },
      {
        "x": "2021-02-07",
        "y": 7
      },
      {
        "x": "2021-02-08",
        "y": 5
      },
      {
        "x": "2021-02-09",
        "y": 4
      },
      {
        "x": "2021-02-10",
        "y": 3
      },
      {
        "x": "2021-02-11",
        "y": 7
      },
      {
        "x": "2021-02-12",
        "y": 3
      },
      {
        "x": "2021-02-15",
        "y": 4
      },
      {
        "x": "2021-02-16",
        "y": 5
      },
      {
        "x": "2021-02-17",
        "y": 5
      },
      {
        "x": "2021-02-18",
        "y": 7
      },
      {
        "x": "2021-02-19",
        "y": 5
      },
      {
        "x": "2021-02-20",
        "y": 4
      },
      {
        "x": "2021-02-21",
        "y": 6
      },
      {
        "x": "2021-02-24",
        "y": 1
      },
      {
        "x": "2021-02-26",
        "y": 3
      },
      {
        "x": "2021-02-27",
        "y": 5
      },
      {
        "x": "2021-02-28",
        "y": 6
      },
      {
        "x": "2021-03-01",
        "y": 5
      }
    ]
  },
  {
    "id": "nickwong",
    "data": [
      {
        "x": "2021-02-03",
        "y": 4
      },
      {
        "x": "2021-02-14",
        "y": 2
      },
      {
        "x": "2021-02-26",
        "y": 4
      },
      {
        "x": "2021-03-01",
        "y": 0
      }
    ]
  },
  {
    "id": "jasoncha",
    "data": [
      {
        "x": "2021-01-21",
        "y": 1
      },
      {
        "x": "2021-02-04",
        "y": 2
      },
      {
        "x": "2021-02-07",
        "y": 1
      },
      {
        "x": "2021-03-01",
        "y": 0
      }
    ]
  },
  {
    "id": "jasonsilaban",
    "data": [
      {
        "x": "2021-02-14",
        "y": 1
      },
      {
        "x": "2021-03-01",
        "y": 0
      }
    ]
  }
]