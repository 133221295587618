import React, { useEffect } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { ListItem, ListItemIcon, Collapse, List, ListItemText, TextField, InputAdornment, Avatar} from '@material-ui/core'

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LabelIcon from '@material-ui/icons/Label';
import MusicNoteIcon from '@material-ui/icons/MusicNote';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

const isInViewport = function (elem: HTMLElement) {
  var bounding = elem.getBoundingClientRect();
  return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export interface Step1SectionListProps {
  editorId: string
  section: IntermediaryJsonSection,
  setSection: (section: IntermediaryJsonSection, index: number) => void,
  sectionIndex: number
}

export default function Step1SectionList({ editorId, section, setSection, sectionIndex }: Step1SectionListProps ) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [chords, setChords] = React.useState<IntermediaryJsonChord[]>([])
  
  const [currentSelected, setCurrentSelected] = React.useState<number|null>(null)
  const [tempDurationValue, setTempDurationValue] = React.useState("")
  const [tempDurationError, setTempDurationError] = React.useState("")
  
  useEffect(() => {
    setChords(section.chords)
  }, [section])

  const saveAndClose = () => {

  }

  const moveToChord = (chord: IntermediaryJsonChord) => {
    let id = `${chord.chord}-${chord.start}-${chord.end}`
    console.log(`asking for chordId: ${id}`)

    let chordElm = document.getElementById(`${chord.chord}-${chord.start}-${chord.end}`)
    console.log(chordElm)

    console.log(editorId)
    let editor = document.getElementById(editorId)
    let offset = 100

    //chordElm?.scrollIntoView()
    var elementPosition = chordElm?.getBoundingClientRect().top
    if (elementPosition) {
      var offsetPosition = elementPosition - offset

      if (chordElm && !isInViewport(chordElm)) { 
        console.log(`chordElm: ${chord.chord}-${chord.start}-${chord.end} is NOT InViewport`)
        editor?.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        })
      } else console.log(`chordElm: ${chord.chord}-${chord.start}-${chord.end} isInViewport`)
    }
  }

  return (
    <div>
      <ListItem button onClick={() => { setOpen(!open) }}>
        <ListItemIcon>
          <LabelIcon />
        </ListItemIcon>
        <ListItemText primary={section.name}/>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          { chords.map( (chord, index) => {
            return (
            <ListItem dense
              className={classes.nested} 
              key={`step1-section-chord-${chord.start}-${chord.end}`}
              onClick={() => {
                moveToChord(chord)

                try {
                  let tempVal = parseFloat(tempDurationValue)
                  console.log(tempVal)
                  if (!isNaN(tempVal) && currentSelected) {
                    chords[currentSelected].duration = tempVal
                    setChords([...chords])
                    setTempDurationValue("")

                    section.chords = chords
                    setSection(section, sectionIndex)
                  }
                } catch (e) {}

                setCurrentSelected(index)
                setTempDurationValue(chords[index].duration?.toString() || "")
              }}
            >
              <ListItemIcon>
                <MusicNoteIcon />
              </ListItemIcon>
              { currentSelected === index ? 
                <ListItemText>
                  <TextField label="duration in beats"  autoFocus
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Avatar style={{width: 25, height: 25, fontSize: 12}}>{chord.chord}</Avatar>
                        </InputAdornment>
                      ),
                    }}
                    value={tempDurationValue}
                    error={tempDurationError !== ""}
                    helperText={tempDurationError}

                    onChange={(e) => { 
                      setTempDurationValue(e.target.value)
                      if (isNaN(parseFloat(e.target.value)) && e.target.value !== "") {
                        setTempDurationError("Not a number!")
                      } else setTempDurationError("")
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        try {
                           let tempVal = parseFloat(tempDurationValue)
                          if (!isNaN(tempVal)) {
                            chords[index].duration = tempVal
                            setChords([...chords])

                            setTempDurationValue("")
                            setCurrentSelected(currentSelected+1)

                            section.chords = chords
                            setSection(section, sectionIndex)
                          } else {
                            alert("Not a number!")
                          }
                          
                        } catch (e) {
                          alert("Not a number!")
                        }
                      }
                    }}
                  />
                </ListItemText>:
                <ListItemText>
                  <b style={{fontWeight: 600, fontSize: 16}}>
                    {chord.chord}
                  </b>, duration: {chord.duration ? chord.duration : ""}
                </ListItemText>
              }
            </ListItem>
            )
          })}
        </List>
      </Collapse>
    </div>
  )
}