import React, { useEffect, useRef } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';

import RecordingsList from './RecordingListTable/RecordingsList'
import DeletePopup from './Popups/DeletePopup'
import FilterPopup, { FilterObj } from './Popups/FilterPopup'
import AssignColleague from '../LabelStudio/ActionPopup/AssignColleague'

import { API } from 'aws-amplify'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: "80px"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

export interface RecordingsProps {
  title: string,
  user: Labeller,
  page: string,
  matchpage: string
  goToLabelStudio: (id: string) => void
}

export default function Recordings({
  title, user, goToLabelStudio, page, matchpage
}: RecordingsProps) {
  const classes = useStyles()
  const [deletePopupOpen, setDeletePopupOpen] = React.useState(false)
  const [selected, setSelected] = React.useState<string[]>([])
  const [assignColleagueRecordId, setAssignColleagueRecordId] = React.useState<string|null>(null)
  
  const [filterOpen, setFilterOpen] = React.useState(false)
  const [currentFilter, setCurrentFilter] = React.useState<FilterObj|null>(null)

  const RecordingsRef = useRef(null)

  // const [isStart, setIsStart] = React.useState<boolean>(false)

  useEffect(() => {
    if (page === "Workspace" && user){
      console.log("SETTING CURRENT FILTER")
      setCurrentFilter({
        labeller: user,
        status: 'INAUDIT'
      } as FilterObj)
    } else if (page === "RecordingList") {
      setCurrentFilter({
        status: 'TOLABEL'
      } as FilterObj)
    }
  },[page, user])

  useEffect(() => {
    if (page === matchpage) 
      console.warn(`Recordings MOUNT ${page} ${matchpage} ${JSON.stringify(currentFilter)}`)
    return () => { if (page === matchpage) console.warn("Recordings UNMOUNTED") }
  }, [page, matchpage, currentFilter])

  const setDeleteOpen = (selected: string[]) => { 
    setSelected(selected)
    setDeletePopupOpen(true)
  }
  const setDeleteClose = () => { setDeletePopupOpen(false) } 

  const setAssignOpen = (id: string) => { setAssignColleagueRecordId(id) }
  const setAssignClose = () => { setAssignColleagueRecordId(null) }

  const setFilterPopupOpen = () => { setFilterOpen(true) }
  const setFilterPopupClose = () => { setFilterOpen(false) }

  const  assignLabellerToRecording = (userId: string, recordingId: string): Promise<boolean> => {
    return new Promise ( resolve => {
      let params = {
        body: {
          userId, recordingId
        }
      }
      let data = API.post('oslynstudiov1', '/studio/recording/assign', params) as any
      if (data.error) { console.log(data.error); resolve(false) }
      else { resolve(true) } 
    })
  }

  const unassignLabellerToRecording = (userId: string, recordingId: string): Promise<boolean> => {
    return new Promise ( resolve => {
      let params = {
        body: {
          userId, recordingId
        }
      }
      let data = API.post('oslynstudiov1', '/studio/recording/unassign', params) as any
      if (data.error) { console.log(data.error); resolve(false) }
      else { resolve(true) } 
    })
  }

  return (
    <div className={classes.root} style={{overflow:"hidden"}}>
      <Grid container spacing={3}>
        <Grid item lg={1} />
        <Grid item xs={12} lg={10}>
          <Paper className={classes.paper}>
            <RecordingsList user={user} title={title}
              page={page} matchpage={matchpage}
              filter={currentFilter}
              setDeleteOpen={setDeleteOpen} 
              goToLabelStudio={(id) => goToLabelStudio(id)}
              setAssignOpen={(id) => {setAssignOpen(id)}}
              setFilterOpen={setFilterPopupOpen}
              
              assignLabellerToRecording={
                (userId: string, recordingId: string):Promise<boolean> => { 
                  return assignLabellerToRecording(userId, recordingId)
              }}
              unassignLabellerToRecording={
                (userId: string, recordingId: string):Promise<boolean> => { 
                  return unassignLabellerToRecording(userId, recordingId)
              }}

              filterOpen={filterOpen}
              setFilterPopupClose={setFilterPopupClose}
              setCurrentFilter={setCurrentFilter}
            />
          </Paper>
        </Grid>
      </Grid>
      <DeletePopup user={user} isOpen={deletePopupOpen} setClose={setDeleteClose} selected={selected}/>
      <AssignColleague recordId={assignColleagueRecordId}  handleClose={setAssignClose} 
        assignLabellerToRecording={(userId: string, username: string, email: string, recordingId: string):Promise<boolean> => { 
          return new Promise (async resolve => { 
            const event = new CustomEvent('assign-labeller', { 
              detail: {
                recordingId, username, email
              }
            } as any)
            if (await assignLabellerToRecording(userId, recordingId)) {
              document.body.dispatchEvent(event)
              console.log("assign-labeller EVENT dispatched")
              resolve(true)
            } else {
              console.log("Could not assignLabellerToRecording.")
              resolve(false)
            }
          })
        }}
      />
    </div>
  )
}

