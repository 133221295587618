import React from 'react'
import clsx from 'clsx'
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles'

import { Toolbar, Typography, IconButton, Tooltip } from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete'
import FilterListIcon from '@material-ui/icons/FilterList'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'

const useToolbarStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}),
);

interface EnhancedTableToolbarProps {
  title: string
  numSelected: number
  clickDelete: () => void
  setFilterOpen: () => void
}

export default function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {props.title}
        </Typography>
      )}
      {numSelected > 0 ? (
        <div style={{display: "flex", flexDirection: "row"}} >
        <Tooltip title="Assign All">
          <IconButton aria-label="assign-all">
            <AssignmentIndIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
        <IconButton aria-label="delete" onClick={props.clickDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      </div>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list" onClick={props.setFilterOpen}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};