import React, { useState } from 'react'
import { Avatar, Button, Snackbar, TextField, 
  Checkbox, Link, Grid, Box, Typography, Container, Divider 
} from '@material-ui/core'

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import { makeStyles, Theme } from '@material-ui/core/styles'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import SignupVerificationCode from './SignupVerificationCode'
import config from '../../../config.json'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    display: "block",
    color: "#f44336",
    transition: "opacity 0.3s ease-in-out"
  },
  wrapper: {
    marginTop: 40,
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -12,
  },
}));

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface ForgetPasswordProps {
  email: string
  setEmail: (email: string) => void
  goToSignin: () => void
}

export default function ForgetPassword ({ email, setEmail, goToSignin }: ForgetPasswordProps) {
  const classes = useStyles()

  const [openVerification, setOpenVerification] = React.useState(false)
  const [verifcationCode, setVerificationCode] = React.useState("")
  
  const [isChangePassword, setIsChangePassword] = React.useState(false)
  const [newPass1, setNewPass1] = useState('')
  const [newPass2, setNewPass2] = useState('')
  
  const [openError, setOpenError] = React.useState(false)

  async function sendResetPassword(username: string): Promise<boolean> {
    return new Promise( async (resolve) => {
      var poolData = {
        UserPoolId: config.cognito.USER_POOL_ID, // Your user pool id here
        ClientId: config.cognito.APP_CLIENT_ID, // Your client id here
      }
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

      let userData = {
        Username: username,
        Pool: userPool
      }
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

      cognitoUser.forgotPassword({
        onSuccess: (result) => {
          console.log(result)
          setOpenVerification(true)
          resolve(true)
        },
        onFailure: (err) => {
          console.log(err)
          setOpenError(true)
          resolve(false)
        }
      })
    })
  }

  async function confirmPassword(username: string, code: string, newPass: string): Promise<boolean> {
    return new Promise ( async (resolve) => {
      var poolData = {
        UserPoolId: config.cognito.USER_POOL_ID, // Your user pool id here
        ClientId: config.cognito.APP_CLIENT_ID, // Your client id here
      }
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

      let userData = {
        Username: username,
        Pool: userPool
      }
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

      cognitoUser.confirmPassword(code, newPass, {
        onSuccess: () => {
          console.log("Success!")
          goToSignin()
          resolve(true)
        },
        onFailure: (err) => {
          console.log(err)
          setOpenError(true)
          resolve(false)
        }
      })
    })
  }

  return (
    <div className={classes.paper} style={{paddingTop:"50px"}}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5" style={{paddingBottom: 20}}>
        Please enter your email
      </Typography>
      { isChangePassword ?
        <form className={classes.form} noValidate>
          <Grid container>
            <Grid item xs={12}>
              <TextField className="admin-input"
                value={newPass1} onChange={(e) => setNewPass1(e.target.value)} variant="outlined"
                margin="normal" required fullWidth name="new_password" label="New Password" type="password"
                id="new_password" autoComplete="current-password"
              />
              <TextField className="admin-input"
                value={newPass2} onChange={(e) => setNewPass2(e.target.value)} variant="outlined"
                margin="normal" required fullWidth name="retype_password" label="Retype Password" type="password"
                id="retype_password" autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12} className={classes.wrapper}>
            <Button type="submit" fullWidth variant="contained" color="primary" 
              className={classes.submit} onClick={async (e) => {
                e.preventDefault()
                if (newPass1 === newPass2)
                  confirmPassword(email, verifcationCode, newPass1)
                else console.log("Password not the same!")
              }}>
              Reset
            </Button>
            </Grid>
          </Grid>
        </form> :
        <form className={classes.form} noValidate>
          <TextField className="admin-input"
            value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined"
            required fullWidth id="email" label="Email" name="email"
            autoComplete="email"
          />
          <div className={classes.wrapper}>
            <Button type="submit" fullWidth variant="contained" color="primary" 
              className={classes.submit} onClick={async (e) => {
                e.preventDefault()
                sendResetPassword(email)
              }}>
              Send Password Reset
            </Button>
          </div>
        </form>

      }
      <SignupVerificationCode 
        open={openVerification} email={email} isRegistration={false}
        submitCode={(code: string): Promise<boolean> => {
          return new Promise(resolve => {
            setVerificationCode(code)

            setOpenVerification(false)
            setIsChangePassword(true)
            resolve(true)
          })
        }}
      />
      <Snackbar open={openError} autoHideDuration={6000} onClose={() => { setOpenError(false) }}>
        <Alert severity="error">
          You may have waited too long to reset your pass, try again!
        </Alert>
      </Snackbar>
      
    </div>
  )
}