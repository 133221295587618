import React, { useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { Table,TableContainer, Paper } from '@material-ui/core'
import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"

import SongListTableToolbar from './TableComponents/SongListTableToolbar'
import SongListTableHeader from './TableComponents/SongListTableHeader'
import SongListTableBody from './TableComponents/SongListTableBody'

const _LIST_START_AMOUNT = 1000
const _LIST_CONTINUE = 10

function createData(
  id: string,
  title: string,
  songAuthor: string,
  isApproved: boolean,
  
  chordSheetRawKey: string,
  chordSheetPlatform: string,
  chordSheetLink: string,

  isIntermediaryChordChanged: boolean,
  intermediaryChordVersion: number,

  oslynSongVersion: number,
  creator: Labeller | null,
  recordings: any,

  isSpaceBarless: boolean
): FlattenedSong {
  let creatorEmail = creator ? creator.email : ""
  let creatorName = creator ? creator.username : ""
  // console.log(creator)

  let numOfRecordings = recordings.items ? recordings.items.length : 0

  return {
    id, title, songAuthor, isApproved, chordSheetRawKey, chordSheetPlatform, 
    chordSheetLink, isIntermediaryChordChanged, intermediaryChordVersion, 
    oslynSongVersion, creatorEmail, creatorName, numOfRecordings, isSpaceBarless
  }
}

type Order = 'asc' | 'desc'

interface ListSongs {
  listSongs: {
    items: Song[]
    nextToken: string|null
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    margin: {
      margin: "0px",
    },
  }),
);

export interface SongListProps {
  title: string
  user: Labeller
  page: string
  matchpage: string
  setDeleteOpen: (selected: string[]) => void
  setDeleteClose: () => void
  goToAudioRecording: (id: string) => void
  goToEditSong: (id: string) => void
  setAssignOpen: (id: string) => void
  setFilterOpen: () => void
}

export default function SongsList({
  title, user, setDeleteOpen, setDeleteClose, goToEditSong,
  setAssignOpen, setFilterOpen, goToAudioRecording, page, matchpage
}: SongListProps) {
  const dense = true
  const classes = useStyles()

  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof FlattenedSong>('title')
  const [selected, setSelected] = React.useState<string[]>([])
  const [rows, setRows] = React.useState<FlattenedSong[]>([])

  const [filterOldSongs, setFilterOldSongs] = React.useState(true)

  const [nextToken, setNextToken] = React.useState<string|null>(null)

  //useEffect(() => { getData(null) }, [])
  useEffect(() => {
    if (page === "Songs")
      getData(null) 
  }, [page])
  
  const getData = async (nextToken: string|null) : Promise<boolean> => {
    return new Promise(async resolve => {
      
      console.log(`Use Token: ${nextToken}`)
      let query = `query getSongs {
        listSongs(
          limit: ${_LIST_START_AMOUNT}
        ) {
          items {
            id title songAuthor isApproved olderSongVersion { id }
            newerSongVersion { id } songVersion creator { id username email }
            chordSheetRawKey chordSheetPlatform chordSheetLink
            intermediaryChordVersion isIntermediaryChordChanged
            oslynSongVersion recordings { items { id } } isSpaceBarless
          }
        }
      }`

      if (nextToken) {
        console.log("in NEXT Token")
        query = `listSongs(
          limit: ${_LIST_START_AMOUNT},
          nextToken: "${nextToken}"
          ) {
            items {
              id title songAuthor isApproved olderSongVersion { id }
              newerSongVersion { id } songVersion creator { id username email }
              chordSheetRawKey chordSheetPlatform chordSheetLink
              intermediaryChordVersion isIntermediaryChordChanged
              oslynSongVersion recordings { items { id } } isSpaceBarless
            }
          }
        }`
      } //"Validation error of type SubSelectionRequired: Sub selection required for type ModelRecordingConnection of field recordings @ 'listSongs/items/recordings'"

      let data
      try {
        data = (await API.graphql(graphqlOperation(query))) as GraphQLResult<ListSongs>
      } catch (e) { console.log(e) }

      let tempNextToken = data ? data.data? data.data.listSongs.nextToken : null : null
      let firstCall = nextToken === null
      if (tempNextToken !== nextToken) {
        setNextToken(tempNextToken)
        var tempRows: FlattenedSong[] = []
        let items = data ? data.data ? data.data.listSongs.items : [] : []

        console.log(items)

        for (let item of items) {
          if (filterOldSongs && !item.newerSongVersion) {
            tempRows.push(createData(
              item.id, item.title, item.songAuthor, item.isApproved, 
              item.chordSheetRawKey, item.chordSheetPlatform, item.chordSheetLink? item.chordSheetLink : "N/A",
              item.isIntermediaryChordChanged, item.intermediaryChordVersion,
              item.oslynSongVersion, item.creator? item.creator as Labeller: null, 
              item.recordings, item.isSpaceBarless?true:false
            ))
          } else { console.log(`Not listing old song: ${item.id}`) }
        }

        firstCall ? setRows(tempRows) : setRows([...rows, ...tempRows])
        resolve(true)
      } else {
        console.log("REPEATE NEXT TOKEN FOUND. Skipping ...")
        resolve(false)
      }
    })
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof FlattenedSong) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log((event.target as HTMLInputElement).value)
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string | number) => {
    const selectedIndex = selected.indexOf(name.toString());
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.toString());
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  return (
    <div className={classes.root} style={{overflowY:"hidden"}}> 
      <Paper className={classes.paper} style={{overflowY:"hidden"}}>
        <SongListTableToolbar user={user}
          numSelected={selected.length} clickDelete={() => {
            console.log("Delete Songs engaged!")
            setDeleteOpen(selected)
          }}
          setFilterOpen={setFilterOpen} title={title}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <SongListTableHeader
              user={user}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <SongListTableBody
              user={user}
              page={page}
              matchpage={matchpage}
              rows={rows}
              selected={selected}
              handleClick={handleClick}
              goToAudioRecording={goToAudioRecording}
              goToEditSong={goToEditSong}
              setAssignOpen={setAssignOpen}
              order={order}
              orderBy={orderBy}
              getData={async (nextToken: any) => { return await getData(nextToken) }}
              nextToken={nextToken}
            />
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}