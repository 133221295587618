
// export const recordingHistoryToNivoData = (recordingHistoryList: RecordingHistory[]): RecordingHistoryNivoElement[] => {
  
//   let rhne_temp = {} as { [username: string]: RecordingHistoryNivoElement }
//   var unique = [] as string[]

//   for (let rh of recordingHistoryList) {
//     if (!unique.includes(rh.recording.id)){
//       unique.push(rh.recording.id)
//       let dateString = rh.date.split("T")[0]


//       if (rhne_temp[rh.labeller.username]) {

//         console.log(`REPEAT ${rh.recording.id} ${rh.labeller.username} ${rh.newAction}`)
//         console.log(rh)

//         let isFound = false
//         for (let xy of rhne_temp[rh.labeller.username].data) {
//           if (xy.x === dateString) {
//             xy.y = xy.y+1; isFound=true; break
//           }
//         }

//         if (!isFound) {
//           rhne_temp[rh.labeller.username].data.push({x: dateString, y: 1 } as RecordingHistoryNivoData)
//         }
//       } else {
//         console.log(`NEW ${rh.labeller.username} ${rh.labeller.username} ${rh.newAction} ${dateString}`)
//         let today = new Date()
//         rhne_temp[rh.labeller.username] = {
//           id: rh.labeller.username,
//           //color: "hsl(71, 70%, 50%)",
//           data: [ 
//             {x: dateString, y: 1 } as RecordingHistoryNivoData
//           ]
//         } as RecordingHistoryNivoElement

//         let todayDate = `${today.getFullYear()}-${
//           today.getMonth()+1 < 10 ? "0"+(today.getMonth()+1):today.getMonth()+1}-${
//           today.getDate() < 10 ? "0"+today.getDate():today.getDate()}`
//         if (dateString !== todayDate){
//           rhne_temp[rh.labeller.username].data.push({x: todayDate, y: 0 } as RecordingHistoryNivoData)
//         }
//       }
//     } else { 
//       console.log(`ID already here! ${rh.recording.id} ${rh.labeller.username} ${rh.newAction}`)
//     }
//   }

//   let rhne = [] as RecordingHistoryNivoElement[]
//   for (let key of Object.keys(rhne_temp)) { rhne.push(rhne_temp[key]) }
//   return rhne
// }

  export const recordingHistoryToNivoData = (recordingHistoryList: RecordingHistory[]):RecordingHistoryNivoElement[] => {
  
    let rhne_temp = {} as { [username: string]: RecordingHistoryNivoElement }
    var unique = [] as string[]
  
    for (let rh of recordingHistoryList) {
      if (!unique.includes(rh.recording.id)){
        unique.push(rh.recording.id)
        let dateString = rh.date.split("T")[0]

        if (rhne_temp[rh.labeller.username]) {
          rhne_temp[rh.labeller.username].data = insertHelper(dateString, rhne_temp[rh.labeller.username].data)
        } else {
          let today = new Date()
          rhne_temp[rh.labeller.username] = {
            id: rh.labeller.username,
            //color: "hsl(71, 70%, 50%)",
            data: [ 
              {x: dateString, y: 1 } as RecordingHistoryNivoData
            ]
          } as RecordingHistoryNivoElement
          let todayDate = `${today.getFullYear()}-${
            today.getMonth()+1 < 10 ? "0"+(today.getMonth()+1):today.getMonth()+1}-${
            today.getDate() < 10 ? "0"+today.getDate():today.getDate()}`
          //rhne_temp[rh.labeller.username].data = insertHelper(todayDate, rhne_temp[rh.labeller.username].data)

          if (dateString !== todayDate){
            rhne_temp[rh.labeller.username].data.push({x: todayDate, y: 0 } as RecordingHistoryNivoData)
          }
        }
      }
    }

  let rhne = [] as RecordingHistoryNivoElement[]
  for (let key of Object.keys(rhne_temp)) { rhne.push(rhne_temp[key]) }
  return rhne
}

/**
 * 
 * @param n dateString - format: YYYY-mm-dd
 * @param a SORTED RecordingHistoryNivoData[]
 * 
 * this is a mergesort implementation
 */
const insertHelper = (n:string, a: RecordingHistoryNivoData[]): RecordingHistoryNivoData[] => {
  if (a.length === 0) return [{x: n, y: 1}]
  else if (a.length === 1) {
    let d1 = Date.parse(a[0].x)
    let d2 = Date.parse(n)

    //console.warn(`d1:${d1}, d2:${d2}`)

    if (d1 > d2) return [{x:n, y:1}, a[0]]
    else if (d1 < d2) return [a[0], {x:n, y:1}]
    else if (d1 === d2) { a[0].y = a[0].y+1; return a }
    else console.warn("This is not possible!")
  } else {
    let split = Math.floor(a.length / 2)
    let d = Date.parse(n)
    let d1 = Date.parse(a[split-1].x)
    let d2 = Date.parse(a[split].x)

    if (d1 === d) { a[split-1].y = a[split-1].y + 1; return a } 
    else if (d2 === d) { a[split].y = a[split].y + 1; return a } 
    else if (d < d1) { 
      return insertHelper(n, a.slice(0, split))
        .concat(a.slice(split, a.length)) 
    }
    else if (d > d2) { 
      return a.slice(0, split).concat(
        insertHelper(n, a.slice(split, a.length))
      ) 
    }
    else { // right between d1 and d2
      a.splice(split, 0, {x: n, y: 1})
      return a
    }
  }

  // this should not be possible
  return []
}
