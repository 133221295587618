//import { getChordByNumber } from '../../../EditSongUtil'

export const ItemTypes = {
  CHORD: 'chord'
}

export const checkForChanges = (oldIJ: IntermediaryJson, newIJ: IntermediaryJson): boolean => {
  if (oldIJ.sections.length !== newIJ.sections.length)
    return true

  for (let si=0; si<newIJ.sections.length; si++) {
    if (oldIJ.sections[si].name !== newIJ.sections[si].name) return true
    if (oldIJ.sections[si].chords.length !== newIJ.sections[si].chords.length) return true
    for (let ci=0; ci<newIJ.sections[si].chords.length; ci++) {
      if (oldIJ.sections[si].chords[ci].chord !== newIJ.sections[si].chords[ci].chord)
        return true
    }
  }

  return false
}

export const checkForChangesInSongStructure = (): any => {

}

export const matchSections = (oldIJ: IntermediaryJson, newIJ: IntermediaryJson): number[] => {
  //let useCount = []
  let highScores = []

  for (let i=0; i<newIJ.sections.length; i++) {
    
    let tempScores = [] //new Array(oldIJ.sections.length).fill(0)
    for(let j=0; j<oldIJ.sections.length; j++) {
      tempScores[j] = getPositionScore(i, j) + 
        getNameSimilarityScore(newIJ.sections[i].name, oldIJ.sections[j].name)
    }

    highScores[i] = tempScores.indexOf( Math.max(...tempScores) )
  }

  return highScores
}

/** SCORE out of 100 */
const getPositionScore = (i: number, j: number): number => {
  let _ADJUST = 10
  return 100 - (Math.abs(i - j) * _ADJUST)
}

/** SCORE out of 100 */
const getNameSimilarityScore = (n1: string, n2: string): number => {
  let _ADJUST = 10
  let score = 100

  let minLen = n1.length < n2.length ? n1.length : n2.length
  let maxLen = n1.length > n2.length ? n1.length : n2.length

  for (let i=0; i<minLen; i++){
    if (n1.charAt(i) !== n2.charAt(i)) score = score - _ADJUST
  }

  score = score - ((maxLen - minLen) * _ADJUST)
  return score
}

export const matchChordsInSection = (newIJS: IntermediaryJsonSection, oldIJS: IntermediaryJsonSection): number[] => {

  let newChords = [] as string[]
  for (let i=0; i<newIJS.chords.length; i++) {
    newChords.push(newIJS.chords[i].chord)
  }
  console.log("new section")
  console.log(newChords)

  let oldChords = [] as string[]
  for (let i=0; i<oldIJS.chords.length; i++) {
    oldChords.push(oldIJS.chords[i].chord)
  }
  console.log("old section")
  console.log(oldChords)
  
  let returnableMatches = new Array(newChords.length).fill(-1)

  console.log("BEFORE allSequenceMatch()")
  let allMatches = allSequenceMatch(newChords, oldChords)
  for (let i=0; i<allMatches.length; i++) {
    let curMatch = allMatches[i]
    for (let k=0; k<curMatch.duration; k++){
      returnableMatches[curMatch.astart+k] = curMatch.bstart+k
    }
  }

  console.log(returnableMatches)
  return returnableMatches
}

const _MIN_MATCH_LENG = 2
/**
 * @param a the base (new chordsheet chords)
 * @param b the old set of chords -- this wont change
 */
const allSequenceMatch = (a: string[], b: string[]): { astart: number, bstart: number, duration: number }[] => {
  let seqs = [] as { astart: number, bstart: number, duration: number }[]
  let testableChunks = [ {seq1: a, s1: 0, seq2: b, s2: 0} ]
  
  let curIndex = 0 /** NOTE:: This might not be the best idea if line 119 gets accepted */
  while (testableChunks.length > 0) {
    console.log("BEFORE firstSequenceMatch()")
    console.log(a)
    console.log(b)

    let match = firstSequenceMatch(testableChunks[0].seq1, testableChunks[0].seq2)
    console.log(`${testableChunks[0].seq1.toString()} || astart: ${match?.astart} bstart: ${match?.bstart} ,, duration: ${match?.duration}`)

    if (match) {
      seqs.push({ astart: match.astart+testableChunks[0].s1, bstart: match.bstart+testableChunks[0].s2, duration: match.duration })

      if (match.astart >= _MIN_MATCH_LENG && match.astart+match.duration === testableChunks[0].seq1.length-1) {
        console.log(`PUSHING ${testableChunks[0].seq1.slice(0, match.astart-1)}, start: 0`)
        testableChunks.push({
          seq1: testableChunks[0].seq1.slice(0, match.astart-1), s1: 0,
          seq2: testableChunks[0].seq2.slice(0, match.bstart-1), s2: 0
        })
      }

      if (match.astart === 0 && match.astart+match.duration <= testableChunks[0].seq1.length - _MIN_MATCH_LENG ) {
        console.log(`PUSHING ${testableChunks[0].seq1.slice(match.astart+match.duration)}, start: ${match.astart+match.duration+curIndex}`)
        testableChunks.push({
          seq1: testableChunks[0].seq1.slice(match.astart+match.duration), s1: match.astart+match.duration+curIndex,
          seq2: testableChunks[0].seq2.slice(match.bstart+match.duration), s2: match.bstart+match.duration+curIndex
        })
      }

      curIndex = curIndex + match.astart+match.duration+curIndex
    }
    testableChunks.shift()
  }
  console.log(seqs)
  return seqs
}

/**
 * @param a the base (new chordsheet chords)
 * @param b the old set of chords -- this wont change
 */
const firstSequenceMatch = (a: string[], b: string[]): { astart: number, bstart: number, duration: number }|null => {
  let fsm = null
  
  let ap = 0
  let bp = 0
  
  while (ap < a.length && bp < b.length) {
    console.log(`firstSequenceMatch() while1 ap: ${ap}, bp: ${bp} fsm=${JSON.stringify(fsm)}`)

    let temp_ap = ap
    while (bp < b.length && temp_ap < a.length) {
      console.log(`firstSequenceMatch() while2 ap: ${temp_ap}, bp: ${bp} fsm=${JSON.stringify(fsm)}`)
      if (!fsm && a[temp_ap] === b[bp]) {
        console.log(`firstSequenceMatch() while2 EQUAL START :: ${a[temp_ap]} === ${b[bp]}`)
        fsm = {astart: temp_ap, bstart: bp, duration: 0}
        temp_ap=temp_ap+1
      }

      // if we reach the end for temp_ap OR bp,, and things are still equal
      else if (fsm && a[temp_ap] === b[bp] && b.length === bp+1) {
        console.log(`firstSequenceMatch() while2 EQUAL (b.len) END :: ${a[temp_ap]} === ${b[bp]} ${bp} ${bp-fsm.bstart}`)
        if (bp-fsm.bstart > _MIN_MATCH_LENG-1) { fsm.duration = bp-fsm.bstart+1; return fsm  }
        else { fsm=null; break }
      }
      else if (fsm && a[temp_ap] === b[bp] && a.length === temp_ap+1) {
        console.log(`firstSequenceMatch() while2 EQUAL (a.len) END :: ${a[temp_ap]} === ${b[bp]} ${bp} ${bp-fsm.bstart} ${a.length}`)
        if (bp-fsm.bstart > _MIN_MATCH_LENG-1) { fsm.duration = bp-fsm.bstart+1; return fsm  }
        else { fsm=null; break }
      }

      // increment by 1 if everything continues to be equal
      else if (fsm && a[temp_ap] === b[bp]) {
        console.log(`firstSequenceMatch() while2 EQUAL CONT :: ${a[temp_ap]} === ${b[bp]} ${bp} ${a.length} ${b.length}`)
        temp_ap=temp_ap+1
      }

      // end early, if the sequence is nolonger equal
      else if (fsm && a[temp_ap] !== b[bp]) {
        console.log(`firstSequenceMatch() while2 NOTEQUAL END :: ${a[temp_ap]} === ${b[bp]}, ${bp} duration: ${bp-fsm.bstart}`)
        if (bp-fsm.bstart > _MIN_MATCH_LENG-1) { fsm.duration = bp-fsm.bstart; return fsm  }
        else { fsm=null; break }
      }
      bp=bp+1
    }
    ap=ap+1; bp=0
  }
  
  if (fsm) fsm.duration = a.length - fsm.astart // recent change
  console.log(fsm)
  return fsm
}

export const mediateOslynIndermediaries = (
  oldIJ: IntermediaryJson, newIJ: IntermediaryJson,  
  sectionMatch: number[], perSectionMatch: number[][]
): IntermediaryJson|null => {
  
  if (sectionMatch.length !== newIJ.sections.length ) { 
    console.log(`sectionMatch: ${sectionMatch.length}`)
    console.log(`newIJ.sections: ${newIJ.sections.length}`)
    console.error("mediateOslynIndermediaries(): sectionMatch != newIJ.sections.length."); return null
  }
  
  let temp = { sections: [] } as IntermediaryJson

  for (let i=0; i<sectionMatch.length; i++) {
    let curTempSection = { 
      name: newIJ.sections[i].name,
      chords: newIJ.sections[i].chords
    } as IntermediaryJsonSection
    
    if (sectionMatch[i] !== -1) {
      let matchingSection = oldIJ.sections[sectionMatch[i]]
      let matchingSequence = perSectionMatch[sectionMatch[i]]

      if (matchingSequence.length !== newIJ.sections[i].chords.length) {
        console.log(matchingSequence)
        console.log(`matchingSequence: ${matchingSequence.length}`)
        console.log(`newIJ.sections[${i}].chords: ${newIJ.sections[i].chords.length}`)
        console.error(`mediateOslynIndermediaries(): matchingSequence != newIJ.sections[${i}].chords.length.`); return null
      }

      for (let k=0; k< newIJ.sections[i].chords.length; k++) {
        let curTempChord = newIJ.sections[i].chords[k]
        if (matchingSequence[k] !== -1) {
          curTempChord.duration = matchingSection.chords[matchingSequence[k]].duration
        }
        curTempSection.chords[k] = curTempChord
      }
    }

    temp.sections.push(curTempSection)
  }

  return temp
}