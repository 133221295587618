import React, { useEffect, useRef } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import throttle from "lodash.throttle"
import { Table,TableContainer, Paper } from '@material-ui/core'

import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"

import FilterPopup from '../Popups/FilterPopup'
import RecordingListTableToolbar from './TableComponents/RecordingListTableToolbar'
import RecordingListTableHeader from './TableComponents/RecordingListTableHeader'
import RecordingListTableBody from './TableComponents/RecordingListTableBody'
import { FilterObj } from '../Popups/FilterPopup'
import { getFilterString } from '../RecordingsUtil'

const _LIST_START_AMOUNT = 20
const _LIST_CONTINUE = 10

function createData(
  id: string,
  songTitle: string,
  formId: string,
  key: string,
  tabLinkUsed: string,
  rawTranspositionFromTab: string, // needs to be fixed in automation
  transpositionFromTab: number,
  fileExtension: string,

  labeller: Labeller | null,
  isLabelerRejected: boolean, // needs to be fixed in automation
  // labelerRejectionReason: string, -- we dont need this level of detail
  
  singerEmail: string,
  singerName: string,
  // gender: string,
  
  status: string,
  comment: string,

  createDate: string,
  updateDate: string,
  lastOULGenerateDate: string
): FlattenedData {
  let labellerEmail = labeller ? labeller.email : ""
  let labellerUsername = labeller ? labeller.username : ""
  let createDateAsInt = Date.parse(createDate)
  let updateDateAsInt = Date.parse(updateDate)
  let lastOULDateAsInt = Date.parse(lastOULGenerateDate)

  return {
    id, songTitle, formId, key, tabLinkUsed, rawTranspositionFromTab, transpositionFromTab, fileExtension,
    labellerEmail, labellerUsername, isLabelerRejected, singerEmail, singerName, status, comment, 
    createDate: createDateAsInt, updateDate: updateDateAsInt, lastOULGenerateDate: lastOULDateAsInt
  }
}

type Order = 'asc' | 'desc';

interface ListRecordings {
  listRecordings : {
    items: Data[]
    nextToken: string|null
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    margin: {
      margin: "0px",
    },
  }),
);

export interface RowsAndToken {
  nextToken: string|null
  rows: FlattenedData[]
}

export interface RecordingListProps {
  title: string
  user: Labeller
  matchpage: string
  page: string
  filter: FilterObj|null
  setDeleteOpen: (selected: string[]) => void
  goToLabelStudio: (id: string) => void
  setAssignOpen: (id: string) => void
  setFilterOpen: () => void
  
  assignLabellerToRecording: (userId: string, recordingId: string) => Promise<boolean>
  unassignLabellerToRecording: (userId: string, recordingId: string) => Promise<boolean>

  filterOpen: boolean
  setFilterPopupClose: () => void
  setCurrentFilter: (o: FilterObj) => void
}

export default function RecordingList({
    title, user, setDeleteOpen, page, matchpage,
    goToLabelStudio, setAssignOpen, setFilterOpen, 
    assignLabellerToRecording, unassignLabellerToRecording,
    
    filter, filterOpen, setFilterPopupClose, setCurrentFilter
  }: RecordingListProps) {
  const dense = true

  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof FlattenedData>('createDate');
  const [selected, setSelected] = React.useState<string[]>([]);

  const [rowsAndToken, setRowsAndToken] = React.useState<RowsAndToken>( { nextToken: null, rows: []} )

  /**
   *  Maybe rowsAndToken "changed" but the values in this object are the same ..
   */
  useEffect(() => {
    console.log(`RecordingsList() :: ${rowsAndToken.rows}`)

    if (page === matchpage && rowsAndToken.rows.length === 0 && 
        rowsAndToken.nextToken === null && filter && user) {
      preLoadingData(rowsAndToken.nextToken, rowsAndToken.rows, filter)
    } else if (page === matchpage && rowsAndToken.rows.length < window.innerHeight/60 &&
        rowsAndToken.nextToken && filter && user) {
      preLoadingData(rowsAndToken.nextToken, rowsAndToken.rows, filter)
    } else {

      console.warn("CALLING !!")

      console.log(`title: ${title}`)
      console.log(`user: ${JSON.stringify(user)}`)
      console.log(`matchpage: ${matchpage}`)
      console.log(`page: ${page}`)
      console.log(`filter: ${JSON.stringify(filter)}`)

      console.log(`rowsAndToken ${JSON.stringify(rowsAndToken)}`)

      console.log(`setDeleteOpen ${setDeleteOpen.toString()}`)
      console.log(`goToLabelStudio ${goToLabelStudio.toString()}`)
      console.log(`setAssignOpen ${setAssignOpen.toString()}`)
      console.log(`setFilterOpen ${setFilterOpen.toString()}`)
      console.log(`assignLabellerToRecording ${assignLabellerToRecording.toString()}`)

      console.log("Recording List -- preLoadingData")

      //preLoadingData(rowsAndToken.nextToken, rowsAndToken.rows, filter)
      setRowsAndToken({nextToken: rowsAndToken.nextToken, rows: rowsAndToken.rows})
    }

    return () => { console.log('UNMOUNTED') }
  }, [page, matchpage, rowsAndToken.nextToken, user, filter])

  const preLoadingData = throttle(async (
    nextToken: string|null, rows: FlattenedData[], filter: FilterObj
  ) => {
    console.log("BEFORE GET DATA")
    let temp = await getData(nextToken, rows, filter)
    console.log("GETTING DATA")
    console.log(temp)
    temp ? setRowsAndToken(temp) : console.log("preLoadingData() could not set RowsAndToken")
  }, 1000)

  useEffect(() => { 
    const listener = (e: any) => {
      console.log("IN assign-labeller EVENT")
      console.log(e)
      let temp = { nextToken: rowsAndToken.nextToken } as RowsAndToken
      let tempRows = rowsAndToken.rows
      for (let row of tempRows) {
        if (row.id === e.detail.recordingId) {
          console.log(`Changing Row: ${row.id}`)
          row.labellerUsername = e.detail.username
          row.labellerEmail = e.detail.email
          row.status = "INAUDIT"
          break
        }
      }

      temp.rows = tempRows // [...tempRows]
      setRowsAndToken(temp)
    }

    document.body.addEventListener('assign-labeller', listener)
    return () => document.body.removeEventListener('assign-labeller', listener)
  }, [rowsAndToken])

  
  // useEffect(() => {
  //   if (page === matchpage) {
  //     console.log("FILTER CHANGE!")
  //     setRowsAndToken({nextToken: null, rows: []})
  //   } else console.warn ("NO FILTER CHANGE")
  // }, [filter])

  const getData = async (nextToken: string|null, rows: FlattenedData[], filter: FilterObj) : 
      Promise<{nextToken: string|null, rows: FlattenedData[]}|null> => {
    return new Promise(async resolve => {
      console.log(`Use Token: ${nextToken}`)
      let query = `query getRecordings {
        listRecordings(
          limit: ${_LIST_START_AMOUNT}
          ${filter ? getFilterString(filter) : ""}
        ){ items { 
            id songTitle formId key tabLinkUsed 
            rawTranspositionFromTab transpositionFromTab
            fileExtension 
            labeller { email id oslynTeacherEmail roles username } 
            isLabelerRejected singerEmail singerName status
            comment createDate updateDate lastOULGenerateDate
          } 
          nextToken
        }
      }`

      if (nextToken) {
        console.log("in NEXT Token")
        query = `query getRecordings {
          listRecordings(
            limit: ${_LIST_CONTINUE}
            ${filter ? getFilterString(filter) : ""}
            nextToken: "${nextToken}"
          ){ items { 
              id songTitle formId key tabLinkUsed 
              rawTranspositionFromTab transpositionFromTab
              fileExtension 
              labeller { email id oslynTeacherEmail roles username } 
              isLabelerRejected singerEmail singerName status
              comment createDate updateDate lastOULGenerateDate
            } 
            nextToken
          }
        }`
      }

      console.log(query)

      let data
      try {
        data = ( await API.graphql(graphqlOperation(query))) as GraphQLResult<ListRecordings>
      } catch (e) { console.log(e) }

      let tempNextToken = data ? data.data? data.data.listRecordings.nextToken : null : null

      let firstCall = nextToken === null
      if (tempNextToken !== nextToken) {
        // setNextToken(tempNextToken)
        var tempRows: FlattenedData[] = []
        let items = data ? data.data ? data.data.listRecordings.items : [] : []

        console.log(items)

        for (let item of items) {
          tempRows.push(createData(
            item.id, item.songTitle, item.formId, item.key, item.tabLinkUsed, item.rawTranspositionFromTab,
            item.transpositionFromTab, item.fileExtension, item.labeller? item.labeller as Labeller: null, 
            item.isLabelerRejected, item.singerEmail, item.singerName, item.status, item.comment, 
            item.createDate, item.updateDate, item.lastOULGenerateDate
          ))
        }

        firstCall ? resolve({
          nextToken: tempNextToken,
          rows: tempRows
        }) : 
        resolve({
          nextToken: tempNextToken,
          rows: [...rows, ...tempRows]
        })
      } else {
        console.log("REPEATE NEXT TOKEN FOUND. Skipping ...")
        resolve(null)
      }
    })
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof FlattenedData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log((event.target as HTMLInputElement).value)
    if (event.target.checked) {
      const newSelecteds = rowsAndToken.rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string | number) => {
    const selectedIndex = selected.indexOf(name.toString());
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.toString());
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  return (
    <div className={classes.root} style={{overflowY:"hidden"}}>
      <Paper className={classes.paper} style={{overflowY:"hidden"}}>
        <RecordingListTableToolbar 
          numSelected={selected.length} clickDelete={() => setDeleteOpen(selected) }
          setFilterOpen={setFilterOpen} title={title}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <RecordingListTableHeader
              user={user}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowsAndToken.rows.length}
            />
            <RecordingListTableBody
              user={user} page={page} matchpage={matchpage}
              
              rowsAndToken={rowsAndToken}
              setRowsAndToken={setRowsAndToken}
              
              selected={selected}
              handleClick={handleClick}
              goToLabelStudio={goToLabelStudio}
              setAssignOpen={setAssignOpen}
              order={order}
              orderBy={orderBy}

              getData={getData}

              assignLabellerToRecording={ async (recordingId: string) => {
                if (await assignLabellerToRecording(user.id, recordingId)) {
                  let temp = {nextToken: rowsAndToken.nextToken} as RowsAndToken
                  let newRows = rowsAndToken.rows
                  
                  for (let row of newRows) {
                    if (row.id === recordingId) {
                      console.log(`Changing Row: ${row.id}`)
                      row.labellerEmail = user.email
                      row.labellerUsername = user.username
                      row.status = "INAUDIT"
                      break
                    }
                  }

                  temp.rows = newRows
                  setRowsAndToken(temp)
                }
              }}

              unassignLabellerToRecording={ async (recordingId: string) => {
                if (await unassignLabellerToRecording(user.id, recordingId)) {
                  let temp = {nextToken: rowsAndToken.nextToken} as RowsAndToken
                  let newRows = rowsAndToken.rows
                  
                  for (let row of newRows) {
                    if (row.id === recordingId) {
                      console.log(`Changing Row: ${row.id}`)
                      row.labellerEmail = ""
                      row.labellerUsername = ""
                      row.status = "TOLABEL"
                      break
                    }
                  }

                  temp.rows = newRows
                  setRowsAndToken(temp)
                }
              }}

              filter={filter}
            />
          </Table>
        </TableContainer>
      </Paper>
      <FilterPopup open={filterOpen} filter={filter} handleClose={setFilterPopupClose}
        setFilter={(filter: FilterObj) => { 
          setRowsAndToken({nextToken: null, rows:[]})
          setCurrentFilter(filter) 
        }} />
    </div>
  );
}