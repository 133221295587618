import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography, Card, CardContent, Button, CardActions, IconButton } from '@material-ui/core';

import MenuBookIcon from '@material-ui/icons/MenuBook';

import ChordDetectorCard from './Cards/ChordDetectorCard'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
      paddingTop: 80
    },
    cardRoot: {
      margin: theme.spacing(2),
      minWidth: 275,
    },
    title: {
      fontSize: 14,
      textAlign: "center"
    },
    description: {
      textAlign: "center",
      padding: theme.spacing(2),
      minHeight: 100
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      color: "purple",
      height: 50,
      width: 50,
    },
  }),
);

export default function AllDemos() {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <ChordDetectorCard />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <Card className={classes.cardRoot} variant="outlined">
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Page Turner
            </Typography>
            <div className={classes.controls}>
              <IconButton aria-label="play/pause">
                <MenuBookIcon className={classes.playIcon} style={{ color: "orange" }}/>
              </IconButton>
            </div>
            <Typography variant="body2" component="p" className={classes.description}>
              Moves to the next chord when given a chordsheet.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small">Activate!</Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}