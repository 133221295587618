import React, { useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid,
  Dialog, DialogTitle, DialogActions, Button, DialogContentText, DialogContent,
} from '@material-ui/core'

import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"

import LabellerTableRow from './LabellerTableRow'

function createData(
  id: string,
  username: string,
  email: string,
  oslynTeacherEmail: string,
  roles: ('AE'|'AELEAD'|'ADMIN')[]
):LabellerWithHistory {
  return {
    id, username, email, oslynTeacherEmail, roles,
    history: [
      { id: "e7ebf02b-ec15-4eaa-b1f2-e4402a8e1f60", postdate: '2020-01-05', cutoffmonth: 4, cutoffyear: 2021, amount: 3, currency: "CAN", admin: "prislee" },
      { id: "91448d36-8961-47c4-b237-f4dae5b7f622", postdate: '2020-01-02', cutoffmonth: 4, cutoffyear: 2021, amount: 1, currency: "CAN", admin: "prislee" },
    ],
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: 80,
      marginBottom: 20
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

interface ListLabellers {
  listLabellers: {items: Labeller[]}
}

export interface AdminProps {
  user: Labeller,
  goToHome: () => void
}

export default function Admin({ user, goToHome }: AdminProps) {
  const classes = useStyles()
  const [rows, setRows] = React.useState<LabellerWithHistory[]>([])

  useEffect(() => {
    getLabellers()
  }, [])
  
  const getLabellers = async () => {
    let query = `query listUser2 {
      listLabellers(
        limit: 20000
      ) { items { id username email roles oslynTeacherEmail } }
    }`

    let data = ( await API.graphql(graphqlOperation(query)) ) as GraphQLResult<ListLabellers>
    let items = data ? data.data ? data.data.listLabellers.items: [] : []
    
    var tempRows: LabellerWithHistory[] = []
    for (let item of items) {
      tempRows.push(createData(
        item.id, item.username, item.email, item.oslynTeacherEmail, item.roles as unknown as ('AE'| 'AELEAD'| 'ADMIN')[]
      ))
    }

    setRows(tempRows)
  }

  return (
    <div className={classes.root} style={{overflow: "hidden"}}>
      <Grid container spacing={3}>
        <Grid item lg={1} />
        <Grid item xs={12} lg={10}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell style={{fontWeight: 600}}>Oslyn Employee ID</TableCell>
                  <TableCell style={{fontWeight: 600}} align="right">Username</TableCell>
                  <TableCell style={{fontWeight: 600}} align="right">Email</TableCell>
                  <TableCell style={{fontWeight: 600}} align="right">
                    <span style={{paddingRight:"75px"}}>Role</span>
                  </TableCell>
                  <TableCell style={{fontWeight: 600}} align="right">
                    <span style={{paddingRight:"45px"}}>Disable</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <LabellerTableRow key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}