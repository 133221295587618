import React, { useEffect } from "react"
import { withStyles, createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { 
  DialogContent, Grid, Typography, MenuItem,
  List, FormControl, Select
} from "@material-ui/core"
import { grey } from '@material-ui/core/colors'

import { DndProvider } from "react-dnd"
import { HTML5Backend } from 'react-dnd-html5-backend'

import DroppableChord from "./utils/DroppableChord"
import DraggableChord from "./utils/DraggableChord"
import LyricLine from "./utils/LyricLine"

import { matchChordsInSection } from './utils/SongChangeUtils'
import { 
  convertOslynSongJson, getSectionText, getChordByNumber, 
  getLeftSpacing, rawChordsheetToIntermediaryJson, getSectionText2
} from "../../EditSongUtil"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    table: {
      minWidth: 400,
      maxWidth: 400
    },
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

interface sectionItem {
  id: string,
  primary: string,
  secondary: string | undefined
}

const getItems = (count: number): sectionItem[] =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    primary: `item ${k}`,
    secondary: k % 2 === 0 ? `Whatever for ${k}` : undefined
  }))

// a little function to help us with reordering the result
const reorder = (list: sectionItem[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});

export interface PerSectionChangeSummaryProps {
  oldSection: IntermediaryJsonSection|null,
  newSection: IntermediaryJsonSection,
  rawChordSheet: string,
  rawkey: string,
  index: number,

  oldIJ: IntermediaryJson,

  matchIndex: number,
  setMatchIndex: (match: number) => void,

  chordMapping: number[],
  setChordMapping: (n: number[]) => void
}

export const ChordContext = React.createContext(null as { markAsDone: (chordIndex: number) => void }|null)

export default function PerSectionChangeSummary ({
  oldSection, newSection, rawChordSheet, rawkey,
  oldIJ, matchIndex, setMatchIndex, index,
  chordMapping, setChordMapping
}: PerSectionChangeSummaryProps) {
  const classes = useStyles()

  const markAsDone = (chordIndex: number) => { /** Not sure how to impl */ }

  const [newOslynJson, setNewOslynJson] = React.useState<OslynSongJson|null>(null)

  useEffect(() => {
    let rawText = getSectionText2(rawChordSheet, index) // Still wrong
    let tempSections = rawChordsheetToIntermediaryJson(rawText)
    console.log(tempSections)
    setNewOslynJson( convertOslynSongJson(rawText, tempSections, rawkey) )

    console.log("BEFORE matchChordInSection()")
    if ( oldSection ) {
      let tempMatches = matchChordsInSection(newSection, oldSection)
      console.log(tempMatches)
      setChordMapping( tempMatches )
    }
    else setChordMapping(new Array(newSection.chords.length).fill(-1))
    console.log("AFTER matchChordInSection()")

  }, [newSection, oldSection, rawChordSheet, index])

  const [menu, setMenu] = React.useState([<MenuItem key={-1} value={-1}><em style={{color: grey[400]}}>None</em></MenuItem>])

  useEffect(() => {
    let tempMenu = [<MenuItem key={-1} value={-1}><em style={{color: grey[400]}}>None</em></MenuItem>]
    for (let i=0; i<oldIJ.sections.length; i++){
      tempMenu.push(<MenuItem key={i} value={i}>{oldIJ.sections[i].name}</MenuItem>)
    }
    setMenu(tempMenu)
  }, [oldIJ])

  return (<div>
    <DialogContent style={{paddingLeft: 20}}>
        <DndProvider backend={HTML5Backend}>
          <ChordContext.Provider value={{markAsDone}}>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="h6"
                  style={{color: "grey", fontSize: 18}}
                >{newSection.name}</Typography>
                <div style={{overflow: "scroll", minHeight: 420, maxHeight: 420, padding: 30, paddingTop:20}}>
                  { newOslynJson?.song.map((phrase, i1) => {
                    console.log(phrase)
                    let p = 0

                    return <div key={i1}>
                      <Typography variant="h6" component={"div"}
                        style={{color: "black", fontSize: 20, whiteSpace: "nowrap"}}
                      >
                        {phrase.chords.map((chord, i2) => {
                          console.log(chord)
                          
                          let paddingLeft = getLeftSpacing(phrase.lyric.substring(p, chord.position))*6
                          let c = getChordByNumber(chord.chord, chord.isMinor, rawkey)

                          p = chord.position+1
                          
                          let iTotal = 0
                          for (let k=0; k<i1; k++) {
                            iTotal = iTotal + newOslynJson.song[k].chords.length
                          }

                          console.log(`i1: ${i1}, i2: ${i2} | ${chordMapping[iTotal+i2]}`)

                          return <DroppableChord key={iTotal+i2}
                                    paddingLeft={paddingLeft} chord={c?c:"N"}
                                    matchIndex={chordMapping[iTotal+i2]} 
                                    setMatchIndex={(n) => {
                                      chordMapping[iTotal+i2]=n
                                      setChordMapping([...chordMapping])
                                    }}
                                  />
                        })}
                      </Typography>
                      <LyricLine line={phrase.lyric} />
                    </div>
                  })}
                </div>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6"
                  style={{color: "grey", fontSize: 18}}
                >Old Section Chords</Typography>
                <div style={{overflowY: "scroll", minHeight: 420, maxHeight: 420}}>
                  <List className={classes.root}>
                    { oldSection ?
                      oldSection.chords.map((chord, index) => {
                        return <DraggableChord key={index} chord={chord.chord} 
                                  chordIndex={index} duration={chord.duration}
                                  isUsed={chordMapping.includes(index)}
                                />
                      }):
                      <div>
                        <FormControl className={classes.formControl}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={matchIndex}
                            defaultValue={-1}
                            onChange={(e) => { setMatchIndex(e.target.value as number) }}
                          >
                            {menu}
                          </Select>
                        </FormControl>
                        <Typography variant="body1" style={{fontSize: 12, paddingTop: 30}}>
                          <em>* This is a brand new section! No duration data will be carried over.</em>
                        </Typography>
                      </div>
                    }
                  </List>
                </div>

              </Grid>
            </Grid>
          </ChordContext.Provider>
        </DndProvider>
    </DialogContent>
  </div>)


}