import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { Button, Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle,
        FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, FormHelperText,
        NativeSelect, Typography, TextField, CircularProgress
} from '@material-ui/core'

import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    dialogStyle: {
      padding: theme.spacing(1)
    },
    formControl: {
      margin: theme.spacing(3),
      marginTop: theme.spacing(0)
    },
    formControl2: {
      display: "flex",
      flexDirection: "row",
      margin: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
    labelStyle: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(1.5),
      marginRight: theme.spacing(2)
    },
    labelStyle2: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2.7),
      marginRight: theme.spacing(2)
    },
    labelStyle3: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(1.8),
      marginRight: theme.spacing(2)
    }
  }),
)

enum Status {
  TOLABEL = "TOLABEL",
  INAUDIT = "INAUDIT",
  COMPLETE = "COMPLETE"
}

interface ListLabellers {
  listLabellers: {items: Labeller[]}
}

export interface FilterObj {
  labeller: Labeller|null
  status: Status|null
  title: string|null
}

export interface FilterPopupProps {
  open: boolean
  filter: FilterObj|null
  handleClose: () => void
  setFilter: (filter: FilterObj) => void
}

export default function FilterPopup({ open, filter, handleClose, setFilter }:FilterPopupProps ) {
  const classes = useStyles()
  const [state, setState] = React.useState({ status: true, name: false, song: false })
  const { status, name, song } = state
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const [statusValue, setStatus] = React.useState(Status.TOLABEL)
  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(event.target.value)
    setStatus(event.target.value as Status)
  }

  const [AEOptionsList, setAEOptionsList] = React.useState<Labeller[]>([])
  const [AE, setAE] = React.useState<Labeller|null>(null)
  const [tempName, setTempName] = React.useState("")
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false)
  const loading = autocompleteOpen && AEOptionsList.length === 0
  const getLabellers = async (): Promise<Labeller[]> => {
    return new Promise (async resolve => {
      let query = `query listUser2 {
        listLabellers(
          limit: 20000
        ) { items { id username email roles oslynTeacherEmail } }
      }`
  
      let data = ( await API.graphql(graphqlOperation(query)) ) as GraphQLResult<ListLabellers>
      let items = data ? data.data ? data.data.listLabellers.items: [] : []
      resolve(items)
    }) 
  }
  const setAssignedAE = (labeller: Labeller|null) => { console.log(labeller);setAE(labeller) }
  React.useEffect(() => {
    let active = true;

    if (!loading) { return undefined }
    (async () => { 
      if (active) { setAEOptionsList( await getLabellers() ) }
    })()
    return () => { active = false }
  }, [loading]);
  React.useEffect(() => {
    if (!open) { setAEOptionsList([]) }
  }, [open])

  const [songTitle, setSongTitle] = React.useState<string|null>(null)
  const handleSongTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value)
    setSongTitle(event.target.value)
  }

  const handleClear = () => {
    setState({ status: false, name: false, song: false })
    setStatus(Status.TOLABEL)
    setAE(null)
    setSongTitle(null)
  }
  const handleApply = () => {

    console.log(AE)

    let filter = {
      status: state.status ? statusValue : null,
      labeller: state.name ? AE : null,
      title: state.song ? songTitle : null
    }

    console.log(filter)
    if (AE) setTempName(AE.email)
    setFilter(filter as FilterObj)
    handleClose()
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className={classes.dialogStyle}>
        <DialogTitle id="form-dialog-title">Add Filter</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Select all the filters you want to apply</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={status} onChange={handleChange} name="status" />}
                label={
                  <FormControl className={classes.formControl2}>
                    <Typography className={classes.labelStyle}>
                      Where <b style={{fontWeight: 700}}>Status</b> is 
                    </Typography>
                    <NativeSelect style={{top:"2px"}}
                      name="status"
                      value={statusValue}
                      onChange={handleStatusChange}
                    >
                      {Object.keys(Status).map( key => {
                        return <option key={key} value={key}>{key}</option>
                      })}
                    </NativeSelect>
                  </FormControl>
                }
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel disabled={statusValue === Status.TOLABEL}
                control={<Checkbox checked={name} onChange={handleChange} name="name" />}
                label={
                  <FormControl className={classes.formControl2}>
                    <Typography className={classes.labelStyle2}>
                      <b style={{fontWeight: 700}}>Singer Name</b>:
                    </Typography>
                    <Autocomplete style={{width: 300, marginBottom: 16}}
                      id="asynchronous-demo"
                      open={autocompleteOpen}
                      onOpen={() => { setAutocompleteOpen(true) }}
                      onClose={() => { setAutocompleteOpen(false) }}
                      getOptionSelected={(option, value) => option.email === value.email}
                      getOptionLabel={(option) => option.email}
                      options={AEOptionsList}
                      loading={loading}
                      //value={AE}
                      renderInput={(params) => (
                        <TextField {...params} label={
                            tempName !== "" ? <span style={{color: "black"}}>{tempName}</span> : (
                              AE ? <span style={{color: "white"}}>l</span>: <span style={{color: "grey"}}>Audio Engineer</span>
                            )}
                          InputLabelProps={{shrink: false}}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                      onChange={(e: React.ChangeEvent<any>, value) => { 
                        console.log(value)
                        setAssignedAE(value)
                        setTempName("")
                      }}
                    />
                  </FormControl>
                }
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={song} onChange={handleChange} name="song" />}
                label={
                  <FormControl className={classes.formControl2}>
                    <Typography className={classes.labelStyle3} style={{}}>
                      <b style={{fontWeight: 700}}>Song Title</b> contains: 
                    </Typography>
                    <TextField id="standard-basic" label="Song" style={{top:"-5px", width:250}} 
                        value={songTitle? songTitle:""} onChange={handleSongTitleChange}/>
                  </FormControl>
                }
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleClear} color="secondary">Clear</Button>
          <Button onClick={handleApply} color="primary">Apply</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}