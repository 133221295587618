import React, { useEffect } from "react"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Dialog, DialogTitle, DialogActions, Button, Slide } from "@material-ui/core"

import Pagination from '@material-ui/lab/Pagination'
import ToggleButton from '@material-ui/lab/ToggleButton'

import MenuBookIcon from '@material-ui/icons/MenuBook'

import SongChangeSummary from './components/SongChangeSummary'
import SectionChangeSummary from './components/PerSectionChangeSummary'

import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"

import { rawChordsheetToIntermediaryJson } from "../EditSongUtil"
import { matchSections, mediateOslynIndermediaries } from "./components/utils/SongChangeUtils"

// Auto Tap

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    table: {
      minWidth: 400,
      maxWidth: 400
    },
  }),
);

export interface SectionChangeReportProps {
  open: boolean,
  song: Song,

  onClose: () => void,
  step1Next: (intermediaryChordJson: IntermediaryJson, nextStatus: number) => Promise<boolean>
}

const _intermediaryChordVersion_ = 1
export default function SectionChangeReport ({ open, song, onClose, step1Next }: SectionChangeReportProps ) {
  const classes = useStyles()
  
  const [sectionIndex, setSectionIndex] = React.useState<number>(-1)

  const [oldIntermediaryJson, setOldIntermediaryJson] = React.useState<IntermediaryJson>(
    song.intermediaryChordJson ?
    JSON.parse(song.intermediaryChordJson) as IntermediaryJson : 
    {} as IntermediaryJson
  )
  const [newIntermediaryJson, setNewIntermediaryJson] = React.useState<IntermediaryJson>( 
    song.chordSheetRaw ? rawChordsheetToIntermediaryJson(song.chordSheetRaw) : { sections: []} as IntermediaryJson
  )

  const [newMatches, setNewMatches] = React.useState<number[]>([])
  const [perSectionMatches, setPerSectionMatches] = React.useState<number[][]>([])
  
  useEffect(() => { // Maybe we need to check if chordSheetRaw changed ...
    if (song.chordSheetRaw && song.chordSheetRaw !== "" && song.intermediaryChordJson) {
      let oij = song.intermediaryChordJson ?
                JSON.parse(song.intermediaryChordJson) as IntermediaryJson : 
                {} as IntermediaryJson
      setOldIntermediaryJson(oij)

      let nij = rawChordsheetToIntermediaryJson(song.chordSheetRaw)
      setNewIntermediaryJson(nij) //setNewMatches(new Array(nij.sections.length).fill(-1))

      let tempMatches = matchSections(oij, nij)
      setNewMatches(tempMatches)

      let tempPerSectionMatches = []
      for (let i=0; i< tempMatches.length; i++) {
        let tempArray = new Array(nij.sections[i].chords.length).fill(-1)
        tempPerSectionMatches.push(tempArray)
      }
      setPerSectionMatches(tempPerSectionMatches)
    }
  }, [song])

  const onsubmit = async () => {
    return new Promise (async resolve => {
      let intermediary = mediateOslynIndermediaries(
        oldIntermediaryJson, newIntermediaryJson, newMatches, perSectionMatches
      )
  
      console.log(JSON.stringify(intermediary))
      if (intermediary) {
        let returnStatus = await step1Next(intermediary, 1)
        if (returnStatus){ onClose(); resolve(true) }
        else { resolve(false) }
      } else { alert("Error in song mediation! Press Ctrl + I") }
    })
    
  }
  
  return (
    <Dialog open={open} maxWidth="md" fullWidth style={{overflow:"hidden"}}>
      <DialogTitle id="simple-dialog-title" style={{paddingBottom: 0}}>Song Updates</DialogTitle>
        <div className={sectionIndex===-1?"alwaysShowScrollbar":""} style={{
          minHeight: 500, maxHeight: 700, overflowX: "auto", overflowY: sectionIndex===-1?"scroll":"auto"
        }}>
          <Slide  direction="right" in={sectionIndex < 0} mountOnEnter unmountOnExit>
            <div style={{
              visibility: sectionIndex<0?"visible":"hidden",
              display: sectionIndex<0?"":"None",
              height: sectionIndex>=0?0:"auto"
            }}>
              <SongChangeSummary 
                oldIJ={oldIntermediaryJson} newIJ={newIntermediaryJson} 
                newMatches={newMatches} setNewMatches={setNewMatches}
                setSectionIndex={(i) => setSectionIndex(i)}
              />
            </div>
          </Slide>
          {newIntermediaryJson.sections.map((section, index) => {
              return <Slide key={index} direction="right" in={index === sectionIndex} mountOnEnter unmountOnExit>
                <div style={{
                  visibility: index === sectionIndex?"visible":"hidden",
                  display: index === sectionIndex?"":"None",
                  height: index === sectionIndex?0:"auto",
                }}>
                  <SectionChangeSummary 
                    rawChordSheet={song.chordSheetRaw} rawkey={song.chordSheetRawKey}
                    oldSection={oldIntermediaryJson.sections[newMatches[index]]} 
                    newSection={section} index={index}

                    oldIJ={oldIntermediaryJson} matchIndex={newMatches[index]} 
                    setMatchIndex={(match) => {
                      newMatches[index] = match
                      setNewMatches([...newMatches])
                    }}

                    chordMapping={perSectionMatches[index]}
                    setChordMapping={(n) => {
                      perSectionMatches[index] = n
                      setPerSectionMatches([...perSectionMatches])
                    }}
                  />
                </div>
              </Slide>
            })
          }
        </div>
      <DialogActions>
        <ToggleButton
          value="check"
          selected={sectionIndex < 0}
          onChange={() => {
            setSectionIndex(-1)
          }}
        >
          <MenuBookIcon />
        </ToggleButton>
        <div style={{flexGrow: 1}} />
        <span>Sections: </span>
        <Pagination count={newIntermediaryJson.sections.length} shape="rounded" color="primary" 
          page={sectionIndex+1} onChange={(event: React.ChangeEvent<unknown>, value: number) => { console.log(value); setSectionIndex(value-1) }}/>
      </DialogActions>
      <DialogActions>
        <Button onClick={onClose} style={{color: "grey"}}>Ignore for now</Button>
        <Button color="primary" onClick={onsubmit}>Submit Changes</Button>
      </DialogActions>
    </Dialog>)
}