
const DefaultCharToSpaceLength = 177/100
const CorrectionFactor = 0.04

const CharToSpaceMap = {
  'a': 196.5/100,   'A': 188/80,
  'b': 196.5/100,   'B': 211/90,
  'c': 177/100,     'C': 228.5/90,
  'd': 196.8/100,   'D': 228.5/90,
  'e': 196.8/100,   'E': 211.2/90,
  'f': 94/100,      'F': 194/90,
  'g': 196.8/100,   'G': 218.5/80,
  'h': 196.8/100,   'H': 203/80,
  'i': 89/100,      'I': 100/100,
  'j': 89/100,      'J': 177/100,
  'k': 167/100,     'K': 188/80,
  'l': 80.5/100,    'L': 177/90,
  'm': 292.5/100,   'M': 175.5/60,
  'n': 196.8/100,   'N': 78/70,
  'o': 196.8/100,   'O': 218.5/80,
  'p': 196.8/100,   'P': 188/80,
  'q': 196.8/100,   'Q': 218.5/80,
  'r': 119.4/100,   'R': 203.4/80,
  's': 177/100,     'S': 188/80,
  't': 100.5/100,   'T': 172.5/80,
  'u': 196.5/100,   'U': 203.2/80,
  'v': 177/100,     'V': 188/80,
  'w': 254/100,     'W': 198.5/60,
  'x': 177/100,     'X': 188/80,
  'y': 177/100,     'Y': 188/80,
  'z': 177/100,     'Z': 172.5/80,
  ' ': 1
} as { [key: string]: number }

export const KeyDistanceMap = [
  [ 'A',          'G##', 'Bbb' ],
  [ 'Bb', 'A#',          'Cbb' ],
  [ 'B',  'Cb',   'A##',       ],
  [ 'C',  'B#',                ],
  [ 'C#', 'Db',   'B##',       ],
  [ 'D',          'C##', 'Ebb' ],
  [ 'Eb', 'D#',          'Fbb' ],
  [ 'E',  'Fb',   'D##',       ],
  [ 'F',  'E#',          'Gbb' ],
  [ 'F#', 'Gb',   'E##',       ],
  [ 'G',          'F##', 'Abb' ],
  [ 'Ab', 'G#',                ],
]

// export const isChordLine = (line1: string, line2: string): boolean => {
//   return true
// }
export const chordRegexForTextBlock =
                    "(^| |\n)([A-Ga-g](##?|bb?)?(m|M)?[2-9]?(sus|maj|min|aug|dim)?[2-9]?(\/[A-G](##?|bb?)?)?)(\n| |$)"
export const chordRegex =  "^([A-Ga-g](##?|bb?)?(m|M)?[2-9]?(sus|maj|min|aug|dim)?[2-9]?(\/[A-G](##?|bb?)?)?)$"
export const keyRegex = "^[A-Ga-g](##?|bb?)?$"

export const sectionRegexForTextBlock = "\\[[^\n]*\\]"

export const getLineType = (line: string): 'chord'|'lyric'|'annotation'|'blank' => {
  if (isChordLine(line)) { /*console.log(`getLineType() "${line}" chord`);*/ return 'chord' }
  else if (line.trim().replace(/\s\s+/g, ' ') === ' ') return 'blank'
  else if (line.trim().replace(/\s\s+/g, '').startsWith('[') &&
          line.trim().replace(/\s\s+/g, '').startsWith('[')) return 'annotation'
  else return 'lyric'
}

export const isChordLine = (line: string): boolean => {
  let tempLine = line.trim().replace(/\s\s+/g, ' ').split(' ')
  
  let numOfChords = 0
  let numOfNonChords = 0
  //const _Tol

  for (let item of tempLine) {
    if (!new RegExp(chordRegex).test(item)) {
      numOfNonChords = numOfNonChords + 1
    } else numOfChords = numOfChords + 1
  }

  //console.log(`"${line}" ${numOfChords} vs ${numOfNonChords}`)
  if (numOfChords >= numOfNonChords) return true
  else return false
}

export const getSectionName = (line: string): string => {
  let temp = line.split("[")
  return temp[temp.length-1].split("]")[0]
}

export const stripChordToKey = (chord: string): string|null => {
  /**
   * Chords can look like the following: C, Cm, Cmdim7, C/G, etc.
   *   1. split by [sus, maj, min, aug, dim, "/"], take the first char
   *   2. split by m or M, remove the minor
   *   3. some chords then are followed by a number ex. C2
   * 
   *   4. (optional) check that the chord looks like what we expect
   */

   chord = chord.split(/(sus|maj|min|aug|dim|\/)/g)[0]
   chord = chord.split(/(M|m)/g)[0]

   chord = chord.replace(/[0-9]/g, '')

   if (new RegExp(keyRegex, 'g').test(chord)) return chord
   else { console.warn(`stripChordToKey(${chord}) could not be stripped.`); return null}
}

export const distanceFromKey = (chord: string, key: string): number|null => {
  let schord
  if (!new RegExp(keyRegex, 'g').test(key)) return null
  if (!(schord = stripChordToKey(chord))) return null

  for (let i=0; i<KeyDistanceMap.length; i++) {
    if (KeyDistanceMap[i].includes(key)) {
      let index = i
      let p = 0
      
      while (p < 12) {
        if (KeyDistanceMap[index].includes(schord)) return p

        if (index + 1 < KeyDistanceMap.length ) index++
        else index = 0

        p++
      }
    }
  }

  console.log("distanceFromKey(): Could not find chord -> key distance, returning null ..")
  return null
}

export const getIsMinor = (chord: string): boolean => {
  chord = chord.split(/(sus|maj|min|aug|dim)/g)[0]
  if (chord.toLowerCase().endsWith('m')) return true
  else if (chord.toLowerCase().endsWith('m7')) return true
  else return false
}

export const getChordByNumber = (chord: number, isMinor: boolean, key: string): string|null => {
  if (!new RegExp(keyRegex, 'g').test(key)) return null
  
  for (let i=0; i<KeyDistanceMap.length; i++) {
    if (KeyDistanceMap[i].includes(key)) {
      let index = (i + chord) % KeyDistanceMap.length
      return isMinor ? KeyDistanceMap[index][0]+'m' : KeyDistanceMap[index][0]
    }
  }

  console.warn("getChordByNumber(): Could not find chord given chordInt. Returning null.")
  return null
}

export const getLeftSpacing = (lyric: string):number => {
  let totalSpacing = 0
  for (let i=0; i<lyric.length; i++) {
    let addNum = Object.keys(CharToSpaceMap).includes(lyric[i]) ? CharToSpaceMap[lyric[i]] : DefaultCharToSpaceLength
    totalSpacing = totalSpacing + addNum
  }
  return totalSpacing
}


/**
 * @param text a chordsheet in raw text from ultimate-guitar = uses monospaced font
 * Goal: output Rich Text - spacing is PROPORTIONAL
 */
export const convertToProportionalFont = (text: string):string => {
  if (text === null) return ""
  var textArray = text.split("\n")
  var newText = ""
  // console.log(textArray)

  for (let i=0; i<textArray.length; i++) {
    // console.log(textArray[i])
    if (getLineType(textArray[i]) === 'chord') {
      var chordLine = textArray[i]
      if (i<textArray.length-1 && getLineType(textArray[i+1]) === 'lyric') {
        var lyricLine = textArray[i+1]

        // reconstruct the chord line
        let newChordLine = ""
        let chordLineArray = chordLine.split('')
        // console.log(chordLine)
        
        let spaceTotal = 0.0
        let reductionFactor = 0.0

        for (let a=0; a<chordLineArray.length; a++) {
          let chordLineChar = chordLineArray[a]
          // console.log(chordLineChar)
          if (chordLineChar === " ") {
            let wordLineChar = lyricLine[a]
            if (reductionFactor > 0) {
              spaceTotal = spaceTotal - reductionFactor
              reductionFactor = 0
            }
            if (Object.keys(CharToSpaceMap).includes(wordLineChar))
              spaceTotal = spaceTotal + CharToSpaceMap[wordLineChar] - CorrectionFactor
            else spaceTotal = spaceTotal + DefaultCharToSpaceLength - CorrectionFactor
          } else {
            // .repeat() is not implemented by IE!
            //console.log(`${chordLineChar}  ${Math.round(spaceTotal)}`)

            let repeat = Math.round(spaceTotal) >= 0 ? Math.round(spaceTotal) : 0
            newChordLine = newChordLine + " ".repeat(repeat) + chordLineChar
            reductionFactor = reductionFactor + CharToSpaceMap[chordLineChar] - CorrectionFactor
            spaceTotal = 0
            //console.log(newChordLine)
          }
        }

        // console.log(`old chord: |${chordLine}\n`+
        //             `new chord: |${newChordLine}\n`+
        //             `    lyric: |${lyricLine}`)
        
        newText = newText + newChordLine + "\n"
        continue
      }// else console.log("Chord line is not followed by lyric, no modification needed")
    }// else console.log("Not a chord line, not modifying")
    newText = newText + textArray[i] + "\n"
  }
  return newText
}

/**
 * @param text a chordsheet from Oslyn Studio, that is in proportional font aka. variable-width font
 * Goal: output monospaced font - to be stored in DB
 */
export const convertToMonospacedFont = (text: string):string => {
  if ( text === null ) return ""
  var textArray = text.split("\n")
  var newText = ""
  console.log(textArray)

  for (let i=0; i<textArray.length; i++) {
    // console.log(textArray[i])
    if (getLineType(textArray[i]) === 'chord') {
      var chordLine = textArray[i]
      if (i<textArray.length-1 && getLineType(textArray[i+1]) === 'lyric') {
        var lyricLine = textArray[i+1]

        let newChordLine = ""
        let chordLineArray = chordLine.split('')

        let budget = 0.0
        let lyricStartPointer = 0
        let p = 0
        for (let a=0; a<chordLineArray.length; a++) {
          if (chordLineArray[a] === ' ') {
            budget = budget + 1
          } else {


            if (a-1 >= 0 && chordLineArray[a-1] === ' ') {
              // console.log(`HERE! first ${CharToSpaceMap[lyricLine[p]]}`)
              while ( budget >= (Object.keys(CharToSpaceMap).includes(lyricLine[p]) ? CharToSpaceMap[lyricLine[p]] : DefaultCharToSpaceLength)) {
                budget = budget - (Object.keys(CharToSpaceMap).includes(lyricLine[p]) ? CharToSpaceMap[lyricLine[p]] : DefaultCharToSpaceLength)
                p = p + 1
              }
              newChordLine = newChordLine + " ".repeat(p-lyricStartPointer) + chordLineArray[a]
              lyricStartPointer = p
            } else {
              newChordLine = newChordLine + chordLineArray[a]
            }
            
            const boldMultiplier = 1.3
            budget = budget + (Object.keys(CharToSpaceMap).includes(chordLineArray[a]) ? CharToSpaceMap[chordLineArray[a]] : DefaultCharToSpaceLength) * boldMultiplier
            // budget = budget - (CharToSpaceMap[chordLineArray[a]]*4.4)
          }
          // console.log(`"${chordLineArray[a]}", Budget: ${budget}`)
        }
        
        // console.log(`old chord: |${chordLine}\n`+
        //             `new chord: |${newChordLine}\n`+
        //             `    lyric: |${lyricLine}`)

        newText = newText + newChordLine + "\n"
        continue
      }// else console.log("Chord line is not followed by lyric, no modification needed")
    }// else console.log("Not a chord line, not modifying")
    newText = newText + textArray[i] + "\n"
  }
  return newText
}

export const rawChordsheetToIntermediaryJson = (text: string):IntermediaryJson => {
  
  var intermediaryJson = {
    sections: []
  } as IntermediaryJson

  var index = 0
  let currentSection = { 
    name: "",
    chords: []
  } as IntermediaryJsonSection

  // NOTE! rawChordsheet is in mono font .. so the start/end is not calculated correctly
  // We need to convert back to proportional font, to figure out the ID.
  let proportional = convertToProportionalFont(text)
  var pTextArray = proportional.split("\n")
  //console.log(text)
  //console.log(pTextArray)

  var textArray = text.split("\n")

  for (let i=0; i<textArray.length; i++) {
    let line = textArray[i]
    let pline = pTextArray[i]

    let lineType = getLineType(line)
    if (lineType === 'chord') {
      //console.log(`${lineType} "${pline}"`)
      let matchArr, start, end
      let regex = new RegExp(chordRegexForTextBlock, 'g')

      while ((matchArr = regex.exec(line)) !== null) {
        start = index + matchArr.index
        end = start + matchArr[0].length

        //chrordStrategy regex matches \n at the beginning & end of line. string.trim() cuts them out
        if (matchArr.index === 0) start = start - 1
        if (matchArr.index + matchArr[0].length === textArray[i].length) end = end + 1

        //console.log(`${currentSection.name} ${matchArr[0]}-${start}-${end} || ${matchArr.index + matchArr[0].length} : ${textArray[i].length}`)
        currentSection.chords.push({
          chord: matchArr[0].trim(),
          start, end, duration: null
        } as IntermediaryJsonChord)
      }
    } else if (lineType === 'annotation') {
      if (currentSection.chords.length === 0 && currentSection.name === "") {
        currentSection.name = getSectionName(line)
      } else if (currentSection.chords.length === 0) {
        console.warn(`We've detected an empty section: ${currentSection.name}`)
        intermediaryJson.sections.push(currentSection)
        currentSection = { 
          name: getSectionName(line),
          chords: []
        } as IntermediaryJsonSection
      } else {
        intermediaryJson.sections.push(currentSection)
        currentSection = { 
          name: getSectionName(line),
          chords: []
        } as IntermediaryJsonSection
      }
    }

    index = index + line.length + 1 // for \n ,, since that gets cut by trim(\n)
  }

  intermediaryJson.sections.push(currentSection)
  //console.log(JSON.stringify(intermediaryJson))
  return intermediaryJson
}

export const getSectionText = (text: string, sectionName: string): string => {
  let textArray = text.split("\n")
  let newText = ""

  let accept = false
  for (let i=0; i<textArray.length; i++) {
    let line = textArray[i]
    let lineType = getLineType(line)

    if (lineType === 'annotation') { 
      if (accept && !line.toLowerCase().includes(sectionName.toLowerCase())) return newText
      else if (line.toLowerCase().includes(sectionName.toLowerCase())) accept = true
    }

    if (accept) {
      newText = newText + line + "\n"
    }
  }

  return newText
}

export const getSectionText2 = (text: string, sectionIndex: number): string => {
  let textArray = text.split("\n")
  let newText = ""

  let accept = false
  let curIndex = 0

  for (let i=0; i<textArray.length; i++) {
    let line = textArray[i]
    let lineType = getLineType(line)

    if (lineType === 'annotation') { 
      if (accept && curIndex !== sectionIndex) return newText
      else if (!accept && curIndex === sectionIndex) accept = true
      curIndex = curIndex + 1
    }

    if (accept) {
      newText = newText + line + "\n"
    }
  }

  return newText
}

/**
 * 
 * @param text            Mono format text! We should not need to recalculate - use directly from DB
 * @param intermediary    IntermediaryJson from Step1
 * @param key             Key from Step0 submitted by user
 */
export const convertOslynSongJson = (text: string, intermediary: IntermediaryJson, key: string): OslynSongJson => {
  let textArray = text.split("\n")
  //let ptext = convertToProportionalFont(text)
  
  let oslynSongJson = {
    meta: {},
    song: [] as OslynSongJsonPhrase[]
  } as OslynSongJson

  let p = 0 // currentPhraseNumber
  let currentIntermediaryJasonSection = intermediary.sections[p] as IntermediaryJsonSection

  let c = 0 // currentChord

  for (let i=0; i<textArray.length; i++) {
    let line = textArray[i]
    let lineType = getLineType(line)

    if (lineType === 'chord') {
      var lyricLine = ""
      if (i<textArray.length-1 && getLineType(textArray[i+1]) === 'lyric') {
        lyricLine = textArray[i+1]
      }

      var currentPhrase = {
        lyric: lyricLine,
        section: currentIntermediaryJasonSection.name,
        chords: [] as OslynSongJsonChord[],
        phrase: p,
        phraseDuration: null,
        start: null
      } as OslynSongJsonPhrase


      let matchArr
      let regex = new RegExp(chordRegexForTextBlock, 'g')
      let prevChordWidth = 0
      
      while ((matchArr = regex.exec(line)) !== null) {

        //console.log(`${matchArr[0].trim()} :: ${currentIntermediaryJasonSection.chords[c].chord} :: ${c}`)

        if (currentIntermediaryJasonSection.chords[c].chord === matchArr[0].trim()) {
          let currentIntermediaryJasonChord = currentIntermediaryJasonSection.chords[c] as IntermediaryJsonChord
          
          let currentChord = {
            chord: distanceFromKey(matchArr[0].trim(), key),
            isMinor: getIsMinor(matchArr[0].trim()),
            beats: currentIntermediaryJasonChord.duration,
            meta: {
              start: currentIntermediaryJasonChord.start,
              end: currentIntermediaryJasonChord.end,
            },
            position: lyricLine === "" ? matchArr.index : matchArr.index - prevChordWidth
          } as OslynSongJsonChord

          currentPhrase.chords.push(currentChord)
          prevChordWidth = matchArr[0].trim().length
          c++
          //console.log(`${matchArr[0].trim()} c: ${c} `)
        } else { 
          throw `convertOslynSongJson(): 
          nextChord missmatch :: ${currentIntermediaryJasonSection.chords[c].chord} at index ${c} vs ${matchArr[0].trim()}`
        }
      }

      oslynSongJson.song.push(currentPhrase)
    } else if (lineType === 'annotation') {
      //console.log(getSectionName(line))

      // NOTE! Some chord sheets may have 2 of the SAME sections following 1 another
      if (getSectionName(line) === currentIntermediaryJasonSection.name) { 
        console.warn(`convertOslynSongJson(): There are 2 sections of the same name - ${currentIntermediaryJasonSection.name}, this can be OK.`)
      }

      // skip the first section .. its already defined at the beginning of the function
      if (p!== 0 || (p === 0 && getSectionName(line) !== currentIntermediaryJasonSection.name)) { 
        if (p+1 < intermediary.sections.length) {
          if (getSectionName(line) === intermediary.sections[p+1].name) {
            //console.log(getSectionName(line))
            currentIntermediaryJasonSection = intermediary.sections[p+1]
            p++; c=0
          } else { throw `convertOslynSongJson(): the next section name missmatch! Raw: "${getSectionName(line)}", Intermediary Section: ${intermediary.sections[p+1].name}` }
        } else console.log("convertOslynSongJson(): This is the last section!")
      }
    }
  }
  
  return oslynSongJson
}


//C   G    D    Em          G      C    G       D
//7   11   16   21          33     40   45      53
//                          35     44   49      59
//  4    5    5     12-14      7-9   5-5   8-10
//                    2         2     0     2

// From ^
// 7-12 = +2

// FROM MY calculation: 56
// [Chorus] = 63

//       C           G
//      ?81          93
//       81          99
//            12-18
//              6

//        C                   G

export const checkSpaceBarLess = (ij: IntermediaryJson ): boolean => {

  for (let i=0; i<ij.sections.length; i++) {
    let tempSection = ij.sections[i]
    for (let j=0; j<tempSection.chords.length; j++) {
      if (tempSection.chords[j].duration === null || tempSection.chords[j].duration === undefined) 
        return false
    }
  }

  return true
}