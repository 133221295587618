import React, { useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TableCell, TableHead, TableRow, TableSortLabel, Checkbox } from '@material-ui/core'

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean
  id: keyof FlattenedSong
  label: string
  numeric: boolean
  isCenter: boolean
  roles: string[]
}

const _ALL = ["ADMIN", "AELEAD", "AE"]
const _chordSheetPlatformMap = {
  "UG": "Ultimate Guitar"
}

// const headCells: HeadCell[] = [
//   { id: 'title', numeric: false, disablePadding: true, isCenter: false, label: 'Song', roles: _ALL },
//   { id: 'songAuthor', numeric: false, disablePadding: true, isCenter: true, label: 'Author', roles: _ALL },
//   { id: 'id', numeric: false, disablePadding: false, isCenter: true, label: 'Unique Identifier', roles: _ALL },
//   { id: 'chordSheetLink', numeric: false, disablePadding: false, isCenter: true, label: 'Platform Link', roles: _ALL },
//   { id: 'numOfRecordings', numeric: true, disablePadding: false, isCenter: false, label: 'Number of Recordings', roles: _ALL},
//   { id: 'creatorName', numeric: false, disablePadding: true, isCenter: true, label: 'Audio Engineer', roles: _ALL }
// ]

const headCells: HeadCell[] = [
  { id: 'title', numeric: false, disablePadding: true, isCenter: false, label: 'Song', roles: _ALL },
  { id: 'songAuthor', numeric: false, disablePadding: true, isCenter: true, label: 'Author', roles: _ALL },
  { id: 'id', numeric: false, disablePadding: false, isCenter: true, label: 'Unique Identifier', roles: _ALL },
  { id: 'chordSheetLink', numeric: false, disablePadding: false, isCenter: true, label: 'Platform Link', roles: _ALL },
  { id: 'numOfRecordings', numeric: true, disablePadding: false, isCenter: false, label: 'Number of Recordings', roles: _ALL},
  { id: 'creatorName', numeric: false, disablePadding: true, isCenter: true, label: 'Audio Engineer', roles: _ALL },
  { id: 'isSpaceBarless', numeric: false, disablePadding: true, isCenter: true, label: 'Auto Tap', roles: _ALL }
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    margin: {
      margin: "0px",
    },
  }),
);

interface EnhancedTableProps {
  user: Labeller
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof FlattenedSong) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const MD_SCREEN = 960
const LG_SCREEN = 1280
export default function EnhancedTableHead(props: EnhancedTableProps) {
  //const [width, setWidth] = React.useState(0)
  const [head, setHead] = React.useState(headCells)
  
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof FlattenedSong) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  }

  useEffect(() => {
    function handleResize() { 
      //console.log(window.innerWidth)
      //console.log(head[3].id)

      const _indexOfLink = 3 // QUICK FIX for responsive table (take out "link" when screen is small)!!!
      if (window.innerWidth <= LG_SCREEN && head[_indexOfLink].id === 'chordSheetLink') {
        head.splice(_indexOfLink, 1)
        setHead([...head])
      } else if (window.innerWidth > LG_SCREEN && head[_indexOfLink].id !== 'chordSheetLink') {
        head.splice(_indexOfLink, 0, 
          { id: 'chordSheetLink', numeric: false, disablePadding: false, isCenter: true, label: 'Platform Link', roles: _ALL }
        )
        setHead([...head])
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => { window.window.removeEventListener('resize', handleResize) }
  }, [])

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {head.map((headCell) => {
          
          let contains = false
          for (let i in headCell.roles) {
            if (props.user.roles){
              if (props.user.roles.includes(headCell.roles[i])) {
                contains = true
                break
              }
            } else console.log("no user.roles in RecordingListTableBody")
          }

          return  contains ? <TableCell
                    key={headCell.id}
                    align={headCell.isCenter ? 'center' : (headCell.numeric ? 'right' : 'left') }
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell> : null

        })}
        <TableCell align="center">Action</TableCell>
      </TableRow>
    </TableHead>
  );
}