exports.DEBUG = true

exports.s3 = {
  accessKeyId: "AKIAQCZ46IJB2LP726V5",
  secretAccessKey: "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"
}

exports.s3PiplineBucket = "oslyn-studio-pipeline"
//exports.s3PipleineFolder = ""

// aws s3 sync build/ s3://oslyn-studio --acl public-read