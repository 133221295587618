import React from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { 
  Stepper, Step, StepLabel, Typography,
  StepConnector, Button, Snackbar
} from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import SubjectIcon from '@material-ui/icons/Subject';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import SaveIcon from '@material-ui/icons/Save'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { StepIconProps } from '@material-ui/core/StepIcon'

import Step0 from './Step0/Step0'
import Step1 from './Step1/Step1'
import Step2 from './Step2/Step2'

import SectionChangeReport from '../Popups/SectionChangeReport'

import { rawChordsheetToIntermediaryJson, convertOslynSongJson, checkSpaceBarLess } from '../EditSongUtil'
import { checkForChanges } from '../Popups/components/utils/SongChangeUtils'

import { grey, green } from '@material-ui/core/colors';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 19,
  },
  active: {
    '& $line': {
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      backgroundColor: "#651fff"
    },
  },
  completed: {
    '& $line': {
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      backgroundColor: "#651fff"
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    backgroundImage:
      'linear-gradient( 136deg, #ff8a65 0%, #651fff 100%)',
  },
  completed: {
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    backgroundImage:
      'linear-gradient( 136deg, #ff8a65 0%, #651fff 100%)',
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SubjectIcon fontSize="small" />,
    2: <MusicNoteIcon fontSize="small"/>,
    3: <AssignmentTurnedInIcon fontSize="small"/>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

function getSteps() {
  return ['Paste the Lead Sheet', 'Enter Chord Durations', 'Submit & Verify!'];
}

export interface EditSongStepperProps {
  editorId: string
  song: Song|null
  step0Next: (link: string, key: string, nextStatus: number, beats: number) => Promise<boolean>
  step1Next: (intermediaryChordJson: IntermediaryJson, nextStatus: number) => Promise<boolean>
  step2Next: (oslynSongJson: OslynSongJson, nextStatus: number) => Promise<boolean>
  goToSongs: () => void
}

export default function EditSongStepper({ 
    editorId, song, goToSongs,
    step0Next, step1Next, step2Next,  
  }: EditSongStepperProps) {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [completionChoice, setCompletionChoice] = React.useState(0)
  const steps = getSteps()

  const [link, setLink] = React.useState("")
  const [key, setKey] = React.useState("")
  const [beats, setBeats] = React.useState(4)

  const [intermediaryJson, setIntermediaryJson] = React.useState<IntermediaryJson|null>(null)

  const [needResolve, setNeedResolve] = React.useState(false)
  const [songError, setSongError] = React.useState(false)

  const [isSpaceBarLessEnabled, setIsSpaceBarLessEnabled] = React.useState(false)

  React.useEffect(() => {
    if (song) {
      if (song.intermediaryChordJson) {
        setIsSpaceBarLessEnabled(
          checkSpaceBarLess(JSON.parse(song.intermediaryChordJson) as IntermediaryJson)
        )
      }
    }
  }, [song])

  React.useEffect(() => {
    if (song) { 
      setLink(song.chordSheetLink)
      setKey(song.chordSheetRawKey)
      setActiveStep(song.status)

      if (song.intermediaryChordJson && song.chordSheetRaw) {

        // TODO ! DO THE CHORDS ON THE PAGE MATCH THE CHORDS in intermediary json?
        let isChanged = checkForChanges(
          rawChordsheetToIntermediaryJson(song.chordSheetRaw), 
          JSON.parse(song.intermediaryChordJson) as IntermediaryJson
        )

        //let isChanged = true

        if (!isChanged) {
          setIntermediaryJson(JSON.parse(song.intermediaryChordJson) as IntermediaryJson)
        } else {
          setNeedResolve(true)
        }
      } else if (song.chordSheetRaw) {
        let rawjson = rawChordsheetToIntermediaryJson(song.chordSheetRaw)
        setIntermediaryJson(rawjson)
      } else console.log("Step1: Could not detect a valid chordsheet.")
    }
  }, [song])

  // React.useEffect(() => {
  //   if (song) {
  //     if (activeStep === 0) {
  //       if (!link || link === "" || !key || key === "") setHasChanged(true)
  //     }
  //   }
  // }, [song, link, key, beats, intermediaryJson])

  // const reparseIntermediaryJson = () => {
  //   if (song) {
  //     if (song.chordSheetRaw) {
  //       let rawjson = rawChordsheetToIntermediaryJson(song.chordSheetRaw)
  //       setIntermediaryJson(rawjson)
  //     } else { console.warn("Step1: reparseIntermediaryJson() - could not find song.chordSheetRaw for reparsing.") }
  //   } else { console.warn("Step1: reparseIntermediaryJson() - could not find song.") }
  // }

  const saveIntermediaryJason = async () => {
    console.log(intermediaryJson)
    let isHandled = intermediaryJson? await step1Next(intermediaryJson, activeStep): false
    return isHandled
  }


  const handleNext = async () => {
    let isHandled = false
    if (activeStep === 0) { 
      isHandled = await step0Next(link, key, activeStep + 1, beats)
      // if (isHandled) {
      //   reparseIntermediaryJson()
      // } else alert("ERROR: step0Next returned FALSE !")
    }
    else if (activeStep === 1) { isHandled = intermediaryJson? await step1Next(intermediaryJson, activeStep + 1) : false }
    else if (activeStep === 2) {  }
    isHandled ? setActiveStep((prevActiveStep) => prevActiveStep + 1) : console.log("couldn't handleNext()")
  }

  const handleSkip = async () => {
    let isHandled = false
    if (activeStep === 0) { isHandled = true }
    else if (activeStep === 1) { isHandled = true }
    else if (activeStep === 2) {  }
    isHandled ? setActiveStep((prevActiveStep) => prevActiveStep + 1) : console.log("couldn't handleSkip()")
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  };

  const handleReset = () => { setActiveStep(0) }

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{display: "flex", justifyContent: "center"}}>
        <Typography style={{
            display:"inline-flex", alignItems: "center", 
            color: isSpaceBarLessEnabled? green[500] : grey[300]
          }}>
          Auto Tap Enabled! <CheckCircleIcon  style={{marginLeft: 10, fontSize: 20}}/>
        </Typography>
      </div>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <div className={classes.instructions}>
              { activeStep === 0? 
                <Step0 songKey={key} link={link} 
                  setKey={key => { setKey(key) }} 
                  setLink={link => { setLink(link) }}
                  beats={beats} setBeats={(beats) => {setBeats(beats)} }
                  /> : null }
              { activeStep === 1? 
                <Step1 editorId={editorId} 
                  intermediaryJson={intermediaryJson} 
                  setIntermediaryJson={setIntermediaryJson}
                /> : null }
              { activeStep === 2? 
                <Step2 selected={completionChoice}
                  setSelected={ (id: number) => { setCompletionChoice(id) }} /> : null 
              }
            </div>
            <div style={{paddingTop: 20, display: "flex", flexDirection:"row"}}>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>
                { activeStep === 0 || activeStep === 1 ? 
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >Submit</Button>:
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={completionChoice === 0}
                    onClick={ async () => { 
                      if (song) {
                        console.log(song)
                        if (song.intermediaryChordJson) {

                          var oslynSongJson = convertOslynSongJson(
                            song.chordSheetRaw, 
                            JSON.parse(song.intermediaryChordJson) as IntermediaryJson, 
                            song.chordSheetRawKey
                          )

                          console.log(oslynSongJson)
                          await step2Next(oslynSongJson, 0)
                        } else { console.log("intermediaryChordJson was empty") }
                        goToSongs() 
                      } else console.log("empty song!")
                    }}
                  >Done!</Button>
                }
                {activeStep === 1 ?
                  <Button color="secondary" onClick={saveIntermediaryJason}
                      style={{paddingLeft: 5, paddingRight: 5}}>
                    <SaveIcon />
                  </Button>:null
                }
              </div>
              <div style={{flexGrow: 1}}/>
              { activeStep === 0 || activeStep === 1 ? 
                <Button onClick={handleSkip}>
                  Skip <DoubleArrowIcon />
                </Button> : null
              }
            </div>
          </div>
        )}
      </div>
      { song ?
        <SectionChangeReport 
          open={needResolve} song={song} step1Next={step1Next}
          onClose={() => { setNeedResolve(false) }} 
        />:
        <Snackbar open={songError} autoHideDuration={6000} onClose={() => { setSongError(false) }}>
          <Alert severity="error">Song is not detected.</Alert>
        </Snackbar>
      }
    </div>
  );
}