import React, { useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,
  TextField, FormGroup, Typography, InputAdornment } from '@material-ui/core'

import LinkIcon from '@material-ui/icons/Link';
 
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1)
      },
    },
  }),
)

export interface Step0Props {
  songKey: string,
  link: string,

  setKey: (key: string) => void
  setLink: (link: string) => void

  beats: number,
  setBeats: (beats: number) => void
}

export default function Step0({ songKey, link, setKey, setLink, beats, setBeats }: Step0Props) {
  const classes = useStyles()
  const placement = "bottom"

  return (
    <div className={classes.root}>
      <FormGroup>
        <TextField
          error={false}
          id="chord-sheet-link"
          label="Lead Sheet Link"
          value={link ? link : ""}
          onChange={ e => { setLink(e.target.value) }}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormGroup>
      <FormGroup row>
        <Typography component="span" style={{paddingTop: 10, paddingRight: 15, paddingLeft: 5}}>Key: </Typography>
        <TextField 
          margin="dense"
          inputProps={{min:0, style:{ textAlign: "center" }}}
          error={false} style={{width: 60, top: -15}}
          id="key" label=" "
          value={songKey ? songKey : ""} onChange={ e => { setKey(e.target.value) }}
        />
      </FormGroup>
      <FormControl component="fieldset">
        <FormLabel component="legend">Time Signature</FormLabel>
        <RadioGroup row aria-label="position" name="position" defaultValue="4" 
          value={beats} onChange={(e) => { setBeats(parseInt(e.target.value)) }}>
          <FormControlLabel
            value={4}
            control={<Radio color="primary" />}
            label="4/4"
            labelPlacement={placement}
          />
          <FormControlLabel
            value={6}
            control={<Radio color="primary" />}
            label="6/8"
            labelPlacement={placement}
          />
          <FormControlLabel
            value={3}
            control={<Radio color="primary" />}
            label="3/4"
            labelPlacement={placement}
          />
          <FormControlLabel 
            value={2} 
            control={<Radio color="primary" />} 
            label="2/4" 
            labelPlacement={placement}
          />
        </RadioGroup>
      </FormControl>
    </div>
  )
}