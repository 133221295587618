import React, { useEffect } from 'react'
import { Button, Card, CardActions, CardContent, CardMedia, Grid,
          Typography, Container } from '@material-ui/core'

import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"

import { makeStyles } from '@material-ui/core/styles'
import Recordings from '../Recordings/Recordings'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}))

const _RECORDINGLIMIT = 200000

export interface WorkspaceProps {
  user: Labeller,
  page: string
  goToLabelStudio: (id: string) => void
  goToRecordingList: () => void
  goToSongs: () => void
}

export default function WorkSpace({
  user, page, goToLabelStudio, goToRecordingList, goToSongs
} : WorkspaceProps) {
  const classes = useStyles();

  const [totalRecordings, setTotalRecordings] = React.useState(0)
  const [totalAudit, setTotalAudit] = React.useState(0)

  const getGreeting = ():string => {
    let dt = new Date()
    var offset = 0; //new Date should return Hour in user's timezone ...
    var estHour = new Date(dt.getTime() + offset*60*1000).getHours()
    console.log(estHour)

    if (estHour < 12) return "Good Morning"
    else if (estHour > 17) return "Good Evening"
    else return "Good Afternoon"
  }

  const getTotalRecordingsCount = async (username: string) => {
    // let query = `query listRecordings {
    //   listRecordings( 
    //     limit: ${_RECORDINGLIMIT}, 
    //     filter: { 
    //       and: {
    //         singerName: { eq: "${username}"},
    //         status: { eq: COMPLETE }
    //       }
    //     }
    //   ) {
    //     items { id labeller { username } }
    //   }
    // }`

    let query = `query listRecordings {
      listRecordings( 
        limit: ${_RECORDINGLIMIT}, 
        filter: { singerName: { eq: "${username}"} }
      ) {
        items { id }
      }
    }`
    let data
    try {
      data = (await API.graphql(graphqlOperation(query))) as GraphQLResult<{
        listRecordings: {items: {
          id: string, 
          labeller: {
            username: string
          }
        }[]}
      }>
    } catch (e) { console.log(e) }

    let items = data ? data.data ? data.data.listRecordings.items : [] : []
    console.log(items)
    if (items) setTotalRecordings(items.length)
  }

  const getTotalAuditCount = async (username: string) => {
    let query = `query listRecordings {
      listRecordings( 
        limit: ${_RECORDINGLIMIT}, 
        filter: { status: { eq: COMPLETE } }
      ) {
        items { id labeller { username } }
      }
    }`

    let data
    try {
      data = (await API.graphql(graphqlOperation(query))) as GraphQLResult<{
        listRecordings: {items: {
          id: string, 
          labeller: {
            username: string
          }
        }[]}
      }>
    } catch (e) { console.log(e) }

    let items = data ? data.data ? data.data.listRecordings.items : [] : []
    let count = 0

    for (let item of items) {
      if (item.labeller.username === username) count = count + 1
    }
    if (items) setTotalAudit(count)
  }

  useEffect(() => {
    if (page === "Workspace" && user) {
      getTotalRecordingsCount(user.username)
      getTotalAuditCount(user.username)
    }
  }, [page])

  return (
      <main style={{paddingTop: "70px"}}>
        <Grid container spacing={4} style={{padding: "20px"}}>
          <Grid item key={1} xs={12} sm={6} md={4} lg={3}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography style={{color:"grey"}}>
                  Songs Recorded
                </Typography>
                <Typography gutterBottom variant="h2" component="h2"
                  style={{color: "green"}}
                >{totalRecordings}</Typography>
                <Typography variant="caption" display="block">
                  * Number of recordings you've done on this platform.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="secondary" style={{color: "grey"}}>Why?</Button>
                <Button size="small" color="primary"
                  onClick={() => { goToSongs() }}
                >Work on this</Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item key={2} xs={12} sm={6} md={4} lg={3}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography style={{color:"grey"}}>
                  Songs Audited
                </Typography>
                <Typography gutterBottom variant="h2" component="h2"
                  style={{color: "GoldenRod"}}
                >{totalAudit}</Typography>
                <Typography variant="caption" display="block">
                  * Number of Recordings you've labelled or approved
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="secondary" style={{color: "grey"}}>Why?</Button>
                <Button size="small" color="primary"
                  onClick={() => { goToRecordingList() }}
                >Work on this</Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item key={3} xs={12} sm={6} md={4} lg={3}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography style={{color:"grey"}}>
                  Sound Libraries released to Oslyn!
                </Typography>
                <Typography gutterBottom variant="h2" component="h2"
                  style={{color: "green"}}
                > 0 </Typography>
                <Typography variant="caption" display="block">
                  * As of now for the month of <b style={{fontWeight: 600, color: "black"}}>April</b>
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary"
                  onClick={() => {window.open('https://oslyn.io/admin', "_blank")}}
                >Oslyn</Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item key={4} xs={12} sm={6} md={4} lg={3}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography style={{color:"grey"}}>
                  Amount Earned
                </Typography>
                <Typography gutterBottom variant="h2" component="h2"
                  style={{color: "Maroon"}}
                > $0 </Typography>
                <Typography variant="caption" display="block">
                  *Paid out as of <b style={{fontWeight: 600, color: "black"}}>March</b> 2021
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="secondary" style={{color: "grey"}}>view History</Button>
                <Button size="small" color="primary">Questions?</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>

        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
              {getGreeting()} <b style={{fontWeight: 600}}>{user.username}</b>,
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
              Click below to start your labelling session. If you have work assigned, we will bring that up first! 
              If not, we will choose one at random for you!
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button variant="contained" color="primary">
                    Start Labelling!
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary">
                    Choose
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        {/*
        <Recordings
          user={user} title={"My Recordings"}
          page={page} matchpage={"Workspace"}
          goToLabelStudio={(id) => goToLabelStudio(id)}
        />*/}
      </main>)
}